
export const getUserDisplayName = (user: Partial<{ givenName: string, familyName: string, emailAddress: string, email: string }>): string => {
    if (!user) {
        return "U N";
    }
    if (user.givenName && user.familyName) {
        return `${user.givenName} ${user.familyName}`;
    } else if (user.givenName) {
        return user.givenName;
    } else if (user.familyName) {
        return user.familyName;
    } else if ('emailAddress' in user && user.emailAddress) {
        return user.emailAddress;
    } else if ('email' in user && user.email) {
        return user.email;
    } else {
        return null;
    }
}