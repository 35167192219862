/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2024-01-19 20:17:40.

import { Axios, AxiosResponse } from "axios";

export interface HttpClient<O> {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: O; }): RestResponse<R>;
}

export interface RestApplication<O> {

    /**
     * HTTP GET /anonymous/**
     * Java method: com.byzpass.demo.loan.AnonymousController.showAnonymous
     */
    showAnonymous(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v1/action
     * Java method: com.byzpass.demo.linkaction.LinkActionController.doAction
     */
    doAction(queryParams: { id: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/action/pendingInvites
     * Java method: com.byzpass.demo.linkaction.LinkActionController.getPendingInvites
     */
    getPendingInvites(options?: O): RestResponse<LinkActionInviteToLoan[]>;

    /**
     * HTTP DELETE /api/v1/admin/labels
     * Java method: com.byzpass.demo.labels.LabelAdminController.deleteLabels
     */
    deleteLabels(toDelete: LabelDeleteDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/admin/labels/forCompany/{companyId}
     * Java method: com.byzpass.demo.labels.LabelAdminController.getLabelsForCompany
     */
    getLabelsForCompany(companyId: string, options?: O): RestResponse<LabelsListDto>;

    /**
     * HTTP POST /api/v1/admin/labels/forCompany/{companyId}
     * Java method: com.byzpass.demo.labels.LabelAdminController.putLabels
     */
    putLabels(companyId: string, create: LabelsListDto, options?: O): RestResponse<LabelsListDto>;

    /**
     * HTTP POST /api/v1/auth/addUser
     * Java method: com.byzpass.demo.security.SecurityAuthController.addUser
     */
    addUser(iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP POST /api/v1/auth/changePassword
     * Java method: com.byzpass.demo.security.SecurityAuthController.changePassword
     */
    changePassword(iChangePasswordRequest: AppUserChangePasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/auth/editUser/{id}
     * Java method: com.byzpass.demo.security.SecurityAuthController.editUser
     */
    editUser$POST$api_v1_auth_editUser_id(id: string, iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP POST /api/v1/auth/globalLogout
     * Java method: com.byzpass.demo.security.SecurityAuthController.globalLogout
     */
    globalLogout(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/auth/isAuthenticated
     * Java method: com.byzpass.demo.security.SecurityAuthController.isAuthenticated
     */
    isAuthenticated(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/auth/timezones
     * Java method: com.byzpass.demo.security.SecurityAuthController.getTimezones
     */
    getTimezones(options?: O): RestResponse<TimezoneDTO[]>;

    /**
     * HTTP POST /api/v1/auth/update
     * Java method: com.byzpass.demo.security.SecurityAuthController.updateAppUser
     */
    updateAppUser(iDto: CreateUpdateAppUserDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/auth/userSettings
     * Java method: com.byzpass.demo.security.SecurityAuthController.getSettings
     */
    getSettings(options?: O): RestResponse<AppUserPreferencesDTO>;

    /**
     * HTTP PUT /api/v1/auth/userSettings
     * Java method: com.byzpass.demo.security.SecurityAuthController.putSettings
     */
    putSettings(iDto: AppUserPreferencesDTO, options?: O): RestResponse<string>;

    /**
     * HTTP PUT /api/v1/clear/reeschedule/personquickflag/{loanId}/{personId}
     * Java method: com.byzpass.demo.clear.ClearController.reeschedulePersonQuickFlagReport
     */
    reeschedulePersonQuickFlagReport(loanId: string, personId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v1/clear/reschedule/businessquickflag/{loanId}/{entityId}
     * Java method: com.byzpass.demo.clear.ClearController.reescheduleBusinessQuickFlagReport
     */
    reescheduleBusinessQuickFlagReport(loanId: string, entityId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v1/clear/schedule/businessquickflag/{loanId}/{entityId}
     * Java method: com.byzpass.demo.clear.ClearController.scheduleBusinessQuickFlagReport
     */
    scheduleBusinessQuickFlagReport(loanId: string, entityId: string, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v1/documents
     * Java method: com.byzpass.demo.document.DocumentController.postUploadUrlFor
     */
    postUploadUrlFor(iUpload: Upload, options?: O): RestResponse<Document>;

    /**
     * HTTP GET /api/v1/documents/uploadUrlFor
     * Java method: com.byzpass.demo.document.DocumentController.getUploadUrlFor
     */
    getUploadUrlFor(queryParams: { name: string; iProviderType?: ProviderType; formElementId?: string; loanId?: string; }, options?: O): RestResponse<Upload>;

    /**
     * HTTP GET /api/v1/documents/{id}/withDownloadUrl
     * Java method: com.byzpass.demo.document.DocumentController.getDocumentWithDownloadUrl
     */
    getDocumentWithDownloadUrl(id: string, queryParams?: { formElementId?: string; loanId?: string; }, options?: O): RestResponse<Document>;

    /**
     * HTTP GET /api/v1/fepreference/{key}
     * Java method: com.byzpass.demo.fepreference.FePreferenceController.getPreference
     */
    getPreference(key: string, options?: O): RestResponse<FePreferenceEntity>;

    /**
     * HTTP POST /api/v1/fepreference/{key}
     * Java method: com.byzpass.demo.fepreference.FePreferenceController.savePreference
     */
    savePreference(key: string, queryParams: { value: { [index: string]: string }; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/javascriptConstants
     * Java method: com.byzpass.demo.services.ConstantsForClientController.constants
     */
    constants(options?: O): RestResponse<PerUserConstants>;

    /**
     * HTTP GET /api/v1/javascriptConstants/hotjar.js
     * Java method: com.byzpass.demo.services.ConstantsForClientController.hotjar
     */
    hotjar(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v1/labels/loan/{loanId}/availableToMe
     * Java method: com.byzpass.demo.labels.LabelController.getLabels
     */
    getLabels(loanId: string, options?: O): RestResponse<LabelsListDto>;

    /**
     * HTTP POST /api/v1/labels/loan/{loanId}/setForUser
     * Java method: com.byzpass.demo.labels.LabelController.putLabelOnSomeone
     */
    putLabelOnSomeone(loanId: string, toset: SetLabelOnUserDto, options?: O): RestResponse<LoanDto>;

    /**
     * @deprecated
     * HTTP GET /api/v1/lenders/companies
     * Java method: com.byzpass.demo.company.LenderController.getCompanies
     */
    getCompanies(options?: O): RestResponse<CompanyListDto[]>;

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/company
     * Java method: com.byzpass.demo.company.LenderController.onboardCompany
     */
    onboardCompany(iOnboardCompany: OnboardCompany, options?: O): RestResponse<void>;

    /**
     * @deprecated
     * HTTP PUT /api/v1/lenders/company/{id}
     * Java method: com.byzpass.demo.company.LenderController.updateCompany
     */
    updateCompany(id: string, iOnboardCompany: OnboardCompany, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/lenders/companyDetails/{subdomain}
     * Java method: com.byzpass.demo.company.LenderController.getCompanyDetails
     */
    getCompanyDetails(subdomain: string, options?: O): RestResponse<CompanyListDto>;

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/companyLogo
     * Java method: com.byzpass.demo.company.LenderController.onboardLogo
     */
    onboardLogo(iRequest: UploadLogo, options?: O): RestResponse<void>;

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/companyUser
     * Java method: com.byzpass.demo.company.LenderController.onboardCompanyUser
     */
    onboardCompanyUser(toOnboard: OnboardUserDto, options?: O): RestResponse<void>;

    /**
     * HTTP DELETE /api/v1/lenders/phases
     * Java method: com.byzpass.demo.company.LenderController.deletePhases
     */
    deletePhases(iPhaseDtos: LoanPhaseDto[], options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v1/lenders/phases
     * Java method: com.byzpass.demo.company.LenderController.setPhases
     */
    setPhases(iPhaseDtos: LoanPhaseDto[], options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/lenders/subdomainLogoStub
     * Java method: com.byzpass.demo.company.LenderController.subdomainLogoStub
     */
    subdomainLogoStub(queryParams?: { sub?: string; }, options?: O): RestResponse<any>;

    /**
     * HTTP GET /api/v1/lenders/{companyId}/excelTemplateId
     * Java method: com.byzpass.demo.company.LenderController.getExcelTemplateId
     */
    getExcelTemplateId(companyId: string, options?: O): RestResponse<string>;

    /**
     * HTTP PUT /api/v1/lenders/{companyId}/excelTemplateId/{documentId}
     * Java method: com.byzpass.demo.company.LenderController.setExcelTemplateId
     */
    setExcelTemplateId(companyId: string, documentId: string, options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v1/lenders/{id}/employees
     * Java method: com.byzpass.demo.company.LenderController.employees
     */
    employees(id: string, options?: O): RestResponse<CompanyEmployeeListDto>;

    /**
     * HTTP GET /api/v1/lenders/{id}/phases
     * Java method: com.byzpass.demo.company.LenderController.getPhases
     */
    getPhases(id: string, options?: O): RestResponse<LoanPhaseDto[]>;

    /**
     * HTTP GET /api/v1/loans
     * Java method: com.byzpass.demo.loan.LoanController.loans
     */
    loans(queryParams?: { role?: ViewType; phaseCategory?: LoanPhaseCategoryType; phaseId?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<LoanDto>>;

    /**
     * HTTP POST /api/v1/loans/editBorrowUser/{loanId}/{userId}
     * Java method: com.byzpass.demo.loan.LoanController.editUser
     */
    editUser$POST$api_v1_loans_editBorrowUser_loanId_userId(loanId: string, userId: string, iAppUserNewData: AppUserDTO2, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP GET /api/v1/loans/{id}
     * Java method: com.byzpass.demo.loan.LoanController.getLoan
     */
    getLoan(id: string, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP PUT /api/v1/loans/{id}
     * Java method: com.byzpass.demo.loan.LoanController.putLoan
     */
    putLoan(id: string, iUpdateLoanDTO: UpdateLoanDTO, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP GET /api/v1/loans/{id}/getPackageUrls
     * Java method: com.byzpass.demo.loan.LoanController.getPackageUrls
     */
    getPackageUrls(id: string, options?: O): RestResponse<PackageUrlResponse>;

    /**
     * HTTP POST /api/v1/loans/{id}/invite
     * Java method: com.byzpass.demo.loan.LoanController.inviteUserToLoan
     */
    inviteUserToLoan(id: string, iInviteToLoanDTO: InviteToLoanDTO, options?: O): RestResponse<string>;

    /**
     * HTTP DELETE /api/v1/loans/{id}/lenderUser/{roleId}
     * Java method: com.byzpass.demo.loan.LoanController.removeLender
     */
    removeLender(id: string, roleId: string, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v1/loans/{id}/phase
     * Java method: com.byzpass.demo.loan.LoanController.setLoanPhase
     */
    setLoanPhase(id: string, iDto: LoanPhaseDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP PUT /api/v1/loans/{id}/pin
     * Java method: com.byzpass.demo.loan.LoanController.pinLoan
     */
    pinLoan(id: string, options?: O): RestResponse<boolean>;

    /**
     * HTTP POST /api/v1/loans/{id}/refreshPackages
     * Java method: com.byzpass.demo.loan.LoanController.generatePackage
     */
    generatePackage(id: string, options?: O): RestResponse<void>;

    /**
     * @deprecated
     * HTTP POST /api/v1/loans/{id}/status
     * Java method: com.byzpass.demo.loan.LoanController.setLoanStatus
     */
    setLoanStatus(id: string, iLoanStatus: LoanStatusDTO, options?: O): RestResponse<LoanDto>;

    /**
     * @deprecated for removal
     * HTTP GET /api/v1/loans/{id}/teamMembers
     * Java method: com.byzpass.demo.loan.LoanController.loanTeamMembers
     */
    loanTeamMembers(id: string, options?: O): RestResponse<AppUser[]>;

    /**
     * HTTP PUT /api/v1/loans/{id}/unpin
     * Java method: com.byzpass.demo.loan.LoanController.unpinLoan
     */
    unpinLoan(id: string, options?: O): RestResponse<boolean>;

    /**
     * HTTP GET /api/v1/loans/{loanId}/answers/{packageInfoId}
     * Java method: com.byzpass.demo.loan.LoanController.getAnswersForFormElement
     */
    getAnswersForFormElement(loanId: string, packageInfoId: string, options?: O): RestResponse<AnswerV2Dto[]>;

    /**
     * HTTP POST /api/v1/loans/{loanId}/borrowerRole
     * Java method: com.byzpass.demo.loan.LoanController.setUserRole
     */
    setUserRole(loanId: string, borrowerUser: LeadUserSetDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v1/loans/{loanId}/borrowerUser
     * Java method: com.byzpass.demo.loan.LoanController.addBorrowerUser
     */
    addBorrowerUser(loanId: string, iBorrowerUser: BorrowUserSetDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v1/loans/{loanId}/lenderUser
     * Java method: com.byzpass.demo.loan.LoanController.addLender
     */
    addLender(loanId: string, lenderUser: LeadUserSetDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP PUT /api/v1/loans/{loanId}/lenderUser
     * Java method: com.byzpass.demo.loan.LoanController.editLender
     */
    editLender(loanId: string, lenderUser: LeadUserSetDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP PUT /api/v1/loans/{loanId}/setReviewStatus/{reviewStatus}
     * Java method: com.byzpass.demo.loan.LoanController.setLoanReadyToReviewStatus
     */
    setLoanReadyToReviewStatus(loanId: string, reviewStatus: LoanReviewStatus, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP GET /api/v1/messages
     * Java method: com.byzpass.demo.message.MessageController.loanMessages
     */
    loanMessages(queryParams: { loanId: string; formElementId?: string; filterByUser?: string[]; page?: number; size?: number; }, options?: O): RestResponse<Message[]>;

    /**
     * HTTP POST /api/v1/messages
     * Java method: com.byzpass.demo.message.MessageController.sendMessage
     */
    sendMessage(iMessageSendDto: MessageSendDto, options?: O): RestResponse<Message>;

    /**
     * HTTP GET /api/v1/messages/originator/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.getMessageFullContentUrl
     */
    getMessageFullContentUrl(messageId: string, options?: O): RestResponse<MessageFullContentUrl>;

    /**
     * HTTP GET /api/v1/messages/originatorimplementation/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.getMessageFullContentImplementation
     */
    getMessageFullContentImplementation(messageId: string, options?: O): RestResponse<MessageOriginationStuff>;

    /**
     * HTTP POST /api/v1/messages/{messageId}/read
     * Java method: com.byzpass.demo.message.MessageController.markAsRead
     */
    markAsRead(messageId: string, options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v1/noauth/confirmForgotPassword
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.confirmForgotPassword
     */
    confirmForgotPassword(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/confirmSignup
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.confirmSignup
     */
    confirmSignup(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/forgotPassword
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.forgotPassword
     */
    forgotPassword(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/noauth/installurl
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.getInstallUrl
     */
    getInstallUrl(options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v1/noauth/login
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.login
     */
    login(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/logout
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.logout
     */
    logout(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/resendConfirmationCode
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.resendConfirmationCode
     */
    resendConfirmationCode(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/signup
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.signup
     */
    signup(iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/noauth/version
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.getVersion
     */
    getVersion(options?: O): RestResponse<VersionDto>;

    /**
     * HTTP POST /api/v1/persons
     * Java method: com.byzpass.demo.person.PersonController.createPerson
     */
    createPerson(iPersonDTO: PersonCreateDto, options?: O): RestResponse<PersonResponseDto>;

    /**
     * HTTP PUT /api/v1/persons
     * Java method: com.byzpass.demo.person.PersonController.updatePerson
     */
    updatePerson(iPersonDTO: PersonUpdateDto, options?: O): RestResponse<PersonResponseDto>;

    /**
     * HTTP GET /api/v1/persons/search
     * Java method: com.byzpass.demo.person.PersonController.search
     */
    search$GET$api_v1_persons_search(queryParams: { slice: string; }, options?: O): RestResponse<PersonResponseDto[]>;

    /**
     * HTTP GET /api/v1/persons/{id}
     * Java method: com.byzpass.demo.person.PersonController.getPerson
     */
    getPerson(id: string, options?: O): RestResponse<PersonResponseDto>;

    /**
     * HTTP GET /api/v1/persons/{id}/images/getUrlsToDownloadDriversLicenseImages
     * Java method: com.byzpass.demo.person.PersonController.usDriversLicenseDownloadImages
     */
    usDriversLicenseDownloadImages(id: string, options?: O): RestResponse<DriversLicenseImages>;

    /**
     * HTTP GET /api/v1/persons/{id}/images/getUrlsToUploadDriversLicenseImages
     * Java method: com.byzpass.demo.person.PersonController.usDriversLicenseUploadImages
     */
    usDriversLicenseUploadImages(id: string, options?: O): RestResponse<DriversLicenseImages>;

    /**
     * HTTP POST /api/v1/persons/{id}/irs4506C
     * Java method: com.byzpass.demo.person.PersonController.addIrs4506C
     */
    addIrs4506C(id: string, iIRS4506C: IRS4506C, options?: O): RestResponse<IRS4506C>;

    /**
     * HTTP POST /api/v1/persons/{id}/softCreditPull
     * Java method: com.byzpass.demo.person.PersonController.addSoftCreditPull
     */
    addSoftCreditPull(id: string, iNewSoftCreditPullDTO: NewSoftCreditPullDTO, options?: O): RestResponse<SoftCreditPull>;

    /**
     * HTTP POST /api/v1/persons/{id}/usDriversLicense
     * Java method: com.byzpass.demo.person.PersonController.addDriversLicense
     */
    addDriversLicense(id: string, iUSDriversLicense: USDriversLicense, options?: O): RestResponse<USDriversLicense>;

    /**
     * HTTP GET /api/v1/s/{tinyId}
     * Java method: com.byzpass.demo.document.tiny.TinyDocumentController.redirect
     */
    redirect(tinyId: string, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/search
     * Java method: com.byzpass.demo.search.SearchController.search
     */
    search$GET$api_v1_search(queryParams: { q: string; p?: number; d?: string; c?: string; ot?: string; }, options?: O): RestResponse<SearchResultsDTO>;

    /**
     * HTTP GET /api/v1/tasks
     * Java method: com.byzpass.demo.task.TaskController.tasksForLoanAndUser
     */
    tasksForLoanAndUser(queryParams: { loanId: string; userId?: string; }, options?: O): RestResponse<ITask[]>;

    /**
     * HTTP GET /api/v1/tasks/countTasks
     * Java method: com.byzpass.demo.task.TaskController.countTasksForLoanAndUser
     */
    countTasksForLoanAndUser(queryParams: { loanId: string; userId?: string; }, options?: O): RestResponse<{ [index: string]: number }>;

    /**
     * HTTP GET /api/v1/tasks/html
     * Java method: com.byzpass.demo.task.TaskController.tasksForLoanAndUserAsHtml
     */
    tasksForLoanAndUserAsHtml(queryParams: { loanId: string; userId?: string; }, options?: O): RestResponse<htmlDto>;

    /**
     * HTTP PUT /api/v1/transunion/reeschedule/{loanId}/{personId}
     * Java method: com.byzpass.demo.transunion.v2.TransunionController.reescheduleReport
     */
    reescheduleReport(loanId: string, personId: string, queryParams?: { forceSchedule?: boolean; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/venture/documenttypes
     * Java method: com.byzpass.demo.venture.VentureController.getVentureDocTypeInSherpas
     */
    getVentureDocTypeInSherpas(options?: O): RestResponse<VenturesDocType[]>;

    /**
     * HTTP PUT /api/v1/venture/export/loan/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.exportLoanToVenture
     */
    exportLoanToVenture(loanId: string, options?: O): RestResponse<VentureEntityDTO[]>;

    /**
     * HTTP PUT /api/v1/venture/import/loan/{loanId}/{loanIdVenture}
     * Java method: com.byzpass.demo.venture.VentureController.importLoanToVenture
     */
    importLoanToVenture(loanId: string, loanIdVenture: number, options?: O): RestResponse<VentureEntityDTO[]>;

    /**
     * HTTP GET /api/v1/venture/import/loan/{loanId}/{loanIdVenture}
     * Java method: com.byzpass.demo.venture.VentureController.importLoanToVentureTest
     */
    importLoanToVentureTest(loanId: string, loanIdVenture: number, options?: O): RestResponse<VentureEntityDTO[]>;

    /**
     * HTTP GET /api/v1/venture/loantypes/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.getVenturesLoanTypes
     */
    getVenturesLoanTypes(loanId: string, options?: O): RestResponse<VentureLoanTypeDTO[]>;

    /**
     * @deprecated
     * HTTP GET /api/v1/view
     * Java method: com.byzpass.demo.view.ViewController.getView
     */
    getView(queryParams?: { wizard?: boolean; viewType?: ViewType; }, options?: O): RestResponse<View>;

    /**
     * HTTP DELETE /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.clearMaintenance
     */
    clearMaintenance(options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.getMaintenance
     */
    getMaintenance(options?: O): RestResponse<MaintenanceWindowDto>;

    /**
     * HTTP POST /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.setMaintenance
     */
    setMaintenance(dto: MaintenanceWindowDto, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/conversion/image
     * Java method: ai.mysherpas.app.conversion.ConversionController.convertImage
     */
    convertImage(requestDto: ConversionRequestDto, options?: O): RestResponse<DeferredResult<ResponseEntity<ConversionResponseDto>>>;

    /**
     * HTTP POST /api/v2/entities/bankAsset
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createBankAssets
     */
    createBankAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP POST /api/v2/entities/businesses
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createBusinesses
     */
    createBusinesses(dto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP POST /api/v2/entities/equipment
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createEquipmentAssets
     */
    createEquipmentAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP POST /api/v2/entities/individualFromPerson
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createIndividualFromPerson
     */
    createIndividualFromPerson(iDto: IdDto, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP GET /api/v2/entities/individualFromPerson/{id}
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.getIndividualFromPerson
     */
    getIndividualFromPerson(id: string, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP POST /api/v2/entities/individuals
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createIndividuals
     */
    createIndividuals(lDto: IndividualRequestDto, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP GET /api/v2/entities/personFromIndividual/{id}
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.getPersonFromIndividual
     */
    getPersonFromIndividual(id: string, options?: O): RestResponse<PersonResponseDto>;

    /**
     * HTTP POST /api/v2/entities/randomAsset
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createOtherAssets
     */
    createOtherAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP POST /api/v2/entities/realEstate
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createRealEstateAssets
     */
    createRealEstateAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP PUT /api/v2/entities/{entityId}/rename
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.renameEntity
     */
    renameEntity(entityId: string, renameDto: RenameEntityDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/frontend/keys
     * Java method: com.byzpass.demo.util.frontend.FrontendKeys.getKeys
     */
    getKeys(options?: O): RestResponse<FeKeys>;

    /**
     * HTTP GET /api/v2/information/bankAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentBankInformation
     */
    getMostRecentBankInformation(entityId: string, options?: O): RestResponse<BankAssetResponseDto>;

    /**
     * HTTP POST /api/v2/information/bankAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeBankInformation
     */
    postUpSomeBankInformation(entityId: string, information: BankAssetRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/information/business/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentBusinessEntityInformation
     */
    getMostRecentBusinessEntityInformation(entityId: string, options?: O): RestResponse<BusinessResponseDto>;

    /**
     * HTTP POST /api/v2/information/business/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeBusinessEntityInformation
     */
    postUpSomeBusinessEntityInformation(entityId: string, information: BusinessRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/information/equipment/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentEquipmentInformation
     */
    getMostRecentEquipmentInformation(entityId: string, options?: O): RestResponse<EquipmentResponseDto>;

    /**
     * HTTP POST /api/v2/information/equipment/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeEquipmentInformation
     */
    postUpSomeEquipmentInformation(entityId: string, information: EquipmentRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/information/individual/usdl/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getADriversLicense
     */
    getADriversLicense(entityId: string, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP POST /api/v2/information/individual/usdl/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpADriversLicense
     */
    postUpADriversLicense(entityId: string, dto: DlRequest, options?: O): RestResponse<ResponseEntity<any>>;

    /**
     * HTTP GET /api/v2/information/individual/{entityId}/riskreportstatus/{loanId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getIndividualInfo
     */
    getIndividualInfo(entityId: string, loanId: string, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP GET /api/v2/information/randomAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentRandomAssetInformation
     */
    getMostRecentRandomAssetInformation(entityId: string, options?: O): RestResponse<RandomAssetResponseDto>;

    /**
     * HTTP POST /api/v2/information/randomAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeRandomAssetInformation
     */
    postUpSomeRandomAssetInformation(entityId: string, information: RandomAssetRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/information/realEstate/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentRealEstateInformation
     */
    getMostRecentRealEstateInformation(entityId: string, options?: O): RestResponse<RealEstateResponseDto>;

    /**
     * HTTP POST /api/v2/information/realEstate/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeRealEstateInformation
     */
    postUpSomeRealEstateInformation(entityId: string, information: RealEstateRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/loanmanager/applyNewTemplateToExistingEntity
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyNewTemplateToExistingEntity
     */
    applyNewTemplateToExistingEntity(iDto: NewEntityTemplateDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/applyNewTemplateToExistingLoan
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyNewTemplateToExistingLoan
     */
    applyNewTemplateToExistingLoan(iDto: NewLoanTemplateDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/applyTemplate
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyTemplate
     */
    applyTemplate(iDto: ApplyTemplateDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/createEntity
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createEntity
     */
    createEntity(iDto: CreateEntityCompleteDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/createFromTemplate
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createFromTemplate2
     */
    createFromTemplate2(iDto: NewLoanDTO, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createElements
     */
    createElements$POST$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteElements
     */
    deleteElements$DELETE$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.updateElements
     */
    updateElements$PUT$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/answer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.addAnswerToElement
     */
    addAnswerToElement$POST$api_v2_loanmanager_elements_answer(iDto: AnswerFormElementDto, options?: O): RestResponse<FormElementV2ResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/elements/answer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteAnswerToElement
     */
    deleteAnswerToElement(iDto: AnswerFormElementDto, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/copy
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.copyElements
     */
    copyElements(iDto: CopyFormElementsDto, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/handleExpiring
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.handleExpiringAndExpiredElements
     */
    handleExpiringAndExpiredElements(options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/{id}/resetAnswer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.resetTemplateAnswerOnElement
     */
    resetTemplateAnswerOnElement(id: string, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/loanmanager/entityLabel
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.addEntityLabel
     */
    addEntityLabel(iDto: EntityLabelDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP GET /api/v2/loanmanager/linkForLoan/{packageId}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLinkForElementV2
     */
    getLinkForElementV2(packageId: string, options?: O): RestResponse<FormElementUrlResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/template/{id}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getAllWithTemplate
     */
    getAllWithTemplate(id: string, options?: O): RestResponse<LoanDto[]>;

    /**
     * HTTP GET /api/v2/loanmanager/{id}/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLoanElements
     */
    getLoanElements(id: string, queryParams?: { view?: LoanViewType; }, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteElementsZip
     */
    deleteElementsZip(id: string, queryParams: { jobid: string; }, options?: O): RestResponse<ZipFileJobResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.generateElementsZip
     */
    generateElementsZip(id: string, iDto: ZipFileJobRequestDto, options?: O): RestResponse<ZipFileJobResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getElementsZip
     */
    getElementsZip(id: string, queryParams?: { jobid?: string; }, options?: O): RestResponse<ZipFileJobsResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/{id}/folders
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLoanFolders
     */
    getLoanFolders(id: string, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/{loanId}/entity/{entityId}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.removeEntityFromLoan
     */
    removeEntityFromLoan(loanId: string, entityId: string, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/shoebox/item
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.createItem
     */
    createItem(iDto: ShoeboxItemRequestDto, options?: O): RestResponse<ShoeboxItemResponseDto>;

    /**
     * HTTP DELETE /api/v2/shoebox/item
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.deleteItem
     */
    deleteItem(iDto: IdDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/shoebox/loan
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.getItemsByLoan
     */
    getItemsByLoan(queryParams: { loan: string; }, options?: O): RestResponse<ShoeboxItemResponseDto[]>;

    /**
     * HTTP GET /api/v2/support/categories
     * Java method: ai.mysherpas.app.support.SupportController.getCategories
     */
    getCategories(options?: O): RestResponse<CategoryDto[]>;

    /**
     * HTTP POST /api/v2/support/category
     * Java method: ai.mysherpas.app.support.SupportController.createCategory
     */
    createCategory(iDto: CategoryDto, options?: O): RestResponse<CategoryDto>;

    /**
     * HTTP PUT /api/v2/support/category
     * Java method: ai.mysherpas.app.support.SupportController.updateCategory
     */
    updateCategory(iDto: CategoryDto, options?: O): RestResponse<CategoryDto>;

    /**
     * HTTP DELETE /api/v2/support/category/{id}
     * Java method: ai.mysherpas.app.support.SupportController.deleteCategory
     */
    deleteCategory(id: string, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/support/entries
     * Java method: ai.mysherpas.app.support.SupportController.getEntries
     */
    getEntries(options?: O): RestResponse<SupportResponseDto[]>;

    /**
     * HTTP POST /api/v2/support/entry
     * Java method: ai.mysherpas.app.support.SupportController.createEntry
     */
    createEntry(iDto: SupportRequestDto, options?: O): RestResponse<SupportResponseDto>;

    /**
     * HTTP PUT /api/v2/support/entry
     * Java method: ai.mysherpas.app.support.SupportController.updateEntry
     */
    updateEntry(iDto: SupportRequestDto, options?: O): RestResponse<SupportResponseDto>;

    /**
     * HTTP DELETE /api/v2/support/entry/{id}
     * Java method: ai.mysherpas.app.support.SupportController.deleteEntry
     */
    deleteEntry(id: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/support/views/increment/{supportId}
     * Java method: ai.mysherpas.app.support.SupportController.incrementViews
     */
    incrementViews(supportId: string, options?: O): RestResponse<SupportResponseDto>;

    /**
     * HTTP GET /api/v2/tasks/consolidated/{loanId}
     * Java method: com.byzpass.demo.task.Task2Controller.consolidatedTasks
     */
    consolidatedTasks(loanId: string, options?: O): RestResponse<ConsolidatedTasksDto>;

    /**
     * HTTP POST /api/v2/tasks/element/{id}
     * Java method: com.byzpass.demo.task.Task2Controller.tasksForV2Element
     */
    tasksForV2Element(id: string, iNewTaskDtos: NewTaskDto[], options?: O): RestResponse<NewTaskResponseDto2[]>;

    /**
     * HTTP POST /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.create
     */
    create(iDto: TemplatesRequestDto, options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP DELETE /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.delete
     */
    delete(iDto: TemplatesRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.findAllByUser
     */
    findAllByUser(options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP PUT /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.update
     */
    update(iDto: TemplatesRequestDto, options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP GET /api/v2/templates/borrower
     * Java method: ai.mysherpas.app.templates.TemplatesController.findByEntityTypeAndUser
     */
    findByEntityTypeAndUser(queryParams: { entityType: SherpaEntityType; loanId: string; }, options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP POST /api/v2/templates/copy
     * Java method: ai.mysherpas.app.templates.TemplatesController.copyTemplate
     */
    copyTemplate(iDto: CopyTemplateDto, options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP GET /api/v2/templates/download/company/{id}
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForCompany
     */
    downloadCsvForCompany(id: string, options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/templates/download/{id}
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForSelected
     */
    downloadCsvForSelected(id: string, options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/templates/downloadBlank
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadBlank
     */
    downloadBlank(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/templates/downloadCsv
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForUser
     */
    downloadCsvForUser(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/templates/downloadMultiple
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadRequestedTemplates
     */
    downloadRequestedTemplates(queryParams: { id: string[]; }, options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.createElements
     */
    createElements$POST$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<TemplateElementsResponseDto>;

    /**
     * HTTP DELETE /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.deleteElements
     */
    deleteElements$DELETE$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.updateElements
     */
    updateElements$PUT$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<TemplateElementsResponseDto>;

    /**
     * HTTP POST /api/v2/templates/elements/answer
     * Java method: ai.mysherpas.app.templates.TemplatesController.addAnswerToElement
     */
    addAnswerToElement$POST$api_v2_templates_elements_answer(iDto: AnswerTemplateElementDto, options?: O): RestResponse<TemplateElementResponseDto>;

    /**
     * HTTP GET /api/v2/templates/types
     * Java method: ai.mysherpas.app.templates.TemplatesController.getLocationTypes
     */
    getLocationTypes(options?: O): RestResponse<TemplateTypeMapsDto>;

    /**
     * HTTP POST /api/v2/templates/upload
     * Java method: ai.mysherpas.app.templates.TemplatesController.upload
     */
    upload(queryParams: { file: Blob; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/templates/{id}/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.getElements
     */
    getElements(id: string, options?: O): RestResponse<TemplateElementsResponseDto>;

    /**
     * HTTP GET /api/v2/workflow/assignments/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.getAssignments
     */
    getAssignments(packageInfoId: string, options?: O): RestResponse<WorkflowPhaseAgentEntity[]>;

    /**
     * HTTP GET /api/v2/workflow/createSelfSign/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.generateEnvelopeForSelfSignGet
     */
    generateEnvelopeForSelfSignGet(packageInfoId: string, options?: O): RestResponse<DocusignViewDTO>;

    /**
     * HTTP PUT /api/v2/workflow/docusign/{packageInfoId}/{envelopeId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.checkEnvelopeStatus
     */
    checkEnvelopeStatus(packageInfoId: string, envelopeId: string, options?: O): RestResponse<WorkflowEntity>;

    /**
     * HTTP POST /api/v2/workflow/form/payment/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.fillFormPayment
     */
    fillFormPayment(packageInfoId: string, iForm: PaymentFormDTO, options?: O): RestResponse<PaymentFormDTO>;

    /**
     * HTTP GET /api/v2/workflow/foruser
     * Java method: com.byzpass.demo.workflow.WorkflowController.getWorkflowForLoggedUser
     */
    getWorkflowForLoggedUser(options?: O): RestResponse<WorkflowEntity[]>;

    /**
     * HTTP PUT /api/v2/workflow/phase/{phaseType}/{packageInfoId}/{phaseStatus}
     * Java method: com.byzpass.demo.workflow.WorkflowController.setPhaseStatus
     */
    setPhaseStatus(phaseType: WorkflowPhaseType, packageInfoId: string, phaseStatus: WorkflowPhaseStatus, options?: O): RestResponse<WorkflowEntity>;

    /**
     * HTTP GET /api/v2/workflow/preview/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.getPreview
     */
    getPreview(packageInfoId: string, options?: O): RestResponse<any>;

    /**
     * HTTP GET /api/v2/workflow/roles/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.getWorkflowRoles
     */
    getWorkflowRoles(packageInfoId: string, options?: O): RestResponse<WorkflowRolesDTO>;

    /**
     * HTTP POST /api/v2/workflow/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.addWorkflow
     */
    addWorkflow(packageInfoId: string, iWorkFlowDto: WorkflowCreateDTO, options?: O): RestResponse<WorkflowEntity>;

    /**
     * HTTP POST /beep/toxiproxy/disable
     * Java method: com.byzpass.demo.util.ToxiproxyController.disableToxics
     */
    disableToxics(options?: O): RestResponse<string>;

    /**
     * HTTP POST /beep/toxiproxy/enable
     * Java method: com.byzpass.demo.util.ToxiproxyController.enableToxics
     */
    enableToxics(options?: O): RestResponse<string>;

    /**
     * HTTP POST /test/500
     * Java method: com.byzpass.demo.common.TestController.a500
     */
    a500(options?: O): RestResponse<string>;
}

export class RestApplicationClient<O> implements RestApplication<O> {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /anonymous/**
     * Java method: com.byzpass.demo.loan.AnonymousController.showAnonymous
     */
    showAnonymous(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`anonymous/**`, options: options });
    }

    /**
     * HTTP GET /api/v1/action
     * Java method: com.byzpass.demo.linkaction.LinkActionController.doAction
     */
    doAction(queryParams: { id: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/action`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/action/pendingInvites
     * Java method: com.byzpass.demo.linkaction.LinkActionController.getPendingInvites
     */
    getPendingInvites(options?: O): RestResponse<LinkActionInviteToLoan[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/action/pendingInvites`, options: options });
    }

    /**
     * HTTP DELETE /api/v1/admin/labels
     * Java method: com.byzpass.demo.labels.LabelAdminController.deleteLabels
     */
    deleteLabels(toDelete: LabelDeleteDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/admin/labels`, data: toDelete, options: options });
    }

    /**
     * HTTP GET /api/v1/admin/labels/forCompany/{companyId}
     * Java method: com.byzpass.demo.labels.LabelAdminController.getLabelsForCompany
     */
    getLabelsForCompany(companyId: string, options?: O): RestResponse<LabelsListDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/admin/labels/forCompany/${companyId}`, options: options });
    }

    /**
     * HTTP POST /api/v1/admin/labels/forCompany/{companyId}
     * Java method: com.byzpass.demo.labels.LabelAdminController.putLabels
     */
    putLabels(companyId: string, create: LabelsListDto, options?: O): RestResponse<LabelsListDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/admin/labels/forCompany/${companyId}`, data: create, options: options });
    }

    /**
     * HTTP POST /api/v1/auth/addUser
     * Java method: com.byzpass.demo.security.SecurityAuthController.addUser
     */
    addUser(iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<AppUserDTO2> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/auth/addUser`, data: iSignupRequestDto, options: options });
    }

    /**
     * HTTP POST /api/v1/auth/changePassword
     * Java method: com.byzpass.demo.security.SecurityAuthController.changePassword
     */
    changePassword(iChangePasswordRequest: AppUserChangePasswordRequestDto, options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/auth/changePassword`, data: iChangePasswordRequest, options: options });
    }

    /**
     * HTTP POST /api/v1/auth/editUser/{id}
     * Java method: com.byzpass.demo.security.SecurityAuthController.editUser
     */
    editUser$POST$api_v1_auth_editUser_id(id: string, iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<AppUserDTO2> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/auth/editUser/${id}`, data: iSignupRequestDto, options: options });
    }

    /**
     * HTTP POST /api/v1/auth/globalLogout
     * Java method: com.byzpass.demo.security.SecurityAuthController.globalLogout
     */
    globalLogout(options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/auth/globalLogout`, options: options });
    }

    /**
     * HTTP GET /api/v1/auth/isAuthenticated
     * Java method: com.byzpass.demo.security.SecurityAuthController.isAuthenticated
     */
    isAuthenticated(options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/auth/isAuthenticated`, options: options });
    }

    /**
     * HTTP GET /api/v1/auth/timezones
     * Java method: com.byzpass.demo.security.SecurityAuthController.getTimezones
     */
    getTimezones(options?: O): RestResponse<TimezoneDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/auth/timezones`, options: options });
    }

    /**
     * HTTP POST /api/v1/auth/update
     * Java method: com.byzpass.demo.security.SecurityAuthController.updateAppUser
     */
    updateAppUser(iDto: CreateUpdateAppUserDto, options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/auth/update`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v1/auth/userSettings
     * Java method: com.byzpass.demo.security.SecurityAuthController.getSettings
     */
    getSettings(options?: O): RestResponse<AppUserPreferencesDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/auth/userSettings`, options: options });
    }

    /**
     * HTTP PUT /api/v1/auth/userSettings
     * Java method: com.byzpass.demo.security.SecurityAuthController.putSettings
     */
    putSettings(iDto: AppUserPreferencesDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/auth/userSettings`, data: iDto, options: options });
    }

    /**
     * HTTP PUT /api/v1/clear/reeschedule/personquickflag/{loanId}/{personId}
     * Java method: com.byzpass.demo.clear.ClearController.reeschedulePersonQuickFlagReport
     */
    reeschedulePersonQuickFlagReport(loanId: string, personId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/clear/reeschedule/personquickflag/${loanId}/${personId}`, options: options });
    }

    /**
     * HTTP PUT /api/v1/clear/reschedule/businessquickflag/{loanId}/{entityId}
     * Java method: com.byzpass.demo.clear.ClearController.reescheduleBusinessQuickFlagReport
     */
    reescheduleBusinessQuickFlagReport(loanId: string, entityId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/clear/reschedule/businessquickflag/${loanId}/${entityId}`, options: options });
    }

    /**
     * HTTP PUT /api/v1/clear/schedule/businessquickflag/{loanId}/{entityId}
     * Java method: com.byzpass.demo.clear.ClearController.scheduleBusinessQuickFlagReport
     */
    scheduleBusinessQuickFlagReport(loanId: string, entityId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/clear/schedule/businessquickflag/${loanId}/${entityId}`, options: options });
    }

    /**
     * HTTP POST /api/v1/documents
     * Java method: com.byzpass.demo.document.DocumentController.postUploadUrlFor
     */
    postUploadUrlFor(iUpload: Upload, options?: O): RestResponse<Document> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/documents`, data: iUpload, options: options });
    }

    /**
     * HTTP GET /api/v1/documents/uploadUrlFor
     * Java method: com.byzpass.demo.document.DocumentController.getUploadUrlFor
     */
    getUploadUrlFor(queryParams: { name: string; iProviderType?: ProviderType; formElementId?: string; loanId?: string; }, options?: O): RestResponse<Upload> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/documents/uploadUrlFor`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/documents/{id}/withDownloadUrl
     * Java method: com.byzpass.demo.document.DocumentController.getDocumentWithDownloadUrl
     */
    getDocumentWithDownloadUrl(id: string, queryParams?: { formElementId?: string; loanId?: string; }, options?: O): RestResponse<Document> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/documents/${id}/withDownloadUrl`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/fepreference/{key}
     * Java method: com.byzpass.demo.fepreference.FePreferenceController.getPreference
     */
    getPreference(key: string, options?: O): RestResponse<FePreferenceEntity> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/fepreference/${key}`, options: options });
    }

    /**
     * HTTP POST /api/v1/fepreference/{key}
     * Java method: com.byzpass.demo.fepreference.FePreferenceController.savePreference
     */
    savePreference(key: string, queryParams: { value: { [index: string]: string }; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/fepreference/${key}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/javascriptConstants
     * Java method: com.byzpass.demo.services.ConstantsForClientController.constants
     */
    constants(options?: O): RestResponse<PerUserConstants> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/javascriptConstants`, options: options });
    }

    /**
     * HTTP GET /api/v1/javascriptConstants/hotjar.js
     * Java method: com.byzpass.demo.services.ConstantsForClientController.hotjar
     */
    hotjar(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/javascriptConstants/hotjar.js`, options: options });
    }

    /**
     * HTTP GET /api/v1/labels/loan/{loanId}/availableToMe
     * Java method: com.byzpass.demo.labels.LabelController.getLabels
     */
    getLabels(loanId: string, options?: O): RestResponse<LabelsListDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/labels/loan/${loanId}/availableToMe`, options: options });
    }

    /**
     * HTTP POST /api/v1/labels/loan/{loanId}/setForUser
     * Java method: com.byzpass.demo.labels.LabelController.putLabelOnSomeone
     */
    putLabelOnSomeone(loanId: string, toset: SetLabelOnUserDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/labels/loan/${loanId}/setForUser`, data: toset, options: options });
    }

    /**
     * @deprecated
     * HTTP GET /api/v1/lenders/companies
     * Java method: com.byzpass.demo.company.LenderController.getCompanies
     */
    getCompanies(options?: O): RestResponse<CompanyListDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/lenders/companies`, options: options });
    }

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/company
     * Java method: com.byzpass.demo.company.LenderController.onboardCompany
     */
    onboardCompany(iOnboardCompany: OnboardCompany, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/lenders/company`, data: iOnboardCompany, options: options });
    }

    /**
     * @deprecated
     * HTTP PUT /api/v1/lenders/company/{id}
     * Java method: com.byzpass.demo.company.LenderController.updateCompany
     */
    updateCompany(id: string, iOnboardCompany: OnboardCompany, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/lenders/company/${id}`, data: iOnboardCompany, options: options });
    }

    /**
     * HTTP GET /api/v1/lenders/companyDetails/{subdomain}
     * Java method: com.byzpass.demo.company.LenderController.getCompanyDetails
     */
    getCompanyDetails(subdomain: string, options?: O): RestResponse<CompanyListDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/lenders/companyDetails/${subdomain}`, options: options });
    }

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/companyLogo
     * Java method: com.byzpass.demo.company.LenderController.onboardLogo
     */
    onboardLogo(iRequest: UploadLogo, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/lenders/companyLogo`, data: iRequest, options: options });
    }

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/companyUser
     * Java method: com.byzpass.demo.company.LenderController.onboardCompanyUser
     */
    onboardCompanyUser(toOnboard: OnboardUserDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/lenders/companyUser`, data: toOnboard, options: options });
    }

    /**
     * HTTP DELETE /api/v1/lenders/phases
     * Java method: com.byzpass.demo.company.LenderController.deletePhases
     */
    deletePhases(iPhaseDtos: LoanPhaseDto[], options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/lenders/phases`, data: iPhaseDtos, options: options });
    }

    /**
     * HTTP POST /api/v1/lenders/phases
     * Java method: com.byzpass.demo.company.LenderController.setPhases
     */
    setPhases(iPhaseDtos: LoanPhaseDto[], options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/lenders/phases`, data: iPhaseDtos, options: options });
    }

    /**
     * HTTP GET /api/v1/lenders/subdomainLogoStub
     * Java method: com.byzpass.demo.company.LenderController.subdomainLogoStub
     */
    subdomainLogoStub(queryParams?: { sub?: string; }, options?: O): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/lenders/subdomainLogoStub`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/lenders/{companyId}/excelTemplateId
     * Java method: com.byzpass.demo.company.LenderController.getExcelTemplateId
     */
    getExcelTemplateId(companyId: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/lenders/${companyId}/excelTemplateId`, options: options });
    }

    /**
     * HTTP PUT /api/v1/lenders/{companyId}/excelTemplateId/{documentId}
     * Java method: com.byzpass.demo.company.LenderController.setExcelTemplateId
     */
    setExcelTemplateId(companyId: string, documentId: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/lenders/${companyId}/excelTemplateId/${documentId}`, options: options });
    }

    /**
     * HTTP GET /api/v1/lenders/{id}/employees
     * Java method: com.byzpass.demo.company.LenderController.employees
     */
    employees(id: string, options?: O): RestResponse<CompanyEmployeeListDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/lenders/${id}/employees`, options: options });
    }

    /**
     * HTTP GET /api/v1/lenders/{id}/phases
     * Java method: com.byzpass.demo.company.LenderController.getPhases
     */
    getPhases(id: string, options?: O): RestResponse<LoanPhaseDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/lenders/${id}/phases`, options: options });
    }

    /**
     * HTTP GET /api/v1/loans
     * Java method: com.byzpass.demo.loan.LoanController.loans
     */
    loans(queryParams?: { role?: ViewType; phaseCategory?: LoanPhaseCategoryType; phaseId?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<LoanDto>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/loans`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/v1/loans/editBorrowUser/{loanId}/{userId}
     * Java method: com.byzpass.demo.loan.LoanController.editUser
     */
    editUser$POST$api_v1_loans_editBorrowUser_loanId_userId(loanId: string, userId: string, iAppUserNewData: AppUserDTO2, options?: O): RestResponse<AppUserDTO2> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/loans/editBorrowUser/${loanId}/${userId}`, data: iAppUserNewData, options: options });
    }

    /**
     * HTTP GET /api/v1/loans/{id}
     * Java method: com.byzpass.demo.loan.LoanController.getLoan
     */
    getLoan(id: string, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/loans/${id}`, options: options });
    }

    /**
     * HTTP PUT /api/v1/loans/{id}
     * Java method: com.byzpass.demo.loan.LoanController.putLoan
     */
    putLoan(id: string, iUpdateLoanDTO: UpdateLoanDTO, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/loans/${id}`, data: iUpdateLoanDTO, options: options });
    }

    /**
     * HTTP GET /api/v1/loans/{id}/getPackageUrls
     * Java method: com.byzpass.demo.loan.LoanController.getPackageUrls
     */
    getPackageUrls(id: string, options?: O): RestResponse<PackageUrlResponse> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/loans/${id}/getPackageUrls`, options: options });
    }

    /**
     * HTTP POST /api/v1/loans/{id}/invite
     * Java method: com.byzpass.demo.loan.LoanController.inviteUserToLoan
     */
    inviteUserToLoan(id: string, iInviteToLoanDTO: InviteToLoanDTO, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/loans/${id}/invite`, data: iInviteToLoanDTO, options: options });
    }

    /**
     * HTTP DELETE /api/v1/loans/{id}/lenderUser/{roleId}
     * Java method: com.byzpass.demo.loan.LoanController.removeLender
     */
    removeLender(id: string, roleId: string, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/loans/${id}/lenderUser/${roleId}`, options: options });
    }

    /**
     * HTTP POST /api/v1/loans/{id}/phase
     * Java method: com.byzpass.demo.loan.LoanController.setLoanPhase
     */
    setLoanPhase(id: string, iDto: LoanPhaseDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/loans/${id}/phase`, data: iDto, options: options });
    }

    /**
     * HTTP PUT /api/v1/loans/{id}/pin
     * Java method: com.byzpass.demo.loan.LoanController.pinLoan
     */
    pinLoan(id: string, options?: O): RestResponse<boolean> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/loans/${id}/pin`, options: options });
    }

    /**
     * HTTP POST /api/v1/loans/{id}/refreshPackages
     * Java method: com.byzpass.demo.loan.LoanController.generatePackage
     */
    generatePackage(id: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/loans/${id}/refreshPackages`, options: options });
    }

    /**
     * @deprecated
     * HTTP POST /api/v1/loans/{id}/status
     * Java method: com.byzpass.demo.loan.LoanController.setLoanStatus
     */
    setLoanStatus(id: string, iLoanStatus: LoanStatusDTO, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/loans/${id}/status`, data: iLoanStatus, options: options });
    }

    /**
     * @deprecated for removal
     * HTTP GET /api/v1/loans/{id}/teamMembers
     * Java method: com.byzpass.demo.loan.LoanController.loanTeamMembers
     */
    loanTeamMembers(id: string, options?: O): RestResponse<AppUser[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/loans/${id}/teamMembers`, options: options });
    }

    /**
     * HTTP PUT /api/v1/loans/{id}/unpin
     * Java method: com.byzpass.demo.loan.LoanController.unpinLoan
     */
    unpinLoan(id: string, options?: O): RestResponse<boolean> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/loans/${id}/unpin`, options: options });
    }

    /**
     * HTTP GET /api/v1/loans/{loanId}/answers/{packageInfoId}
     * Java method: com.byzpass.demo.loan.LoanController.getAnswersForFormElement
     */
    getAnswersForFormElement(loanId: string, packageInfoId: string, options?: O): RestResponse<AnswerV2Dto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/loans/${loanId}/answers/${packageInfoId}`, options: options });
    }

    /**
     * HTTP POST /api/v1/loans/{loanId}/borrowerRole
     * Java method: com.byzpass.demo.loan.LoanController.setUserRole
     */
    setUserRole(loanId: string, borrowerUser: LeadUserSetDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/loans/${loanId}/borrowerRole`, data: borrowerUser, options: options });
    }

    /**
     * HTTP POST /api/v1/loans/{loanId}/borrowerUser
     * Java method: com.byzpass.demo.loan.LoanController.addBorrowerUser
     */
    addBorrowerUser(loanId: string, iBorrowerUser: BorrowUserSetDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/loans/${loanId}/borrowerUser`, data: iBorrowerUser, options: options });
    }

    /**
     * HTTP POST /api/v1/loans/{loanId}/lenderUser
     * Java method: com.byzpass.demo.loan.LoanController.addLender
     */
    addLender(loanId: string, lenderUser: LeadUserSetDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/loans/${loanId}/lenderUser`, data: lenderUser, options: options });
    }

    /**
     * HTTP PUT /api/v1/loans/{loanId}/lenderUser
     * Java method: com.byzpass.demo.loan.LoanController.editLender
     */
    editLender(loanId: string, lenderUser: LeadUserSetDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/loans/${loanId}/lenderUser`, data: lenderUser, options: options });
    }

    /**
     * HTTP PUT /api/v1/loans/{loanId}/setReviewStatus/{reviewStatus}
     * Java method: com.byzpass.demo.loan.LoanController.setLoanReadyToReviewStatus
     */
    setLoanReadyToReviewStatus(loanId: string, reviewStatus: LoanReviewStatus, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/loans/${loanId}/setReviewStatus/${reviewStatus}`, options: options });
    }

    /**
     * HTTP GET /api/v1/messages
     * Java method: com.byzpass.demo.message.MessageController.loanMessages
     */
    loanMessages(queryParams: { loanId: string; formElementId?: string; filterByUser?: string[]; page?: number; size?: number; }, options?: O): RestResponse<Message[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/messages`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/v1/messages
     * Java method: com.byzpass.demo.message.MessageController.sendMessage
     */
    sendMessage(iMessageSendDto: MessageSendDto, options?: O): RestResponse<Message> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/messages`, data: iMessageSendDto, options: options });
    }

    /**
     * HTTP GET /api/v1/messages/originator/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.getMessageFullContentUrl
     */
    getMessageFullContentUrl(messageId: string, options?: O): RestResponse<MessageFullContentUrl> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/messages/originator/${messageId}`, options: options });
    }

    /**
     * HTTP GET /api/v1/messages/originatorimplementation/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.getMessageFullContentImplementation
     */
    getMessageFullContentImplementation(messageId: string, options?: O): RestResponse<MessageOriginationStuff> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/messages/originatorimplementation/${messageId}`, options: options });
    }

    /**
     * HTTP POST /api/v1/messages/{messageId}/read
     * Java method: com.byzpass.demo.message.MessageController.markAsRead
     */
    markAsRead(messageId: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/messages/${messageId}/read`, options: options });
    }

    /**
     * HTTP POST /api/v1/noauth/confirmForgotPassword
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.confirmForgotPassword
     */
    confirmForgotPassword(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/noauth/confirmForgotPassword`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v1/noauth/confirmSignup
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.confirmSignup
     */
    confirmSignup(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/noauth/confirmSignup`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v1/noauth/forgotPassword
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.forgotPassword
     */
    forgotPassword(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/noauth/forgotPassword`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v1/noauth/installurl
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.getInstallUrl
     */
    getInstallUrl(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/noauth/installurl`, options: options });
    }

    /**
     * HTTP POST /api/v1/noauth/login
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.login
     */
    login(options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/noauth/login`, options: options });
    }

    /**
     * HTTP POST /api/v1/noauth/logout
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.logout
     */
    logout(options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/noauth/logout`, options: options });
    }

    /**
     * HTTP POST /api/v1/noauth/resendConfirmationCode
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.resendConfirmationCode
     */
    resendConfirmationCode(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/noauth/resendConfirmationCode`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v1/noauth/signup
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.signup
     */
    signup(iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<SecurityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/noauth/signup`, data: iSignupRequestDto, options: options });
    }

    /**
     * HTTP GET /api/v1/noauth/version
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.getVersion
     */
    getVersion(options?: O): RestResponse<VersionDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/noauth/version`, options: options });
    }

    /**
     * HTTP POST /api/v1/persons
     * Java method: com.byzpass.demo.person.PersonController.createPerson
     */
    createPerson(iPersonDTO: PersonCreateDto, options?: O): RestResponse<PersonResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/persons`, data: iPersonDTO, options: options });
    }

    /**
     * HTTP PUT /api/v1/persons
     * Java method: com.byzpass.demo.person.PersonController.updatePerson
     */
    updatePerson(iPersonDTO: PersonUpdateDto, options?: O): RestResponse<PersonResponseDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/persons`, data: iPersonDTO, options: options });
    }

    /**
     * HTTP GET /api/v1/persons/search
     * Java method: com.byzpass.demo.person.PersonController.search
     */
    search$GET$api_v1_persons_search(queryParams: { slice: string; }, options?: O): RestResponse<PersonResponseDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/persons/search`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/persons/{id}
     * Java method: com.byzpass.demo.person.PersonController.getPerson
     */
    getPerson(id: string, options?: O): RestResponse<PersonResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/persons/${id}`, options: options });
    }

    /**
     * HTTP GET /api/v1/persons/{id}/images/getUrlsToDownloadDriversLicenseImages
     * Java method: com.byzpass.demo.person.PersonController.usDriversLicenseDownloadImages
     */
    usDriversLicenseDownloadImages(id: string, options?: O): RestResponse<DriversLicenseImages> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/persons/${id}/images/getUrlsToDownloadDriversLicenseImages`, options: options });
    }

    /**
     * HTTP GET /api/v1/persons/{id}/images/getUrlsToUploadDriversLicenseImages
     * Java method: com.byzpass.demo.person.PersonController.usDriversLicenseUploadImages
     */
    usDriversLicenseUploadImages(id: string, options?: O): RestResponse<DriversLicenseImages> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/persons/${id}/images/getUrlsToUploadDriversLicenseImages`, options: options });
    }

    /**
     * HTTP POST /api/v1/persons/{id}/irs4506C
     * Java method: com.byzpass.demo.person.PersonController.addIrs4506C
     */
    addIrs4506C(id: string, iIRS4506C: IRS4506C, options?: O): RestResponse<IRS4506C> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/persons/${id}/irs4506C`, data: iIRS4506C, options: options });
    }

    /**
     * HTTP POST /api/v1/persons/{id}/softCreditPull
     * Java method: com.byzpass.demo.person.PersonController.addSoftCreditPull
     */
    addSoftCreditPull(id: string, iNewSoftCreditPullDTO: NewSoftCreditPullDTO, options?: O): RestResponse<SoftCreditPull> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/persons/${id}/softCreditPull`, data: iNewSoftCreditPullDTO, options: options });
    }

    /**
     * HTTP POST /api/v1/persons/{id}/usDriversLicense
     * Java method: com.byzpass.demo.person.PersonController.addDriversLicense
     */
    addDriversLicense(id: string, iUSDriversLicense: USDriversLicense, options?: O): RestResponse<USDriversLicense> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/persons/${id}/usDriversLicense`, data: iUSDriversLicense, options: options });
    }

    /**
     * HTTP GET /api/v1/s/{tinyId}
     * Java method: com.byzpass.demo.document.tiny.TinyDocumentController.redirect
     */
    redirect(tinyId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/s/${tinyId}`, options: options });
    }

    /**
     * HTTP GET /api/v1/search
     * Java method: com.byzpass.demo.search.SearchController.search
     */
    search$GET$api_v1_search(queryParams: { q: string; p?: number; d?: string; c?: string; ot?: string; }, options?: O): RestResponse<SearchResultsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/search`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/tasks
     * Java method: com.byzpass.demo.task.TaskController.tasksForLoanAndUser
     */
    tasksForLoanAndUser(queryParams: { loanId: string; userId?: string; }, options?: O): RestResponse<ITask[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/tasks`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/tasks/countTasks
     * Java method: com.byzpass.demo.task.TaskController.countTasksForLoanAndUser
     */
    countTasksForLoanAndUser(queryParams: { loanId: string; userId?: string; }, options?: O): RestResponse<{ [index: string]: number }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/tasks/countTasks`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/tasks/html
     * Java method: com.byzpass.demo.task.TaskController.tasksForLoanAndUserAsHtml
     */
    tasksForLoanAndUserAsHtml(queryParams: { loanId: string; userId?: string; }, options?: O): RestResponse<htmlDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/tasks/html`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /api/v1/transunion/reeschedule/{loanId}/{personId}
     * Java method: com.byzpass.demo.transunion.v2.TransunionController.reescheduleReport
     */
    reescheduleReport(loanId: string, personId: string, queryParams?: { forceSchedule?: boolean; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/transunion/reeschedule/${loanId}/${personId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/venture/documenttypes
     * Java method: com.byzpass.demo.venture.VentureController.getVentureDocTypeInSherpas
     */
    getVentureDocTypeInSherpas(options?: O): RestResponse<VenturesDocType[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/venture/documenttypes`, options: options });
    }

    /**
     * HTTP PUT /api/v1/venture/export/loan/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.exportLoanToVenture
     */
    exportLoanToVenture(loanId: string, options?: O): RestResponse<VentureEntityDTO[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/venture/export/loan/${loanId}`, options: options });
    }

    /**
     * HTTP PUT /api/v1/venture/import/loan/{loanId}/{loanIdVenture}
     * Java method: com.byzpass.demo.venture.VentureController.importLoanToVenture
     */
    importLoanToVenture(loanId: string, loanIdVenture: number, options?: O): RestResponse<VentureEntityDTO[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/venture/import/loan/${loanId}/${loanIdVenture}`, options: options });
    }

    /**
     * HTTP GET /api/v1/venture/import/loan/{loanId}/{loanIdVenture}
     * Java method: com.byzpass.demo.venture.VentureController.importLoanToVentureTest
     */
    importLoanToVentureTest(loanId: string, loanIdVenture: number, options?: O): RestResponse<VentureEntityDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/venture/import/loan/${loanId}/${loanIdVenture}`, options: options });
    }

    /**
     * HTTP GET /api/v1/venture/loantypes/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.getVenturesLoanTypes
     */
    getVenturesLoanTypes(loanId: string, options?: O): RestResponse<VentureLoanTypeDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/venture/loantypes/${loanId}`, options: options });
    }

    /**
     * @deprecated
     * HTTP GET /api/v1/view
     * Java method: com.byzpass.demo.view.ViewController.getView
     */
    getView(queryParams?: { wizard?: boolean; viewType?: ViewType; }, options?: O): RestResponse<View> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/view`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.clearMaintenance
     */
    clearMaintenance(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/admin/maintenance`, options: options });
    }

    /**
     * HTTP GET /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.getMaintenance
     */
    getMaintenance(options?: O): RestResponse<MaintenanceWindowDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/admin/maintenance`, options: options });
    }

    /**
     * HTTP POST /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.setMaintenance
     */
    setMaintenance(dto: MaintenanceWindowDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/admin/maintenance`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/v2/conversion/image
     * Java method: ai.mysherpas.app.conversion.ConversionController.convertImage
     */
    convertImage(requestDto: ConversionRequestDto, options?: O): RestResponse<DeferredResult<ResponseEntity<ConversionResponseDto>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/conversion/image`, data: requestDto, options: options });
    }

    /**
     * HTTP POST /api/v2/entities/bankAsset
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createBankAssets
     */
    createBankAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/entities/bankAsset`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/entities/businesses
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createBusinesses
     */
    createBusinesses(dto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/entities/businesses`, data: dto, options: options });
    }

    /**
     * HTTP POST /api/v2/entities/equipment
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createEquipmentAssets
     */
    createEquipmentAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/entities/equipment`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/entities/individualFromPerson
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createIndividualFromPerson
     */
    createIndividualFromPerson(iDto: IdDto, options?: O): RestResponse<IndividualResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/entities/individualFromPerson`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v2/entities/individualFromPerson/{id}
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.getIndividualFromPerson
     */
    getIndividualFromPerson(id: string, options?: O): RestResponse<IndividualResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/entities/individualFromPerson/${id}`, options: options });
    }

    /**
     * HTTP POST /api/v2/entities/individuals
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createIndividuals
     */
    createIndividuals(lDto: IndividualRequestDto, options?: O): RestResponse<IndividualResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/entities/individuals`, data: lDto, options: options });
    }

    /**
     * HTTP GET /api/v2/entities/personFromIndividual/{id}
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.getPersonFromIndividual
     */
    getPersonFromIndividual(id: string, options?: O): RestResponse<PersonResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/entities/personFromIndividual/${id}`, options: options });
    }

    /**
     * HTTP POST /api/v2/entities/randomAsset
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createOtherAssets
     */
    createOtherAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/entities/randomAsset`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/entities/realEstate
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createRealEstateAssets
     */
    createRealEstateAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/entities/realEstate`, data: iDto, options: options });
    }

    /**
     * HTTP PUT /api/v2/entities/{entityId}/rename
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.renameEntity
     */
    renameEntity(entityId: string, renameDto: RenameEntityDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/entities/${entityId}/rename`, data: renameDto, options: options });
    }

    /**
     * HTTP GET /api/v2/frontend/keys
     * Java method: com.byzpass.demo.util.frontend.FrontendKeys.getKeys
     */
    getKeys(options?: O): RestResponse<FeKeys> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/frontend/keys`, options: options });
    }

    /**
     * HTTP GET /api/v2/information/bankAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentBankInformation
     */
    getMostRecentBankInformation(entityId: string, options?: O): RestResponse<BankAssetResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/information/bankAsset/${entityId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/information/bankAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeBankInformation
     */
    postUpSomeBankInformation(entityId: string, information: BankAssetRequestDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/information/bankAsset/${entityId}`, data: information, options: options });
    }

    /**
     * HTTP GET /api/v2/information/business/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentBusinessEntityInformation
     */
    getMostRecentBusinessEntityInformation(entityId: string, options?: O): RestResponse<BusinessResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/information/business/${entityId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/information/business/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeBusinessEntityInformation
     */
    postUpSomeBusinessEntityInformation(entityId: string, information: BusinessRequestDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/information/business/${entityId}`, data: information, options: options });
    }

    /**
     * HTTP GET /api/v2/information/equipment/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentEquipmentInformation
     */
    getMostRecentEquipmentInformation(entityId: string, options?: O): RestResponse<EquipmentResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/information/equipment/${entityId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/information/equipment/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeEquipmentInformation
     */
    postUpSomeEquipmentInformation(entityId: string, information: EquipmentRequestDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/information/equipment/${entityId}`, data: information, options: options });
    }

    /**
     * HTTP GET /api/v2/information/individual/usdl/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getADriversLicense
     */
    getADriversLicense(entityId: string, options?: O): RestResponse<IndividualResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/information/individual/usdl/${entityId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/information/individual/usdl/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpADriversLicense
     */
    postUpADriversLicense(entityId: string, dto: DlRequest, options?: O): RestResponse<ResponseEntity<any>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/information/individual/usdl/${entityId}`, data: dto, options: options });
    }

    /**
     * HTTP GET /api/v2/information/individual/{entityId}/riskreportstatus/{loanId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getIndividualInfo
     */
    getIndividualInfo(entityId: string, loanId: string, options?: O): RestResponse<IndividualResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/information/individual/${entityId}/riskreportstatus/${loanId}`, options: options });
    }

    /**
     * HTTP GET /api/v2/information/randomAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentRandomAssetInformation
     */
    getMostRecentRandomAssetInformation(entityId: string, options?: O): RestResponse<RandomAssetResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/information/randomAsset/${entityId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/information/randomAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeRandomAssetInformation
     */
    postUpSomeRandomAssetInformation(entityId: string, information: RandomAssetRequestDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/information/randomAsset/${entityId}`, data: information, options: options });
    }

    /**
     * HTTP GET /api/v2/information/realEstate/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentRealEstateInformation
     */
    getMostRecentRealEstateInformation(entityId: string, options?: O): RestResponse<RealEstateResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/information/realEstate/${entityId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/information/realEstate/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeRealEstateInformation
     */
    postUpSomeRealEstateInformation(entityId: string, information: RealEstateRequestDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/information/realEstate/${entityId}`, data: information, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/applyNewTemplateToExistingEntity
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyNewTemplateToExistingEntity
     */
    applyNewTemplateToExistingEntity(iDto: NewEntityTemplateDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/applyNewTemplateToExistingEntity`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/applyNewTemplateToExistingLoan
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyNewTemplateToExistingLoan
     */
    applyNewTemplateToExistingLoan(iDto: NewLoanTemplateDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/applyNewTemplateToExistingLoan`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/applyTemplate
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyTemplate
     */
    applyTemplate(iDto: ApplyTemplateDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/applyTemplate`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/createEntity
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createEntity
     */
    createEntity(iDto: CreateEntityCompleteDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/createEntity`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/createFromTemplate
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createFromTemplate2
     */
    createFromTemplate2(iDto: NewLoanDTO, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/createFromTemplate`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createElements
     */
    createElements$POST$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<FormElementsV2ResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/elements`, data: iDto, options: options });
    }

    /**
     * HTTP DELETE /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteElements
     */
    deleteElements$DELETE$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/loanmanager/elements`, data: iDto, options: options });
    }

    /**
     * HTTP PUT /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.updateElements
     */
    updateElements$PUT$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<FormElementsV2ResponseDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/loanmanager/elements`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/elements/answer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.addAnswerToElement
     */
    addAnswerToElement$POST$api_v2_loanmanager_elements_answer(iDto: AnswerFormElementDto, options?: O): RestResponse<FormElementV2ResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/elements/answer`, data: iDto, options: options });
    }

    /**
     * HTTP DELETE /api/v2/loanmanager/elements/answer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteAnswerToElement
     */
    deleteAnswerToElement(iDto: AnswerFormElementDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/loanmanager/elements/answer`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/elements/copy
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.copyElements
     */
    copyElements(iDto: CopyFormElementsDto, options?: O): RestResponse<FormElementsV2ResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/elements/copy`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/elements/handleExpiring
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.handleExpiringAndExpiredElements
     */
    handleExpiringAndExpiredElements(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/elements/handleExpiring`, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/elements/{id}/resetAnswer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.resetTemplateAnswerOnElement
     */
    resetTemplateAnswerOnElement(id: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/elements/${id}/resetAnswer`, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/entityLabel
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.addEntityLabel
     */
    addEntityLabel(iDto: EntityLabelDto, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/entityLabel`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v2/loanmanager/linkForLoan/{packageId}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLinkForElementV2
     */
    getLinkForElementV2(packageId: string, options?: O): RestResponse<FormElementUrlResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/loanmanager/linkForLoan/${packageId}`, options: options });
    }

    /**
     * HTTP GET /api/v2/loanmanager/template/{id}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getAllWithTemplate
     */
    getAllWithTemplate(id: string, options?: O): RestResponse<LoanDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/loanmanager/template/${id}`, options: options });
    }

    /**
     * HTTP GET /api/v2/loanmanager/{id}/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLoanElements
     */
    getLoanElements(id: string, queryParams?: { view?: LoanViewType; }, options?: O): RestResponse<FormElementsV2ResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/loanmanager/${id}/elements`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteElementsZip
     */
    deleteElementsZip(id: string, queryParams: { jobid: string; }, options?: O): RestResponse<ZipFileJobResponseDto> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/loanmanager/${id}/elementsDownload`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.generateElementsZip
     */
    generateElementsZip(id: string, iDto: ZipFileJobRequestDto, options?: O): RestResponse<ZipFileJobResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/loanmanager/${id}/elementsDownload`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getElementsZip
     */
    getElementsZip(id: string, queryParams?: { jobid?: string; }, options?: O): RestResponse<ZipFileJobsResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/loanmanager/${id}/elementsDownload`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v2/loanmanager/{id}/folders
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLoanFolders
     */
    getLoanFolders(id: string, options?: O): RestResponse<FormElementsV2ResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/loanmanager/${id}/folders`, options: options });
    }

    /**
     * HTTP DELETE /api/v2/loanmanager/{loanId}/entity/{entityId}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.removeEntityFromLoan
     */
    removeEntityFromLoan(loanId: string, entityId: string, options?: O): RestResponse<LoanDto> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/loanmanager/${loanId}/entity/${entityId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/shoebox/item
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.createItem
     */
    createItem(iDto: ShoeboxItemRequestDto, options?: O): RestResponse<ShoeboxItemResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/shoebox/item`, data: iDto, options: options });
    }

    /**
     * HTTP DELETE /api/v2/shoebox/item
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.deleteItem
     */
    deleteItem(iDto: IdDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/shoebox/item`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v2/shoebox/loan
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.getItemsByLoan
     */
    getItemsByLoan(queryParams: { loan: string; }, options?: O): RestResponse<ShoeboxItemResponseDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/shoebox/loan`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v2/support/categories
     * Java method: ai.mysherpas.app.support.SupportController.getCategories
     */
    getCategories(options?: O): RestResponse<CategoryDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/support/categories`, options: options });
    }

    /**
     * HTTP POST /api/v2/support/category
     * Java method: ai.mysherpas.app.support.SupportController.createCategory
     */
    createCategory(iDto: CategoryDto, options?: O): RestResponse<CategoryDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/support/category`, data: iDto, options: options });
    }

    /**
     * HTTP PUT /api/v2/support/category
     * Java method: ai.mysherpas.app.support.SupportController.updateCategory
     */
    updateCategory(iDto: CategoryDto, options?: O): RestResponse<CategoryDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/support/category`, data: iDto, options: options });
    }

    /**
     * HTTP DELETE /api/v2/support/category/{id}
     * Java method: ai.mysherpas.app.support.SupportController.deleteCategory
     */
    deleteCategory(id: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/support/category/${id}`, options: options });
    }

    /**
     * HTTP GET /api/v2/support/entries
     * Java method: ai.mysherpas.app.support.SupportController.getEntries
     */
    getEntries(options?: O): RestResponse<SupportResponseDto[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/support/entries`, options: options });
    }

    /**
     * HTTP POST /api/v2/support/entry
     * Java method: ai.mysherpas.app.support.SupportController.createEntry
     */
    createEntry(iDto: SupportRequestDto, options?: O): RestResponse<SupportResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/support/entry`, data: iDto, options: options });
    }

    /**
     * HTTP PUT /api/v2/support/entry
     * Java method: ai.mysherpas.app.support.SupportController.updateEntry
     */
    updateEntry(iDto: SupportRequestDto, options?: O): RestResponse<SupportResponseDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/support/entry`, data: iDto, options: options });
    }

    /**
     * HTTP DELETE /api/v2/support/entry/{id}
     * Java method: ai.mysherpas.app.support.SupportController.deleteEntry
     */
    deleteEntry(id: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/support/entry/${id}`, options: options });
    }

    /**
     * HTTP PUT /api/v2/support/views/increment/{supportId}
     * Java method: ai.mysherpas.app.support.SupportController.incrementViews
     */
    incrementViews(supportId: string, options?: O): RestResponse<SupportResponseDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/support/views/increment/${supportId}`, options: options });
    }

    /**
     * HTTP GET /api/v2/tasks/consolidated/{loanId}
     * Java method: com.byzpass.demo.task.Task2Controller.consolidatedTasks
     */
    consolidatedTasks(loanId: string, options?: O): RestResponse<ConsolidatedTasksDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/tasks/consolidated/${loanId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/tasks/element/{id}
     * Java method: com.byzpass.demo.task.Task2Controller.tasksForV2Element
     */
    tasksForV2Element(id: string, iNewTaskDtos: NewTaskDto[], options?: O): RestResponse<NewTaskResponseDto2[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/tasks/element/${id}`, data: iNewTaskDtos, options: options });
    }

    /**
     * HTTP POST /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.create
     */
    create(iDto: TemplatesRequestDto, options?: O): RestResponse<TemplatesResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/templates`, data: iDto, options: options });
    }

    /**
     * HTTP DELETE /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.delete
     */
    delete(iDto: TemplatesRequestDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/templates`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.findAllByUser
     */
    findAllByUser(options?: O): RestResponse<TemplatesResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates`, options: options });
    }

    /**
     * HTTP PUT /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.update
     */
    update(iDto: TemplatesRequestDto, options?: O): RestResponse<TemplatesResponseDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/templates`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v2/templates/borrower
     * Java method: ai.mysherpas.app.templates.TemplatesController.findByEntityTypeAndUser
     */
    findByEntityTypeAndUser(queryParams: { entityType: SherpaEntityType; loanId: string; }, options?: O): RestResponse<TemplatesResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates/borrower`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/v2/templates/copy
     * Java method: ai.mysherpas.app.templates.TemplatesController.copyTemplate
     */
    copyTemplate(iDto: CopyTemplateDto, options?: O): RestResponse<TemplatesResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/templates/copy`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v2/templates/download/company/{id}
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForCompany
     */
    downloadCsvForCompany(id: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates/download/company/${id}`, options: options });
    }

    /**
     * HTTP GET /api/v2/templates/download/{id}
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForSelected
     */
    downloadCsvForSelected(id: string, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates/download/${id}`, options: options });
    }

    /**
     * HTTP GET /api/v2/templates/downloadBlank
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadBlank
     */
    downloadBlank(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates/downloadBlank`, options: options });
    }

    /**
     * HTTP GET /api/v2/templates/downloadCsv
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForUser
     */
    downloadCsvForUser(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates/downloadCsv`, options: options });
    }

    /**
     * HTTP GET /api/v2/templates/downloadMultiple
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadRequestedTemplates
     */
    downloadRequestedTemplates(queryParams: { id: string[]; }, options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates/downloadMultiple`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.createElements
     */
    createElements$POST$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<TemplateElementsResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/templates/elements`, data: iDto, options: options });
    }

    /**
     * HTTP DELETE /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.deleteElements
     */
    deleteElements$DELETE$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v2/templates/elements`, data: iDto, options: options });
    }

    /**
     * HTTP PUT /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.updateElements
     */
    updateElements$PUT$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<TemplateElementsResponseDto> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/templates/elements`, data: iDto, options: options });
    }

    /**
     * HTTP POST /api/v2/templates/elements/answer
     * Java method: ai.mysherpas.app.templates.TemplatesController.addAnswerToElement
     */
    addAnswerToElement$POST$api_v2_templates_elements_answer(iDto: AnswerTemplateElementDto, options?: O): RestResponse<TemplateElementResponseDto> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/templates/elements/answer`, data: iDto, options: options });
    }

    /**
     * HTTP GET /api/v2/templates/types
     * Java method: ai.mysherpas.app.templates.TemplatesController.getLocationTypes
     */
    getLocationTypes(options?: O): RestResponse<TemplateTypeMapsDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates/types`, options: options });
    }

    /**
     * HTTP POST /api/v2/templates/upload
     * Java method: ai.mysherpas.app.templates.TemplatesController.upload
     */
    upload(queryParams: { file: Blob; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/templates/upload`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v2/templates/{id}/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.getElements
     */
    getElements(id: string, options?: O): RestResponse<TemplateElementsResponseDto> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/templates/${id}/elements`, options: options });
    }

    /**
     * HTTP GET /api/v2/workflow/assignments/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.getAssignments
     */
    getAssignments(packageInfoId: string, options?: O): RestResponse<WorkflowPhaseAgentEntity[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/workflow/assignments/${packageInfoId}`, options: options });
    }

    /**
     * HTTP GET /api/v2/workflow/createSelfSign/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.generateEnvelopeForSelfSignGet
     */
    generateEnvelopeForSelfSignGet(packageInfoId: string, options?: O): RestResponse<DocusignViewDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/workflow/createSelfSign/${packageInfoId}`, options: options });
    }

    /**
     * HTTP PUT /api/v2/workflow/docusign/{packageInfoId}/{envelopeId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.checkEnvelopeStatus
     */
    checkEnvelopeStatus(packageInfoId: string, envelopeId: string, options?: O): RestResponse<WorkflowEntity> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/workflow/docusign/${packageInfoId}/${envelopeId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/workflow/form/payment/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.fillFormPayment
     */
    fillFormPayment(packageInfoId: string, iForm: PaymentFormDTO, options?: O): RestResponse<PaymentFormDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/workflow/form/payment/${packageInfoId}`, data: iForm, options: options });
    }

    /**
     * HTTP GET /api/v2/workflow/foruser
     * Java method: com.byzpass.demo.workflow.WorkflowController.getWorkflowForLoggedUser
     */
    getWorkflowForLoggedUser(options?: O): RestResponse<WorkflowEntity[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/workflow/foruser`, options: options });
    }

    /**
     * HTTP PUT /api/v2/workflow/phase/{phaseType}/{packageInfoId}/{phaseStatus}
     * Java method: com.byzpass.demo.workflow.WorkflowController.setPhaseStatus
     */
    setPhaseStatus(phaseType: WorkflowPhaseType, packageInfoId: string, phaseStatus: WorkflowPhaseStatus, options?: O): RestResponse<WorkflowEntity> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v2/workflow/phase/${phaseType}/${packageInfoId}/${phaseStatus}`, options: options });
    }

    /**
     * HTTP GET /api/v2/workflow/preview/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.getPreview
     */
    getPreview(packageInfoId: string, options?: O): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/workflow/preview/${packageInfoId}`, options: options });
    }

    /**
     * HTTP GET /api/v2/workflow/roles/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.getWorkflowRoles
     */
    getWorkflowRoles(packageInfoId: string, options?: O): RestResponse<WorkflowRolesDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v2/workflow/roles/${packageInfoId}`, options: options });
    }

    /**
     * HTTP POST /api/v2/workflow/{packageInfoId}
     * Java method: com.byzpass.demo.workflow.WorkflowController.addWorkflow
     */
    addWorkflow(packageInfoId: string, iWorkFlowDto: WorkflowCreateDTO, options?: O): RestResponse<WorkflowEntity> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v2/workflow/${packageInfoId}`, data: iWorkFlowDto, options: options });
    }

    /**
     * HTTP POST /beep/toxiproxy/disable
     * Java method: com.byzpass.demo.util.ToxiproxyController.disableToxics
     */
    disableToxics(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`beep/toxiproxy/disable`, options: options });
    }

    /**
     * HTTP POST /beep/toxiproxy/enable
     * Java method: com.byzpass.demo.util.ToxiproxyController.enableToxics
     */
    enableToxics(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`beep/toxiproxy/enable`, options: options });
    }

    /**
     * HTTP POST /test/500
     * Java method: com.byzpass.demo.common.TestController.a500
     */
    a500(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`test/500`, options: options });
    }
}

export interface AddEntityDto {
    createFromTemplateId: string;
    entityType: EntityType;
    knowledgeBase: KnowledgeBase;
    loanId: string;
    name: string;
}

export interface AddPersonToLoanDto {
    createFromTemplateId: string;
    loanId: string;
    personId: string;
}

export interface Address {
    citySuburb: string;
    country: string;
    postOfficeBox: string;
    postalCode: string;
    stateProvinceRegion: string;
    streetAddressLine1: string;
    streetAddressLine2: string;
}

export interface Answer extends BaseObject {
    active: boolean;
}

export interface AnswerFormElementDto {
    answerId: string;
    documentId: string;
    elementId: string;
}

export interface AnswerTemplateElementDto {
    answerId: string;
    documentId: string;
    elementId: string;
}

export interface AnswerV2Dto {
    active: boolean;
    document: DocumentAnswerV2Dto;
    id: string;
}

export interface AppUser extends BaseObject, IUser {
    admin: boolean;
    appleEmailConfirmed: boolean;
    appleSub: string;
    authorities: GrantedAuthority[];
    azureadEmailConfirmed: boolean;
    azureadSub: string;
    emailAddressConfirmed: boolean;
    googleEmailConfirmed: boolean;
    googleSub: string;
    initials: string;
    left: boolean;
    managerLender: boolean;
    mobilePhone: PhoneNumber;
    password: string;
    positionOrTitle: string;
    provider: AuthProviderType;
    providerId: string;
    stub: boolean;
    subRoleDefault: SubRole;
    userKey: string;
    yahooEmailConfirmed: boolean;
    yahooSub: string;
}

export interface AppUserChangePasswordRequestDto {
    newPassword: string;
    oldPassword: string;
    token: string;
}

export interface AppUserDTO2 {
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    emailAddress: string;
    familyName: string;
    givenName: string;
    id: string;
    maritalStatus: string;
    mobilePhone: PhoneNumber;
    positionOrTitle: string;
    roleDefault: Role;
    subRoleDefault: SubRole;
    username: string;
}

/**
 * @deprecated for removal
 */
export interface AppUserDto extends BaseObjectDto<AppUser> {
    emailAddress?: string;
    familyName?: string;
    givenName?: string;
    mobilePhone?: PhoneNumber;
    roleDefault?: Role;
    subRoleDefault?: SubRole;
    username?: string;
}

export interface AppUserForgotPasswordRequestDto {
    confirmationCode: string;
    password: string;
    provider: string;
    url: string;
    username: string;
}

export interface AppUserPreferencesDTO {
    agreedToTOS: boolean;
    camScanMode: string;
    dadZipFileHandling: string;
    fileElementUploader: string;
    formElementNavigator: string;
    formElementTreeMapDepth: number;
    formElementTreeMapHideFiles: boolean;
    formElementsSortingEnabled: boolean;
    hiddenLoanStatuses: string;
    previewMode: boolean;
    sendEmailAddRemoveUser: boolean;
    sendEmailAppMessage: boolean;
    sendEmailLoanStatus: boolean;
    sendSmsNotificationOnMessageReceived: boolean;
    sendTextAddRemoveUser: boolean;
    sendTextAppMessage: boolean;
    sendTextLoanStatus: boolean;
    simpleUiEnabled: boolean;
    timezoneId: string;
    useAlternativeFilePreviewer: boolean;
}

export interface AppUserResponseDto extends Id {
    emailAddress: string;
    familyName: string;
    givenName: string;
    middleName: string;
    pendingInvite: boolean;
    roleDefault: Role;
    roleInvited: Role;
    subRoleDefault: SubRole;
}

export interface AppUserSignupRequestDto {
    confirmationCode: string;
    email: string;
    familyName: string;
    givenName: string;
    invitationId: string;
    mobilePhone: PhoneNumber;
    password: string;
    personId: string;
    positionOrTitle: string;
    roleDefault: Role;
    subRoleDefault: SubRole;
    url: string;
    username: string;
}

export interface ApplyTemplateDto {
    entityId: string;
    entityLabel: LoanEntityLabel;
    loanId: string;
    templateId: string;
}

export interface AssetEntityCustomizationsDto extends Serializable {
    bookValueDiscount?: number;
    deposit?: number;
    internalReviewValueForMla?: number;
    lenderPolicyLtv?: number;
    ltc: number;
    ltv: number;
}

export interface AssetResponseInformation extends InformationResponseDto {
    deposit?: number;
    mostImportantCost?: number;
    mostImportantValue?: number;
}

export interface AutoCloseable {
}

export interface BankAssetRequestDto extends OwnerInformationRequestDto {
    accountNumber: string;
    bankOrBrokerageAddress: Address;
    bankOrBrokerageName: string;
    bankOrBrokeragePhone: string;
    bankRoutingNumber: string;
    bankerOrBrokerEmail: string;
    bankerOrBrokerName: string;
    bankerOrBrokerPhone: string;
    bondDateIssued: DateAsString;
    bondDateRedeemed: DateAsString;
    bondSerialNumber: string;
    bondSeries: string;
    bondValueSource: string;
    cusip: string;
    stockTickerSymbol: string;
    value: number;
}

export interface BankAssetResponseDto extends BankAssetRequestDto, InformationResponseDto, AssetResponseInformation {
}

export interface BaseObject extends Id {
    createdBy: string;
    createdDate: DateAsString;
    lastModifiedBy: string;
    lastModifiedDate: DateAsString;
}

/**
 * @deprecated
 */
export interface BaseObjectDto<T> {
    id?: string;
}

export interface BaseResultDTO {
    EndIndex: number;
    GroupCount: number;
    StartIndex: number;
    Status: ClearReportApiStatus;
    Uri: string;
    groupId: string;
    resultCount: number;
    templateNameError: string;
    templateNameSucess: string;
}

export interface BaseStoredTaskDto extends BaseTask {
    formElement: string;
    formElement2: string;
    targetId: string;
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel: boolean;
}

export interface BaseTask extends ITask {
}

export interface BorrowUserSetDto {
    appUser: string;
    doesNeedOnboarding: boolean;
    role: Role;
}

export interface BusinessDatasourcesDTO {
    NPIRecord: boolean;
    PublicRecordBusiness: boolean;
    PublicRecordUCCFilings: boolean;
    WorldCheckRiskIntelligence: boolean;
}

export interface BusinessQuickAnalysisFlagCriteria {
    CompanyRiskFlags: any;
    EntityId: string;
    GroupId: string;
    xmlns: string;
}

export interface BusinessQuickAnalysisFlagRequestDTO {
    Criteria: CriteriaDTO;
    PermissiblePurpose: PermissiblePurpose;
    xmlns: string;
}

export interface BusinessQuickAnalysisFlagResponseDetail {
    CompanyRiskFlagsWithDocguids: any;
}

export interface BusinessQuickAnalysisFlagResultDTO extends BaseResultDTO {
    ResultGroup: BusinessQuickAnalysisFlagResultGroup;
}

export interface BusinessQuickAnalysisFlagResultGroup {
    GroupId: string;
    RecordCount: number;
    RecordDetails: BusinessQuickSearchFlagRecordDetail[];
    mainRecordDetails: BusinessQuickSearchFlagRecordDetail;
}

export interface BusinessQuickSearchFlagRecordDetail {
    CompanyQuickAnalysisFlagResponseDetail: BusinessQuickAnalysisFlagResponseDetail;
    bankruptcy: string;
    businessUsedAsResidentialAddress: string;
    globalSanctions: string;
    goingConcern: string;
    healthcareSanction: string;
    keyNatureOfSuit: string;
    listingLinkedToBusinessAddress: string;
    listingLinkedToBusinessPhone: string;
    listingLinkedToSameFEIN: string;
    marijuanaRelatedBusiness: string;
    msblisting: string;
    ofac: string;
    pendingClassAction: string;
    poBoxAddress: string;
    prisonAddress: string;
    worldCheck: string;
}

export interface BusinessRecordDetail {
    BusinessResponseDetail: any;
}

export interface BusinessRequestDto extends OwnerInformationRequestDto {
    annualSales: number;
    companyAddress: Address;
    dbaName: string;
    duns: string;
    ein: string;
    emailAddress: string;
    entityName: string;
    establishedDate: DateAsString;
    mailingAddress: Address;
    mailingSameAsCompanyAddress: boolean;
    naics: string;
    numberOfEmployees: number;
    phoneNumber: PhoneNumber;
    website: string;
}

export interface BusinessResponseDto extends BusinessRequestDto, InformationResponseDto {
    clearReport: ClearReportDTO;
}

export interface BusinessResultGroup {
    DominantValues: any[];
    GroupId: string;
    RecordCount: number;
    RecordDetails: BusinessRecordDetail[];
    Relevance: number;
}

export interface BusinessSearchRequestDTO {
    Criteria: CriteriaDTO;
    Datasources: BusinessDatasourcesDTO;
    PermissiblePurpose: PermissiblePurpose;
    Reference: string;
    xmlns: string;
}

export interface BusinessSearchResultsDTO extends BaseResultDTO {
    ResultGroup: BusinessResultGroup;
}

export interface Card<BODYTYPE> {
    active: boolean;
    body: BODYTYPE;
    cardType: CardType;
    cards: Card<any>[];
    expanded: boolean;
    percentageDone: number;
    status: ConsentStatus;
    subtitle: string;
    title: string;
}

export interface CategoryDto {
    icon: string;
    id: string;
    name: string;
    totalEntries: number;
}

export interface ClearReportApiStatus {
    Message: string;
    Reference: string;
    StatusCode: number;
    SubStatusCode: number;
}

export interface ClearReportDTO {
    error: string;
    status: ClearReportStatus;
    type: ClearReportType;
}

export interface Company extends BaseObject {
    email: string;
    hasBorrowerOnboard: boolean;
    mailingAddress: Address;
    name: string;
    phoneNumber: PhoneNumber;
    preferences: CompanyPreferences;
    subdomain: string;
    website: string;
}

export interface CompanyDto extends Serializable {
    createdBy: string;
    createdDate: DateAsString;
    email: string;
    hasBorrowerOnboard: boolean;
    id: string;
    lastModifiedBy: string;
    lastModifiedDate: DateAsString;
    name: string;
    phoneNumber: PhoneNumber;
    preferences: CompanyPolicyDto;
    subdomain: string;
    website: string;
}

export interface CompanyEmployeeListDto {
    employees: AppUserDTO2[];
}

export interface CompanyListDto {
    city: string;
    country: string;
    email: string;
    hasBorrowerOnboard: boolean;
    id: string;
    name: string;
    phoneNumber: PhoneNumber;
    postalCode: string;
    state: string;
    streetAddress1: string;
    streetAddress2: string;
    subdomain: string;
    website: string;
}

export interface CompanyPolicyDto extends Serializable {
    lenderPolicyLtv: string;
}

export interface CompanyPreferences extends BaseObject {
    lenderPolicyLtv: number;
}

export interface ConsolidatedTasksDto {
    perUser: { [index: string]: UserTaskDetailsDto };
}

export interface ConversionRequestDto {
    documentId: string;
}

export interface ConversionResponseDto {
    newDocumentId: string;
}

export interface CopyFormElementsDto {
    loanId: string;
    newParentId: string;
    sourceIds: string[];
}

export interface CopyTemplateDto {
    newCompanyId: string;
    newName: string;
    templateId: string;
}

export interface CreateEntityCompleteDto {
    bankAssetInfo: BankAssetRequestDto;
    entityCreateDto: EntityCreateDto;
    entityLabelDto: EntityLabelDto;
    equipmentInfo: EquipmentRequestDto;
    randomAssetInfo: RandomAssetRequestDto;
    realEstateInfo: RealEstateRequestDto;
    renameDto: RenameEntityDto;
    templateDto: ApplyTemplateDto;
}

export interface CreateUpdateAppUserDto {
    emailAddress: string;
    familyName: string;
    givenName: string;
    id: string;
    mobilePhone: PhoneNumber;
    roleDefault: Role;
    subRoleDefault: SubRole;
}

export interface CriteriaDTO {
    "b1:BusinessCriteria": any;
    "cq1:CompanyQuickAnalysisFlagCriteria": BusinessQuickAnalysisFlagCriteria;
    "p1:PersonCriteria": any;
    "pq1:PersonQuickAnalysisFlagCriteria": PersonQuickAnalysisFlagCriteria;
}

export interface DatasourcesDTO {
    NPIRecord: boolean;
    PublicRecordPeople: boolean;
    WorldCheckRiskIntelligence: boolean;
}

export interface DeferredResult<T> {
    errorResult: any;
    result: any;
    resultHandler: DeferredResultHandler;
    resultInternal: any;
    setOrExpired: boolean;
}

export interface DeferredResultHandler {
}

export interface Destination extends BaseObject {
    locationType: LocationType;
    replaceFileName: NamingStrategyType;
    replaceFolderName: NamingStrategyType;
}

export interface DestinationDto {
    locationType: LocationType;
    replaceFileName: NamingStrategyType;
    replaceFolderName: NamingStrategyType;
}

export interface DlRequest {
    dl: any;
    dlBackDocumentId: string;
    dlFrontDocumentId: string;
    dto: IndividualRequestDto;
}

export interface Document extends BaseObject {
    documentType: DocumentType;
    /**
     * @deprecated
     */
    downloadUrl: URL;
    downloadUrlSaved: string;
    lender?: Company;
    name: string;
    providerType: ProviderType;
}

export interface DocumentAnswerV2Dto {
    createdByUser: AppUserDTO2;
    createdDate: string;
    documentType: DocumentType;
    downloadUrlSaved: string;
    id: string;
    lastModifiedDate: string;
    name: string;
    providerType: ProviderType;
}

export interface DocusignViewDTO {
    recipientStatus: string;
    status: string;
    url: string;
}

export interface DriversLicenseImages {
    back: URL;
    front: URL;
}

export interface EntityCreateDto {
    name: string;
    type?: SherpaEntityType;
}

export interface EntityLabelDto {
    customizations?: AssetEntityCustomizationsDto;
    label: LoanEntityLabel[];
    loanId: string;
    sherpaEntityId: string;
    userLabels: string[];
}

export interface EntityResponseDto {
    id: string;
    name: string;
    type: SherpaEntityType;
}

export interface EquipmentRequestDto extends OwnerInformationRequestDto {
    appraisedValue: number;
    bookValue: number;
    bookValueDiscount: number;
    bookValueSource: string;
    cashDownPayment: number;
    condition: string;
    hours: number;
    make: string;
    makeYear: string;
    mileage: number;
    model: string;
    purchasePrice: number;
    repairCost: number;
    serialNumber: string;
    totalCost: number;
    tradeInValue: number;
    ucc1FilingNumber: string;
    vehicleIdentificationNumber: string;
}

export interface EquipmentResponseDto extends EquipmentRequestDto, InformationResponseDto, AssetResponseInformation {
}

export interface FeKeys {
    googleMapsKey: string;
    pdftronKey: string;
    syncfusionKey: string;
}

export interface FePreferenceEntity extends BaseObject {
    key: string;
    user: AppUser;
    value: string;
}

export interface FileAnswerDTO {
    documentId: string;
    id: string;
}

export interface FileUrlDTO {
    url: string;
}

export interface FormElementExpiration {
    canHaveExpiration: boolean;
    expireDate: DateAsString;
    reAskedRef: FormElementV2;
}

export interface FormElementExpirationDto {
    canHaveExpiration: boolean;
    expireDate: DateAsString;
    reAskedRefId: string;
}

export interface FormElementPackageInfo extends BaseObject {
    approved: boolean;
    approvedDate: DateAsString;
    children: FormElementPackageInfo[];
    destination: Destination;
    displayOrder: number;
    element: FormElementV2;
    hiddenForUsers: AppUser[];
    locations: Location[];
    originalTitle: string;
    packageType: PackageType;
    parentInfo: FormElementPackageInfo;
    percentageApproved: number;
    priorityType: PriorityType;
    rejected: boolean;
    tinyDocument: TinyDocument[];
    title: string;
}

export interface FormElementPackageInfoResponseDto {
    approved: boolean;
    approvedByUser: AppUserDTO2;
    childrenIds: string[];
    displayOrder: number;
    elementId: string;
    hasWorkflow: boolean;
    hidden: boolean;
    id: string;
    locations: LocationDto[];
    parentInfoId: string;
    priorityType: PriorityType;
    rejected: boolean;
    rejectedByUser: AppUserDTO2;
    title: string;
}

export interface FormElementUrlResponseDto {
    url: string;
}

export interface FormElementV2 extends BaseObject {
    answer: Answer;
    answersHistory: Answer[];
    assigned: boolean;
    expiration: FormElementExpiration;
    generated: boolean;
    hasAnswer: boolean;
    hasExpiration: boolean;
    knowledgeBase: KnowledgeBase;
    modifiers: ElementModifierType[];
    originalTitle: string;
    percentageAnswered: number;
    processed: FormElementProcessStatus;
    sherpaEntity: SherpaEntity;
    sherpaEntityType: SherpaEntityType;
    signedDate: DateAsString;
    state: FormElementState;
    storageType: StorageType;
    templateElement: TemplateElement;
    venturesDocType: VenturesDocType;
}

export interface FormElementV2RequestDto {
    answerId: string;
    approved: boolean;
    approvedByUserId: string;
    assigned: boolean;
    assignedToUserId: string;
    canHaveExpiration: boolean;
    displayOrder: number;
    expireDate: DateAsString;
    hasExpiration: boolean;
    hidden: boolean;
    id: string;
    knowledgeBase: KnowledgeBase;
    loanId: string;
    parentId: string;
    priorityType: PriorityType;
    rejected: boolean;
    rejectedByUserId: string;
    sherpaEntityId: string;
    sherpaEntityType: SherpaEntityType;
    storageType: StorageType;
    title: string;
    venturesDocType: VenturesDocType;
}

export interface FormElementV2ResponseDto {
    answer: AnswerV2Dto;
    approved: boolean;
    approvedByUser: AppUserDTO2;
    assigned: boolean;
    assignedToUser: AppUserDTO2;
    canHaveExpiration: boolean;
    childrenIds: string[];
    createdBy: string;
    createdDate: DateAsString;
    displayOrder: number;
    expiration: FormElementExpirationDto;
    expireDate: DateAsString;
    expired: boolean;
    hasExpiration: boolean;
    hasTemplateAnswer: boolean;
    hidden: boolean;
    id: string;
    isExpiring: boolean;
    knowledgeBase: KnowledgeBase;
    lastModifiedBy: string;
    lastModifiedDate: DateAsString;
    modifiers: ElementModifierType[];
    originalTitle: string;
    parentId: string;
    percentageAnswered: number;
    percentageApproved: number;
    priorityType: PriorityType;
    rejected: boolean;
    rejectedByUser: AppUserDTO2;
    sherpaEntityId: string;
    sherpaEntityType: SherpaEntityType;
    state: FormElementState;
    storageType: StorageType;
    templateElementId: string;
    title: string;
    venturesDocType: VenturesDocType;
}

export interface FormElementsRequestDto {
    elements: FormElementV2RequestDto[];
    multiSelect: boolean;
}

export interface FormElementsV2ResponseDto {
    elements: { [index: string]: FormElementV2ResponseDto };
    loan: LoanDto;
    packageInfo: { [index: string]: FormElementPackageInfoResponseDto };
}

export interface GeneratedPackage {
    document?: Document;
    errorMessage?: string;
    finished?: DateAsString;
    started?: DateAsString;
    state: GenerationStatus;
}

export interface GeneratedPackageDto extends Serializable {
    document: Document;
    errorMessage: string;
    finished: DateAsString;
    started: DateAsString;
    state: GenerationStatus;
}

export interface GrantedAuthority extends Serializable {
    authority: string;
}

export interface HttpEntity<T> {
    body: T;
    headers: { [index: string]: string[] };
}

export interface IRS4506C extends BaseObject {
    consentStatus: ConsentStatus;
    signature: string;
}

export interface ITask {
    action: string;
    contextId: string;
    deepLink?: string;
    fullTitle: string;
    hidden: boolean;
    id: string;
    subjectName: string;
    taskType: string;
    title: string;
}

export interface IUser extends Id {
    emailAddress: string;
    employer: Company;
    familyName: string;
    features: Features[];
    givenName: string;
    name: string;
    person?: Person;
    roleDefault: Role;
    username: string;
}

export interface Id {
    id: string;
}

export interface IdDto {
    id: string;
}

export interface IndividualRequestDto {
    complianceType: string;
    countryIdentification: string;
    dd: string;
    dob: DateAsString;
    emailAddress: string;
    endorsementCodes: string;
    expiration: DateAsString;
    eyesColor: string;
    familyName: string;
    givenName: string;
    hairColor: string;
    height: string;
    inventoryControlNumber: string;
    issueDate: DateAsString;
    legalFamilyName: string;
    legalGivenName: string;
    legalMiddleName: string;
    mailingAddress: Address;
    number: string;
    organDonorIndicator: string;
    residentialAddress: Address;
    restrictions: string;
    sex: string;
    ssn: string;
    state: UnitedStates;
    vehicleClass: string;
    weight: string;
}

export interface IndividualResponseDto extends IndividualRequestDto, InformationResponseDto {
    clearReport: ClearReportDTO;
}

export interface InformationResponseDto {
    dlBack?: Document;
    dlFront?: Document;
    id: string;
    simpleOwners?: string;
    userWhoSubmittedInformation: AppUserDTO2;
}

export interface InviteToLoanDTO {
    createFromTemplateId: string;
    doesNeedOnboarding: boolean;
    loanId: string;
    role: Role;
    subRole: SubRole;
    userId: string;
}

export interface Iterable<T> {
}

export interface LabelDeleteDto {
    toDelete: string[];
}

export interface LabelsListDto {
    labels: SimpleLabelDto[];
}

export interface LeadUserSetDto {
    appUser: string;
    canAcceptFiles: boolean;
    leadBorrower: boolean;
    leadLender: boolean;
    newToLoan: boolean;
    role: Role;
    visibleToBorrower: boolean;
}

export interface LinkAction extends BaseObject {
    accepted: boolean;
    actionType: LinkActionType;
    authRequest: boolean;
    expires: DateAsString;
    signupCandidate: boolean;
}

export interface LinkActionInviteToLoan extends LinkAction {
    email: string;
    invitedBy: AppUser;
    loan: Loan;
    role: Role;
    subRole: SubRole;
    userInvited: AppUser;
}

export interface Loan extends BaseObject {
    actualLoanAmortization: number;
    actualLoanAmount: number;
    actualLoanTerm: number;
    amortizationInMonths: number;
    /**
     * @deprecated for removal
     */
    applicationLead?: AppUser;
    apr: number;
    /**
     * @deprecated
     */
    borrowerTeamUsers: AppUser[];
    /**
     * @deprecated for removal
     */
    borrowers: Person[];
    closeDate: DateAsString;
    /**
     * @deprecated
     */
    contactTeamUsers: AppUser[];
    /**
     * @deprecated for removal
     */
    contacts: Person[];
    downPayment: number;
    downPaymentPercentage: number;
    downPaymentSource: string;
    entityName: string;
    entityType: EntityType;
    index: number;
    lender: Company;
    /**
     * @deprecated
     */
    lenderTeamUsers: AppUser[];
    loanAmount: number;
    loanOriginationFees: number;
    loanOriginationPoints: number;
    loanOtherFees: number;
    loanPhase: LoanPhase;
    loanPurpose: string;
    loanStatus: LoanStatus;
    /**
     * @deprecated
     */
    loanTeamUsers: AppUser[];
    loanTermInMonths: number;
    loanType: LoanType;
    locked: boolean;
    margin: number;
    pdf: GeneratedPackage;
    percentageAnswered: number;
    percentageApproved: number;
    primaryLenderUser?: AppUser;
    projectName: string;
    reviewStatus: LoanReviewStatus;
    shortCode: string;
    template: Template;
    templateProjectName: string;
    unsecured: boolean;
    zip: GeneratedPackage;
}

export interface LoanDto extends Serializable {
    actualLoanAmortization?: number;
    actualLoanAmount?: number;
    actualLoanTerm?: number;
    amortizationInMonths?: number;
    /**
     * @deprecated
     */
    applicationLead: AppUserDTO2;
    apr: number;
    /**
     * @deprecated
     */
    borrowerTeamUsers: AppUserDTO2[];
    borrowers: PersonResponseDto[];
    closeDate: DateAsString;
    contacts: PersonResponseDto[];
    createdBy: string;
    createdDate: DateAsString;
    downPayment: number;
    downPaymentPercentage?: number;
    downPaymentSource: string;
    entityName: string;
    entityType: EntityType;
    id: string;
    index?: number;
    lastModifiedBy: string;
    lastModifiedDate: DateAsString;
    lender: CompanyDto;
    /**
     * @deprecated
     */
    lenderTeamUsers: AppUserDTO2[];
    loanAmount: number;
    loanEntities: LoanEntityDto[];
    loanOriginationFees?: number;
    loanOriginationPoints?: number;
    loanOtherFees?: number;
    loanPhase: LoanPhaseDto;
    loanPurpose: string;
    loanRoles: LoanRoleDto[];
    loanStatus: LoanStatus;
    loanTermInMonths: number;
    locked: boolean;
    margin?: number;
    pdf: GeneratedPackageDto;
    pendingInviteUsers: AppUserResponseDto[];
    percentageAnswered: number;
    percentageApproved: number;
    pinned: boolean;
    /**
     * @deprecated
     */
    primaryLenderUser?: AppUserDTO2;
    projectName: string;
    reviewStatus: LoanReviewStatus;
    shortCode: string;
    template: TemplateResponseDto;
    templateId: string;
    templateProjectName: string;
    unsecured: boolean;
    userProgress: { [index: string]: UserProgress };
    ventureExportStatus?: VentureExportStatus;
    ventureLastExportTime?: string;
    zip: GeneratedPackageDto;
}

export interface LoanEntityDto {
    assetEntityCustomizations?: AssetEntityCustomizationsDto;
    id: string;
    label: LoanEntityLabel[];
    loan: string;
    mostRecentDriversLicenseInformation?: InformationResponseDto;
    mostRecentUserInformation?: InformationResponseDto;
    sherpaEntity: EntityResponseDto;
    templateId: string;
    templateName: string;
    userLabels: SimpleLabelDto[];
}

export interface LoanListResponseDto {
    loans: LoanDto[];
    roles: LoanRoleDto[];
}

export interface LoanPhase extends BaseObject {
    category: LoanPhaseCategoryType;
    displayOrder: number;
    lender: Company;
    name: string;
}

export interface LoanPhaseDto {
    category: LoanPhaseCategoryType;
    displayOrder: number;
    id: string;
    lenderId: string;
    name: string;
}

export interface LoanRoleDto {
    canAcceptFiles: boolean;
    id: string;
    labels: SimpleLabelDto[];
    loan: string;
    role: Role;
    stub: boolean;
    user: AppUserDTO2;
    visibleToBorrower: boolean;
}

export interface LoanStatusDTO {
    loanStatus: LoanStatus;
}

export interface Location extends BaseObject {
    locationType: LocationType;
}

export interface LocationDto {
    locationType: LocationType;
}

export interface MaintenanceWindowDto {
    end: DateAsString;
    notes?: string;
    start: DateAsString;
}

export interface Message extends BaseObject {
    attachmentCount: number;
    body: string;
    from: BaseObjectDto<AppUser>;
    /**
     * @deprecated
     */
    fromUserId: string;
    /**
     * @deprecated
     */
    fromUsername: string;
    left: boolean;
    messageType: MessageType;
    originalMessageContentUrl: string;
    preview: string;
    processedTime: DateAsString;
    readAt: DateAsString;
    subject: string;
    to: BaseObjectDto<AppUser>;
    /**
     * @deprecated
     */
    toUserId: string;
    /**
     * @deprecated
     */
    toUsername: string;
    visibleOnlyToMyTeam: boolean;
}

export interface MessageFullContentUrl {
    url: string;
}

export interface MessageOriginationStuff {
    htmlContent: string;
    originalSubject: string;
    plainContent: string;
}

export interface MessageSendDto {
    body: string;
    contextId: string;
    messageType: MessageType;
    subject: string;
    toUserIds: string[];
    toUserNames: string[];
}

export interface NewEntityTemplateDto {
    entityId: string;
    loanId: string;
    templateId: string;
}

export interface NewLoanDTO extends UpdateLoanDTO {
    createBlank: boolean;
    lenderId: string;
    templateId: string;
}

export interface NewLoanTemplateDto {
    loanId: string;
    templateId: string;
}

export interface NewSoftCreditPullDTO {
    consent: string;
    loanId: string;
    signature: string;
}

export interface NewTaskDto {
    fieldId: string;
    targetId: string;
    type: TaskType;
}

export interface NewTaskResponseDto2 {
    fieldId: string;
    taskId: string;
}

export interface OnboardCompany {
    city: string;
    country: string;
    email: string;
    hasBorrowerOnboard: boolean;
    name: string;
    phoneNumber: PhoneNumber;
    postalCode: string;
    state: string;
    streetAddress1: string;
    streetAddress2: string;
    subdomain: string;
    website: string;
}

export interface OnboardUserDto {
    company: string;
    emailAddress: string;
    familyName: string;
    givenName: string;
    mobilePhone: PhoneNumber;
    password: string;
    positionOrTitle: string;
    roleDefault: Role;
    subRoleDefault: SubRole;
}

export interface Order extends Serializable {
    ascending: boolean;
    descending: boolean;
    direction: Direction;
    ignoreCase: boolean;
    nullHandling: NullHandling;
    property: string;
}

export interface OwnerInformationRequestDto {
    simpleOwners?: string;
}

export interface PackageUrlResponse {
    pdfPackage: URL;
    zipPackage: URL;
}

export interface Page<T> extends Slice<T> {
    totalElements: number;
    totalPages: number;
}

export interface Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: Sort;
    unpaged: boolean;
}

export interface PaymentFormDTO {
    borrowerFullName: string;
}

export interface PdfOutlinesDTO {
    children: PdfOutlinesDTO[];
    page: number;
    title: string;
}

export interface PerUserConstants {
    entityTypeMap: { [index: string]: any };
    knowledgeBaseMap: { [index: string]: any };
    loanStatusMap: { [index: string]: any };
    loanTypeMap: { [index: string]: any };
    phaseCategoryMap: { [index: string]: any };
    priorityMap: { [index: string]: any };
    statesMap: { [index: string]: any };
    usersExcludedFromCounts: string[];
}

export interface PermissiblePurpose {
    DPPA: string;
    GLB: string;
    VOTER: string;
}

export interface Person extends BaseObject {
    birthDate: DateAsString;
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    /**
     * @deprecated
     */
    doesNeedOnboarding: boolean;
    emailAddress: string;
    familyName: string;
    givenName: string;
    homeAddress: Address;
    inviteAccepted: boolean;
    irs4506C: IRS4506C;
    /**
     * @deprecated for removal
     */
    loans: Loan[];
    maritalStatus: string;
    middleName: string;
    mobilePhone: PhoneNumber;
    name: string;
    /**
     * @deprecated for removal
     */
    onboardNeeded: boolean;
    positionOrTitle: string;
    socialSecurityNumber: string;
    softCreditPull: SoftCreditPull;
    usDriversLicense: USDriversLicense;
}

export interface PersonCreateDto {
    birthDate: DateAsString;
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    /**
     * @deprecated
     */
    doesNeedOnboarding: boolean;
    emailAddress: string;
    familyName: string;
    givenName: string;
    homeAddress: Address;
    maritalStatus: string;
    middleName: string;
    mobilePhone: PhoneNumber;
    positionOrTitle: string;
    socialSecurityNumber: string;
}

export interface PersonQuickAnalysisFlagCriteria {
    EntityId: string;
    GroupId: string;
    PersonRiskFlags: any;
    Reference: string;
    xmlns: string;
}

export interface PersonQuickAnalysisFlagRequestDTO {
    Criteria: CriteriaDTO;
    PermissiblePurpose: PermissiblePurpose;
    xmlns: string;
}

export interface PersonQuickAnalysisFlagResponseDetail {
    PersonRiskFlags: PersonQuickAnalysisRiskFlags;
    PersonRiskFlagsWithDocguids: any;
}

export interface PersonQuickAnalysisFlagResultDTO extends BaseResultDTO {
    ResultGroup: PersonQuickAnalysisFlagResultGroup;
}

export interface PersonQuickAnalysisFlagResultGroup {
    GroupId: string;
    RecordCount: number;
    RecordDetails: PersonQuickSearchFlagRecordDetail[];
    mainRecordDetails: PersonQuickSearchFlagRecordDetail;
}

export interface PersonQuickAnalysisRiskFlags {
    AddressReportedLessNinetyDays: string;
    AgeYoungerThanSSN: string;
    Arrest: string;
    AssocRelativeWithPOBoxAddress: string;
    AssocRelativeWithPrisonAddress: string;
    AssocRelativeWithResidentialUsedAsBusiness: string;
    AssocWithOFACGlobalPEP: string;
    AssociatedWithMarijuanaBusiness: string;
    Bankruptcy: string;
    Criminal: string;
    CriminalLowLevelTrafficOffense: string;
    CriminalUncategorized: string;
    GlobalSanctions: string;
    HealthcareSanction: string;
    MultipleSSN: string;
    OFAC: string;
    POBoxAddress: string;
    PhoneNumberInconsistentAddress: string;
    PrisonAddress: string;
    RealTimeIncarcerationAndArrest: string;
    RecordedAsDeceased: string;
    ResidentialUsedAsBusiness: string;
    SSNFormatInvalid: string;
    SSNIsITIN: string;
    SSNMultipleIndividuals: string;
    SexOffender: string;
    WorldCheck: string;
}

export interface PersonQuickSearchFlagRecordDetail {
    PersonQuickAnalysisFlagResponseDetail: PersonQuickAnalysisFlagResponseDetail;
    addressReportedLessNinetyDays: string;
    ageYoungerThanSSN: string;
    arrest: string;
    assocRelativeWithPOBoxAddress: string;
    assocRelativeWithPrisonAddress: string;
    assocRelativeWithResidentialUsedAsBusiness: string;
    assocWithOFACGlobalPEP: string;
    associatedWithMarijuanaBusiness: string;
    bankruptcy: string;
    criminal: string;
    criminalLowLevelTrafficOffense: string;
    criminalUncategorized: string;
    globalSanctions: string;
    healthcareSanction: string;
    multipleSSN: string;
    ofac: string;
    phoneNumberInconsistentAddress: string;
    poBoxAddress: string;
    prisonAddress: string;
    realTimeIncarcerationAndArrest: string;
    recordedAsDeceased: string;
    residentialUsedAsBusiness: string;
    sexOffender: string;
    ssnFormatInvalid: string;
    ssnIsITIN: string;
    ssnMultipleIndividuals: string;
    worldCheck: string;
}

export interface PersonRecordDetail {
    PersonResponseDetail: any;
}

export interface PersonResponseDto extends Id {
    birthDate: DateAsString;
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    doesNeedOnboarding: boolean;
    emailAddress: string;
    familyName: string;
    givenName: string;
    homeAddress: Address;
    maritalStatus: string;
    middleName: string;
    mobilePhone: PhoneNumber;
    pendingInvite: boolean;
    positionOrTitle: string;
    proxyEmailAddress: string;
    roleDefault: Role;
    socialSecurityNumber: string;
    softCreditPull: SoftCreditPull;
    subRoleDefault: SubRole;
    usDriversLicense: USDriversLicense;
    user: AppUserDTO2;
}

export interface PersonResultGroup {
    DominantValues: any[];
    GroupId: string;
    RecordCount: number;
    RecordDetails: PersonRecordDetail[];
    Relevance: number;
}

export interface PersonSearchRequestDTO {
    Criteria: CriteriaDTO;
    Datasources: DatasourcesDTO;
    PermissiblePurpose: PermissiblePurpose;
    Reference: string;
    xmlns: string;
}

export interface PersonSearchResultsDTO extends BaseResultDTO {
    ResultGroup: PersonResultGroup;
}

export interface PersonUpdateDto extends PersonCreateDto, Id {
}

export interface PhoneNumber {
    locale: string;
    value: string;
}

export interface RandomAssetRequestDto extends OwnerInformationRequestDto {
    appraisedValue: number;
    bookValue: number;
    bookValueDiscount: number;
    bookValueSource: string;
    cashDeposit: number;
    estimatedValue: number;
    purchasePrice: number;
    simpleAssetDescriptor: string;
    ucc1: string;
    value: number;
}

export interface RandomAssetResponseDto extends RandomAssetRequestDto, InformationResponseDto, AssetResponseInformation {
}

export interface RealEstateRequestDto extends OwnerInformationRequestDto {
    afterLeaseValue: number;
    afterRepairValue: number;
    appraisedValue: number;
    asCompletedValue: number;
    buildingSquareFootage: number;
    constructionCost: number;
    costPerSquareFoot: number;
    earnestMoneyDeposit: number;
    landSquareFootage: number;
    leasedSquareFootage: number;
    lotPurchasePrice: number;
    mlsId: string;
    ownerOccupiedSquareFootage: number;
    purchasePrice: number;
    repairCost: number;
    repairCostPerSquareFoot: number;
    streetAddress: Address;
    taxOrParcelId: string;
    totalCompletionCost: number;
}

export interface RealEstateResponseDto extends RealEstateRequestDto, InformationResponseDto, AssetResponseInformation {
}

export interface RenameEntityDto {
    title: string;
}

export interface ResponseEntity<T> extends HttpEntity<T> {
    statusCode: HttpStatus;
    statusCodeValue: number;
}

export interface ResultIdDTO {
    id: number;
}

export interface RollupDto {
    additionalCashNeeded?: number;
    cumulativeDeposits: number;
    cumulativeMla: number;
    ltv?: number;
}

export interface SearchResultDTO {
    header: string;
    hitText: string;
    link: URL;
    type: SearchTypeEnum;
}

export interface SearchResultsDTO {
    items: SearchResultDTO[];
    similar: string[];
    total: number;
}

export interface SecurityResponseDto {
    challenge: string;
    channel: string;
    destination: string;
    linkActionId: string;
    message: string;
    session: string;
    success: boolean;
    username: string;
}

export interface Serializable {
}

export interface SetLabelOnUserDto {
    labels: string[];
    loanRoleToSetFor: string;
}

export interface SherpaEntity extends BaseObject {
    madeBy?: AppUser;
    title: string;
    type: SherpaEntityType;
}

export interface SherpaEntityDto {
    id: string;
    title: string;
    type: SherpaEntityType;
}

export interface ShoeboxItemRequestDto {
    documentId: string;
    id: string;
    loanId: string;
    title: string;
    userId: string;
}

export interface ShoeboxItemResponseDto {
    document: DocumentAnswerV2Dto;
    entity: SherpaEntityDto;
    id: string;
    knowledgeBase: KnowledgeBase;
    loanRole: LoanRoleDto;
    title: string;
}

export interface SimpleLabelDto {
    canBeOnBorrower: boolean;
    canBeOnLender: boolean;
    id: string;
    title: string;
}

export interface Slice<T> extends Streamable<T> {
    content: T[];
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: Pageable;
    size: number;
    sort: Sort;
}

export interface SoftCreditPull extends BaseObject {
    acceptedDate: DateAsString;
    attemptsToProcess: number;
    consentStatus: ConsentStatus;
    creditScore: number;
    creditScoreExperian: number;
    creditScoreTransUnion: string;
    errorMessage: string;
    experired: boolean;
    signature: string;
}

export interface Sort extends Streamable<Order>, Serializable {
    sorted: boolean;
    unsorted: boolean;
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
    empty: boolean;
}

export interface Supplier<T> {
}

export interface SupportRequestDto {
    authorId: string;
    categoryId: string;
    duration: string;
    id: string;
    loomLink: string;
    roles: Role[];
    tag: string;
    tangoHtml: string;
    title: string;
    videoId: string;
}

export interface SupportResponseDto {
    author: AppUserDTO2;
    category: CategoryDto;
    duration: string;
    id: string;
    loomLink: string;
    modifiedAt: DateAsString;
    roles: Role[];
    tag: string;
    tangoHtml: string;
    title: string;
    video: Document;
    views: number;
}

export interface Template extends BaseObject {
    blockType: BlockType;
    company: Company;
    entityModifiers: EntityModifierType[];
    lenderPolicyLtv: number;
    loanViewType: LoanViewType;
    name: string;
    sherpaEntityType: SherpaEntityType;
    uniqueId: string;
    ventureLoanType: number;
}

export interface TemplateElement extends BaseObject {
    answer: Answer;
    children: TemplateElement[];
    destinations: Destination[];
    displayOrder: number;
    generated: boolean;
    hasAnswer: boolean;
    hasExpiration: boolean;
    knowledgeBase: KnowledgeBase;
    locations: Location[];
    modifiers: ElementModifierType[];
    priorityType: PriorityType;
    storageType: StorageType;
    template: Template;
    title: string;
    venturesDocType: VenturesDocType;
}

export interface TemplateElementRequestDto {
    answerId: string;
    destinations: DestinationDto[];
    displayOrder: number;
    hasAnswer: boolean;
    hasExpiration: boolean;
    knowledgeBase: KnowledgeBase;
    locations: LocationDto[];
    modifiers: ElementModifierType[];
    parentId: string;
    priorityType: PriorityType;
    storageType: StorageType;
    templateElementId: string;
    templateId: string;
    title: string;
    venturesDocType: VenturesDocType;
}

export interface TemplateElementResponseDto {
    answer: AnswerV2Dto;
    childrenIds: string[];
    destinations: DestinationDto[];
    displayOrder: number;
    evaluatedTitle: string;
    hasAnswer: boolean;
    hasExpiration: boolean;
    knowledgeBase: KnowledgeBase;
    locations: LocationDto[];
    modifiers: ElementModifierType[];
    parentId: string;
    priorityType: PriorityType;
    storageType: StorageType;
    templateElementId: string;
    templateId: string;
    title: string;
    venturesDocType: VenturesDocType;
}

export interface TemplateElementsRequestDto {
    elements: TemplateElementRequestDto[];
}

export interface TemplateElementsResponseDto {
    elements: { [index: string]: TemplateElementResponseDto };
}

export interface TemplateRequestDto {
    blockType: BlockType;
    entityModifiers: EntityModifierType[];
    id: string;
    lenderId: string;
    lenderPolicyLtv?: number;
    name: string;
    sherpaEntityType: SherpaEntityType;
    uniqueId: string;
    viewType: LoanViewType;
}

export interface TemplateResponseDto {
    blockType: BlockType;
    company: CompanyDto;
    entityModifiers: EntityModifierType[];
    id: string;
    lenderPolicyLtv: number;
    loanViewType: LoanViewType;
    name: string;
    sherpaEntityType: SherpaEntityType;
}

export interface TemplateTypeMapsDto {
    blockTypeMap: { [index: string]: any };
    elementModifierTypeMap: { [index: string]: any };
    entityModifierTypeMap: { [index: string]: any };
    loanViewTypeMap: { [index: string]: any };
    locationTypeMap: { [index: string]: any };
    namingStrategyTypeMap: { [index: string]: any };
    priorityTypeMap: { [index: string]: any };
    sherpaEntityTypeMap: { [index: string]: any };
    storageTypeMap: { [index: string]: any };
}

export interface TemplatesRequestDto {
    templates: TemplateRequestDto[];
}

export interface TemplatesResponseDto {
    templates: TemplateResponseDto[];
}

export interface TimezoneDTO {
    description: string;
    hours: number;
    id: string;
    minutes: number;
}

export interface TinyDocument {
    createdAt: DateAsString;
    expiresAt: DateAsString;
    forDocument: Document;
    forElement: FormElementPackageInfo;
    id: string;
}

export interface URL extends Serializable {
}

export interface USDriversLicense extends BaseObject {
    address: Address;
    backUrl: string;
    birthDate: DateAsString;
    complianceType: string;
    countryIdentification: string;
    dd: string;
    documentDiscriminator: string;
    endorsementCodes: string;
    expiration: DateAsString;
    eyesColor: string;
    familyName: string;
    familyNameTruncation: string;
    frontUrl: string;
    givenName: string;
    givenNameTruncation: string;
    hairColor: string;
    height: string;
    inventoryControlNumber: string;
    issueDate: DateAsString;
    middleName: string;
    middleNameTruncation: string;
    number: string;
    organDonorIndicator: string;
    restrictions: string;
    revisionDate: DateAsString;
    sex: string;
    state: UnitedStates;
    vehicleClass: string;
    verified: DateAsString;
    weight: string;
}

export interface USDriversLicenseDto {
    address: Address;
    backUpload: Upload;
    birthDate: DateAsString;
    complianceType: string;
    countryIdentification: string;
    dd: string;
    documentDiscriminator: string;
    endorsementCodes: string;
    expiration: DateAsString;
    eyesColor: string;
    familyName: string;
    familyNameTruncation: string;
    frontUpload: Upload;
    givenName: string;
    givenNameTruncation: string;
    hairColor: string;
    height: string;
    inventoryControlNumber: string;
    issueDate: DateAsString;
    middleName: string;
    middleNameTruncation: string;
    number: string;
    organDonorIndicator: string;
    restrictions: string;
    revisionDate: DateAsString;
    sex: string;
    state: UnitedStates;
    vehicleClass: string;
    verified: DateAsString;
    weight: string;
}

export interface UpdateLoanDTO {
    actualLoanAmortization?: number;
    actualLoanAmount?: number;
    actualLoanTerm?: number;
    amortizationInMonths?: number;
    apr: number;
    closeDate: DateAsString;
    downPayment: number;
    downPaymentPercentage?: number;
    downPaymentSource: string;
    /**
     * @deprecated
     */
    entityName: string;
    /**
     * @deprecated
     */
    entityType: EntityType;
    index?: number;
    loanAmount: number;
    loanOriginationFees?: number;
    loanOriginationPoints?: number;
    loanOtherFees?: number;
    loanPurpose: string;
    loanTermInMonths: number;
    margin?: number;
    projectName: string;
    templateProjectName: string;
    unsecured: boolean;
}

export interface Upload {
    documentType: DocumentType;
    name: string;
    providerType: ProviderType;
    resourceKey: string;
    /**
     * @deprecated
     */
    s3Key: string;
    url: string;
}

export interface UploadLogo {
    filename: string;
    imageData: any;
    subdomain: string;
}

export interface UserProgress {
    assigned: number;
    percent: number;
    uploaded: number;
}

export interface UserTaskDetailsDto {
    count: { [index: string]: number };
    forMe: ITask[];
}

export interface UsernamePasswordAuthenticationDto {
    password: string;
    remember: boolean;
    username: string;
}

export interface VentureAddressDTO {
    city: string;
    countryCode: string;
    id: number;
    postalCode: string;
    stateCode: string;
    street1: string;
    street2: string;
}

export interface VentureContactDTO {
    businessAddress: VentureAddressDTO;
    businessAddressId: number;
    businessPhone: string;
    dateOfBirth: string;
    email: string;
    firstName: string;
    homeAddress: VentureAddressDTO;
    homeAddressId: number;
    homePhone: string;
    id: number;
    lastName: string;
    mobilePhone: string;
}

export interface VentureContactListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureContactDTO[];
}

export interface VentureDocumentCreateDTO {
    Base64EncodedFile: string;
    Comment: string;
    Description: string;
    DocumentTypeID: number;
    FileName: string;
    LoanID: number;
}

export interface VentureDocumentFileTypeDTO {
    id: number;
    name: string;
}

export interface VentureDocumentFileTypeListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureDocumentFileTypeDTO[];
}

export interface VentureEntityDTO {
    id: number;
    name: string;
}

export interface VentureListResponseBaseDTO {
    page: number;
    recordsPerPage: number;
    totalCount: number;
}

export interface VentureLoanCreateDTO {
    id: number;
    loanStatus: string;
    loanType: string;
    loanTypeId: number;
    logNumber: number;
    organizationId: number;
    primaryOperatingCompanyId: number;
    primaryOperatingCompanyName: string;
    projectAddress: VentureAddressDTO;
    projectAddressId: number;
}

export interface VentureLoanCreateResultDTO extends VentureResponseBaseDTO {
    result: ResultIdDTO;
}

export interface VentureLoanDTO {
    id: number;
    loanStatus: string;
    loanType: string;
    loanTypeId: number;
    logNumber: string;
    organizationId: number;
    primaryOperatingCompanyId: number;
    primaryOperatingCompanyName: string;
    projectAddress: VentureAddressDTO;
    projectAddressId: number;
}

export interface VentureLoanEntityCreateDTO {
    address: VentureAddressDTO;
    addressId: number;
    association: string;
    companyId: number;
    companyName: string;
    dbaName: string;
    dunsNumber: string;
    email: string;
    entityTypeDescription: string;
    entityTypeId: number;
    guaranteeType: string;
    id: number;
    loanId: number;
    naicsCodeId: number;
    naicsDescription: string;
    phone: string;
    taxId: string;
    taxIdType: string;
}

export interface VentureLoanEntityCreateResultDTO extends VentureResponseBaseDTO {
    result: ResultIdDTO;
}

export interface VentureLoanEntityDTO {
    address: VentureAddressDTO;
    addressId: number;
    association: string;
    companyId: number;
    companyName: string;
    dbaName: string;
    dunsNumber: string;
    email: string;
    entityTypeDescription: string;
    entityTypeId: number;
    guaranteeType: string;
    id: number;
    loanId: number;
    naicsCodeId: number;
    naicsDescription: string;
    phone: string;
    taxId: string;
    taxIdType: string;
}

export interface VentureLoanEntityMemberDTO {
    guaranteeType: string;
    id: number;
    loanEntityId: number;
    memberContactId: number;
    memberLoanEntityId: number;
    name: string;
    ownershipPercentage: number;
    taxId: string;
    taxIdType: string;
}

export interface VentureLoanEntityMemberListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureLoanEntityMemberDTO[];
}

export interface VentureLoanEntityMemberResultDTO extends VentureResponseBaseDTO {
    result: VentureLoanEntityMemberDTO;
}

export interface VentureLoanEntityResultListDTO extends VentureListResponseBaseDTO {
    result: VentureLoanEntityDTO[];
}

export interface VentureLoanNoteDTO {
    addedOn: DateAsString;
    id: number;
    loanId: number;
    noteText: string;
    noteTypeId: number;
}

export interface VentureLoanNoteResultDTO extends VentureResponseBaseDTO {
    result: ResultIdDTO;
}

export interface VentureLoanResultDTO extends VentureResponseBaseDTO {
    result: VentureLoanDTO;
}

export interface VentureLoanTypeDTO {
    category: string;
    id: number;
    name: string;
}

export interface VentureLoanTypeListDTO {
    loanType: VentureEntityDTO[];
}

export interface VentureLoanTypeListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureLoanTypeDTO[];
}

export interface VentureNaicsCodeDTO {
    code: string;
    description: string;
    id: number;
}

export interface VentureNaicsCodeListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureNaicsCodeDTO[];
}

export interface VentureResponseBaseDTO {
    messages: string[];
    succeeded: boolean;
}

export interface VentureTokenResultDTO {
    token: string;
}

export interface VersionDto {
    gitHash: string;
    version: string;
}

export interface View extends Card<any> {
    body: any;
    livePDF417: boolean;
    /**
     * @deprecated
     */
    user: IUser;
    viewType: ViewType;
    wizard: boolean;
}

export interface WorkFlowSignerDTO {
    number: number;
    signerId: string;
}

export interface WorkflowCreateDTO {
    fillerId: string;
    payerID: string;
    signers: WorkFlowSignerDTO[];
    type: WorkflowType;
}

export interface WorkflowEntity extends BaseObject {
    documentTemplate: Document;
    envelopeId: string;
    form: WorkflowFormEntity;
    formElementPackageInfo: FormElementPackageInfo;
    formElementV2: FormElementV2;
    loan: Loan;
    pdf: Document;
    phases: WorkflowPhaseEntity[];
    workflowType: WorkflowType;
}

export interface WorkflowFormEntity extends BaseObject {
}

export interface WorkflowPhaseAgentEntity extends BaseObject {
    number: number;
    role: WorflowAgentRole;
    status: WorkflowPhaseAgentStatus;
    user: AppUser;
}

export interface WorkflowPhaseEntity extends BaseObject {
    agents: WorkflowPhaseAgentEntity[];
    orderAction: number;
    phaseType: WorkflowPhaseType;
    status: WorkflowPhaseStatus;
}

export interface WorkflowRoleDTO {
    description: string;
    number: number;
    role: WorflowAgentRole;
}

export interface WorkflowRolesDTO {
    roles: WorkflowRoleDTO[];
}

export interface ZipFileJobRequestDto {
    id: string;
    infoIdList: string[];
    itemsIgnored: number;
    itemsZipped: number;
    loanId: string;
    userId: string;
    view: LoanViewType;
    virtualFolderNames: string[];
}

export interface ZipFileJobResponseDto {
    id: string;
    itemsIgnored: number;
    itemsZipped: number;
    loanId: string;
    user: AppUserDTO2;
    zip: GeneratedPackageDto;
}

export interface ZipFileJobsResponseDto {
    jobs: ZipFileJobResponseDto[];
}

export interface htmlDto {
    header: string;
    html: string;
}

export type DateAsString = string;

export type RestResponse<R> = Promise<AxiosResponse<R>>;

export enum AuthProviderType {
    local = "local",
    facebook = "facebook",
    google = "google",
    github = "github",
    yahoo = "yahoo",
    azuread = "azuread",
    apple = "apple",
}

export enum BlockType {
    NO_BLOCK = "NO_BLOCK",
    LOAN = "LOAN",
    ENTITY = "ENTITY",
}

export enum CardType {
    DRIVERS_LICENSE = "DRIVERS_LICENSE",
    DEMOGRAPHICS = "DEMOGRAPHICS",
    SOFT_CREDIT_PULL = "SOFT_CREDIT_PULL",
    IRS_4506C = "IRS_4506C",
    CONGRATULATIONS = "CONGRATULATIONS",
    LOAN_SUMMARY = "LOAN_SUMMARY",
    ONBOARDING = "ONBOARDING",
    LOAN_SUMMARIES = "LOAN_SUMMARIES",
}

export enum ClearReportStatus {
    WAITING = "WAITING",
    SCHEDULED = "SCHEDULED",
    PROCESSING = "PROCESSING",
    PROCESSED = "PROCESSED",
    ERROR = "ERROR",
    CANCELED = "CANCELED",
}

export enum ClearReportType {
    PERSON_SEARCH = "PERSON_SEARCH",
    PERSON_REPORT = "PERSON_REPORT",
    PERSON_QUICK_ANALYSIS_FLAG_REPORT = "PERSON_QUICK_ANALYSIS_FLAG_REPORT",
    BUSINESS_QUICK_ANALYSIS_FLAG_REPORT = "BUSINESS_QUICK_ANALYSIS_FLAG_REPORT",
    BUSINESS_SEARCH = "BUSINESS_SEARCH",
}

export enum ConsentStatus {
    NOT_ACCEPTED = "NOT_ACCEPTED",
    ACCEPTED = "ACCEPTED",
    PROCESSING = "PROCESSING",
    PROCESSED = "PROCESSED",
    SCHEDULED = "SCHEDULED",
    PROCESSED_ERROR = "PROCESSED_ERROR",
    PROCESSED_INCOMPLETE = "PROCESSED_INCOMPLETE",
}

export enum Direction {
    ASC = "ASC",
    DESC = "DESC",
}

export enum DocumentType {
    UNKNOWN = "UNKNOWN",
    PHOTOS_AND_IMAGES = "PHOTOS_AND_IMAGES",
    AUDIO = "AUDIO",
    PDF = "PDF",
    EXCEL = "EXCEL",
    VIDEO = "VIDEO",
    ZIP = "ZIP",
    WORD_DOCX = "WORD_DOCX",
    FORMS = "FORMS",
    SLIDES = "SLIDES",
    DOCUSIGN_TEMPLATE = "DOCUSIGN_TEMPLATE",
}

export enum ElementModifierType {
    ALL = "ALL",
    NONE = "NONE",
    LENDER_ONLY = "LENDER_ONLY",
    SIGNATURE_REQUIRED = "SIGNATURE_REQUIRED",
    EDITABLE_XLS = "EDITABLE_XLS",
    BILLING_DOCUMENT = "BILLING_DOCUMENT",
}

export enum EntityModifierType {
    ALL = "ALL",
    APPLICANT = "APPLICANT",
    NON_APPLICANT = "NON_APPLICANT",
    NEW = "NEW",
    RELATED_ENTITY = "RELATED_ENTITY",
}

export enum EntityType {
    PERSON = "PERSON",
    DBA = "DBA",
    PARTNERSHIP = "PARTNERSHIP",
    CCORP = "CCORP",
    SCORP = "SCORP",
    LLC = "LLC",
    LLP = "LLP",
    TRUST = "TRUST",
    COMPANY = "COMPANY",
    ASSET = "ASSET",
    REAL_ESTATE_ASSET = "REAL_ESTATE_ASSET",
}

export enum Features {
    CALENDAR = "CALENDAR",
    ANALYTICS = "ANALYTICS",
    COMMUNICATIONS = "COMMUNICATIONS",
    SEARCH = "SEARCH",
    TEMPLATES = "TEMPLATES",
}

export enum FormElementProcessStatus {
    NOT_PROCESSED = "NOT_PROCESSED",
    ERROR = "ERROR",
    PROCESSED = "PROCESSED",
}

export enum FormElementState {
    UNANSWERED = "UNANSWERED",
    ANSWERED = "ANSWERED",
    ANSWERED_UNEXPIRED = "ANSWERED_UNEXPIRED",
    ANSWERED_EXPIRING = "ANSWERED_EXPIRING",
    ANSWERED_EXPIRING_RE_ASKED = "ANSWERED_EXPIRING_RE_ASKED",
    ANSWERED_EXPIRED = "ANSWERED_EXPIRED",
    ANSWERED_EXPIRED_RE_ASKED = "ANSWERED_EXPIRED_RE_ASKED",
}

export enum GenerationStatus {
    NEVER = "NEVER",
    REQUESTED = "REQUESTED",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
    FAILED = "FAILED",
}

/**
 * Values:
 * - `CONTINUE`
 * - `SWITCHING_PROTOCOLS`
 * - `PROCESSING`
 * - `CHECKPOINT`
 * - `OK`
 * - `CREATED`
 * - `ACCEPTED`
 * - `NON_AUTHORITATIVE_INFORMATION`
 * - `NO_CONTENT`
 * - `RESET_CONTENT`
 * - `PARTIAL_CONTENT`
 * - `MULTI_STATUS`
 * - `ALREADY_REPORTED`
 * - `IM_USED`
 * - `MULTIPLE_CHOICES`
 * - `MOVED_PERMANENTLY`
 * - `FOUND`
 * - `MOVED_TEMPORARILY` - @deprecated
 * - `SEE_OTHER`
 * - `NOT_MODIFIED`
 * - `USE_PROXY` - @deprecated
 * - `TEMPORARY_REDIRECT`
 * - `PERMANENT_REDIRECT`
 * - `BAD_REQUEST`
 * - `UNAUTHORIZED`
 * - `PAYMENT_REQUIRED`
 * - `FORBIDDEN`
 * - `NOT_FOUND`
 * - `METHOD_NOT_ALLOWED`
 * - `NOT_ACCEPTABLE`
 * - `PROXY_AUTHENTICATION_REQUIRED`
 * - `REQUEST_TIMEOUT`
 * - `CONFLICT`
 * - `GONE`
 * - `LENGTH_REQUIRED`
 * - `PRECONDITION_FAILED`
 * - `PAYLOAD_TOO_LARGE`
 * - `REQUEST_ENTITY_TOO_LARGE` - @deprecated
 * - `URI_TOO_LONG`
 * - `REQUEST_URI_TOO_LONG` - @deprecated
 * - `UNSUPPORTED_MEDIA_TYPE`
 * - `REQUESTED_RANGE_NOT_SATISFIABLE`
 * - `EXPECTATION_FAILED`
 * - `I_AM_A_TEAPOT`
 * - `INSUFFICIENT_SPACE_ON_RESOURCE` - @deprecated
 * - `METHOD_FAILURE` - @deprecated
 * - `DESTINATION_LOCKED` - @deprecated
 * - `UNPROCESSABLE_ENTITY`
 * - `LOCKED`
 * - `FAILED_DEPENDENCY`
 * - `TOO_EARLY`
 * - `UPGRADE_REQUIRED`
 * - `PRECONDITION_REQUIRED`
 * - `TOO_MANY_REQUESTS`
 * - `REQUEST_HEADER_FIELDS_TOO_LARGE`
 * - `UNAVAILABLE_FOR_LEGAL_REASONS`
 * - `INTERNAL_SERVER_ERROR`
 * - `NOT_IMPLEMENTED`
 * - `BAD_GATEWAY`
 * - `SERVICE_UNAVAILABLE`
 * - `GATEWAY_TIMEOUT`
 * - `HTTP_VERSION_NOT_SUPPORTED`
 * - `VARIANT_ALSO_NEGOTIATES`
 * - `INSUFFICIENT_STORAGE`
 * - `LOOP_DETECTED`
 * - `BANDWIDTH_LIMIT_EXCEEDED`
 * - `NOT_EXTENDED`
 * - `NETWORK_AUTHENTICATION_REQUIRED`
 */
export enum HttpStatus {
    CONTINUE = "CONTINUE",
    SWITCHING_PROTOCOLS = "SWITCHING_PROTOCOLS",
    PROCESSING = "PROCESSING",
    CHECKPOINT = "CHECKPOINT",
    OK = "OK",
    CREATED = "CREATED",
    ACCEPTED = "ACCEPTED",
    NON_AUTHORITATIVE_INFORMATION = "NON_AUTHORITATIVE_INFORMATION",
    NO_CONTENT = "NO_CONTENT",
    RESET_CONTENT = "RESET_CONTENT",
    PARTIAL_CONTENT = "PARTIAL_CONTENT",
    MULTI_STATUS = "MULTI_STATUS",
    ALREADY_REPORTED = "ALREADY_REPORTED",
    IM_USED = "IM_USED",
    MULTIPLE_CHOICES = "MULTIPLE_CHOICES",
    MOVED_PERMANENTLY = "MOVED_PERMANENTLY",
    FOUND = "FOUND",
    /**
     * @deprecated
     */
    MOVED_TEMPORARILY = "MOVED_TEMPORARILY",
    SEE_OTHER = "SEE_OTHER",
    NOT_MODIFIED = "NOT_MODIFIED",
    /**
     * @deprecated
     */
    USE_PROXY = "USE_PROXY",
    TEMPORARY_REDIRECT = "TEMPORARY_REDIRECT",
    PERMANENT_REDIRECT = "PERMANENT_REDIRECT",
    BAD_REQUEST = "BAD_REQUEST",
    UNAUTHORIZED = "UNAUTHORIZED",
    PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
    FORBIDDEN = "FORBIDDEN",
    NOT_FOUND = "NOT_FOUND",
    METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED",
    NOT_ACCEPTABLE = "NOT_ACCEPTABLE",
    PROXY_AUTHENTICATION_REQUIRED = "PROXY_AUTHENTICATION_REQUIRED",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    CONFLICT = "CONFLICT",
    GONE = "GONE",
    LENGTH_REQUIRED = "LENGTH_REQUIRED",
    PRECONDITION_FAILED = "PRECONDITION_FAILED",
    PAYLOAD_TOO_LARGE = "PAYLOAD_TOO_LARGE",
    /**
     * @deprecated
     */
    REQUEST_ENTITY_TOO_LARGE = "REQUEST_ENTITY_TOO_LARGE",
    URI_TOO_LONG = "URI_TOO_LONG",
    /**
     * @deprecated
     */
    REQUEST_URI_TOO_LONG = "REQUEST_URI_TOO_LONG",
    UNSUPPORTED_MEDIA_TYPE = "UNSUPPORTED_MEDIA_TYPE",
    REQUESTED_RANGE_NOT_SATISFIABLE = "REQUESTED_RANGE_NOT_SATISFIABLE",
    EXPECTATION_FAILED = "EXPECTATION_FAILED",
    I_AM_A_TEAPOT = "I_AM_A_TEAPOT",
    /**
     * @deprecated
     */
    INSUFFICIENT_SPACE_ON_RESOURCE = "INSUFFICIENT_SPACE_ON_RESOURCE",
    /**
     * @deprecated
     */
    METHOD_FAILURE = "METHOD_FAILURE",
    /**
     * @deprecated
     */
    DESTINATION_LOCKED = "DESTINATION_LOCKED",
    UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
    LOCKED = "LOCKED",
    FAILED_DEPENDENCY = "FAILED_DEPENDENCY",
    TOO_EARLY = "TOO_EARLY",
    UPGRADE_REQUIRED = "UPGRADE_REQUIRED",
    PRECONDITION_REQUIRED = "PRECONDITION_REQUIRED",
    TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
    REQUEST_HEADER_FIELDS_TOO_LARGE = "REQUEST_HEADER_FIELDS_TOO_LARGE",
    UNAVAILABLE_FOR_LEGAL_REASONS = "UNAVAILABLE_FOR_LEGAL_REASONS",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
    BAD_GATEWAY = "BAD_GATEWAY",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
    GATEWAY_TIMEOUT = "GATEWAY_TIMEOUT",
    HTTP_VERSION_NOT_SUPPORTED = "HTTP_VERSION_NOT_SUPPORTED",
    VARIANT_ALSO_NEGOTIATES = "VARIANT_ALSO_NEGOTIATES",
    INSUFFICIENT_STORAGE = "INSUFFICIENT_STORAGE",
    LOOP_DETECTED = "LOOP_DETECTED",
    BANDWIDTH_LIMIT_EXCEEDED = "BANDWIDTH_LIMIT_EXCEEDED",
    NOT_EXTENDED = "NOT_EXTENDED",
    NETWORK_AUTHENTICATION_REQUIRED = "NETWORK_AUTHENTICATION_REQUIRED",
}

export enum KnowledgeBase {
    NO_KNOWLEDGE_BASE = "NO_KNOWLEDGE_BASE",
    APPLICANT = "APPLICANT",
    ARTICLES_OF_ORGANIZATION = "ARTICLES_OF_ORGANIZATION",
    BORROWING_RESOLUTION = "BORROWING_RESOLUTION",
    CERTIFICATE_OF_FORMATION = "CERTIFICATE_OF_FORMATION",
    COMPANY = "COMPANY",
    COVER_PAGE = "COVER_PAGE",
    CREDIT_CONSENT = "CREDIT_CONSENT",
    CREDIT_REPORT = "CREDIT_REPORT",
    CREDIT_REPORT_RAW_DATA = "CREDIT_REPORT_RAW_DATA",
    CREDIT_REPORTS = "CREDIT_REPORTS",
    DRIVERS_LICENSE_FRONT = "DRIVERS_LICENSE_FRONT",
    DRIVERS_LICENSE_BACK = "DRIVERS_LICENSE_BACK",
    LOAN_DOCUMENTS = "LOAN_DOCUMENTS",
    LOAN_DOCUMENT_DRAFTED = "LOAN_DOCUMENT_DRAFTED",
    LOAN_DOCUMENT_SIGNED = "LOAN_DOCUMENT_SIGNED",
    LOAN_DOCUMENT_RECORDED = "LOAN_DOCUMENT_RECORDED",
    OPERATING_AGREEMENT = "OPERATING_AGREEMENT",
    PHOTOS = "PHOTOS",
    PURCHASE_CONTRACTS = "PURCHASE_CONTRACTS",
    PURCHASE_CONTRACT = "PURCHASE_CONTRACT",
    PRINCIPALS = "PRINCIPALS",
    RELATED_ENTITY = "RELATED_ENTITY",
    SHOE_BOX = "SHOE_BOX",
    PDF_PACKAGE = "PDF_PACKAGE",
    ZIP_PACKAGE = "ZIP_PACKAGE",
    TRANSACTION = "TRANSACTION",
    SHOE_BOXES = "SHOE_BOXES",
    COLLATERAL_ASSET = "COLLATERAL_ASSET",
    COLLATERAL_ASSET_REAL_ESTATE = "COLLATERAL_ASSET_REAL_ESTATE",
    SUBJECT_COLLATERAL = "SUBJECT_COLLATERAL",
    SUBJECT_COLLATERAL_REAL_ESTATE = "SUBJECT_COLLATERAL_REAL_ESTATE",
    TRUST = "TRUST",
    LOAN_APPLICATION = "LOAN_APPLICATION",
    RISK_REPORTS = "RISK_REPORTS",
    RISK_REPORT = "RISK_REPORT",
    PERSON_QUICK_SEARCH_FLAG_REPORT = "PERSON_QUICK_SEARCH_FLAG_REPORT",
    BUSINESS_QUICK_SEARCH_FLAG_REPORT = "BUSINESS_QUICK_SEARCH_FLAG_REPORT",
    CAIVRS_REPORT = "CAIVRS_REPORT",
    GENERIC_SIG = "GENERIC_SIG",
    DEBT_SCHEDULE_SIG = "DEBT_SCHEDULE_SIG",
    IRS_7004_SIG = "IRS_7004_SIG",
    IRS_8821_SIG = "IRS_8821_SIG",
    IRS_4868_SIG = "IRS_4868_SIG",
    USCIS_G845_SIG = "USCIS_G845_SIG",
    SBA_2462_SIG = "SBA_2462_SIG",
    LOAN_APPLICATION_SIG = "LOAN_APPLICATION_SIG",
    IRS_SS4_SIG = "IRS_SS4_SIG",
    IRS_4506C_SIG = "IRS_4506C_SIG",
    GENERIC_LENDER_ONLY = "GENERIC_LENDER_ONLY",
    PERSONAL_FINANCIAL_STATEMENT = "PERSONAL_FINANCIAL_STATEMENT",
    PERSONAL_HISTORY = "PERSONAL_HISTORY",
    GOVERNMENT_ID = "GOVERNMENT_ID",
    GOVERNMENT_ID_FRONT = "GOVERNMENT_ID_FRONT",
    GOVERNMENT_ID_BACK = "GOVERNMENT_ID_BACK",
    APPRAISAL = "APPRAISAL",
    COMPARABLE_SALES = "COMPARABLE_SALES",
    DEED = "DEED",
    INSURANCE_TITLE = "INSURANCE_TITLE",
    INSURANCE_GENERIC = "INSURANCE_GENERIC",
    INSURANCE_BUILDERS_RISK = "INSURANCE_BUILDERS_RISK",
    INSURANCE_FLOOD = "INSURANCE_FLOOD",
    INSURANCE_HAZARD = "INSURANCE_HAZARD",
    INSURANCE_LIABILITY = "INSURANCE_LIABILITY",
    INSURANCE_LIFE = "INSURANCE_LIFE",
    INSURANCE_REQUEST = "INSURANCE_REQUEST",
    RENTAL_ANALYSIS = "RENTAL_ANALYSIS",
    MLS_PAGE = "MLS_PAGE",
    CONSTRUCTION_CONTRACT = "CONSTRUCTION_CONTRACT",
    CONSTRUCTION_BUDGET = "CONSTRUCTION_BUDGET",
    CONSTRUCTION_BIDS = "CONSTRUCTION_BIDS",
    BUILDING_PERMITS = "BUILDING_PERMITS",
    ARCHITECT_PLANS = "ARCHITECT_PLANS",
    RENT_COLLECTION_HISTORY = "RENT_COLLECTION_HISTORY",
    SURVEY = "SURVEY",
    SOIL_REPORT = "SOIL_REPORT",
    PHASE_1_ENVIRONMENTAL = "PHASE_1_ENVIRONMENTAL",
    ENVIRONMENTAL_APPROVAL = "ENVIRONMENTAL_APPROVAL",
    ENVIRONMENTAL_QUESTIONNAIRE = "ENVIRONMENTAL_QUESTIONNAIRE",
    TRUST_DEED = "TRUST_DEED",
    CERT_OF_TRUST = "CERT_OF_TRUST",
    TRUST_AGREEMENT = "TRUST_AGREEMENT",
    GENERIC_CORP = "GENERIC_CORP",
    ARTICLES_OF_ORGANIZATION_CORP = "ARTICLES_OF_ORGANIZATION_CORP",
    ARTICLES_OF_INCORPORATION_CORP = "ARTICLES_OF_INCORPORATION_CORP",
    PARTNERSHIP_AGREEMENT_CORP = "PARTNERSHIP_AGREEMENT_CORP",
    BYLAWS_CORP = "BYLAWS_CORP",
    CERT_OF_AMENDMENTS_CORP = "CERT_OF_AMENDMENTS_CORP",
    CERT_OF_GENERAL_PARTNERS_CORP = "CERT_OF_GENERAL_PARTNERS_CORP",
    CERT_OF_GOOD_STANDING_CORP = "CERT_OF_GOOD_STANDING_CORP",
    CERT_OF_GOOD_STANDING_DBA = "CERT_OF_GOOD_STANDING_DBA",
    CERT_OF_GOOD_FORMATION_CORP = "CERT_OF_GOOD_FORMATION_CORP",
    CERT_TRADE_NAME_CORP = "CERT_TRADE_NAME_CORP",
    DBA_GOOD_STANDING_CORP = "DBA_GOOD_STANDING_CORP",
    OPERATING_AGREEMENT_CORP = "OPERATING_AGREEMENT_CORP",
    SCHEDULE_OF_DEBT_CORP = "SCHEDULE_OF_DEBT_CORP",
    SCHEDULE_OF_REAL_ESTATE_CORP = "SCHEDULE_OF_REAL_ESTATE_CORP",
    SCHEDULE_OF_RELATED_ENTITIES_CORP = "SCHEDULE_OF_RELATED_ENTITIES_CORP",
    SCHEDULE_OF_OWNERSHIP_CORP = "SCHEDULE_OF_OWNERSHIP_CORP",
    CASH_FLOW_ANALYSIS_CORP = "CASH_FLOW_ANALYSIS_CORP",
    EIN_CORP = "EIN_CORP",
    MASTER_INVESTOR_WORKBOOK = "MASTER_INVESTOR_WORKBOOK",
    MASTER_LOAN_SERVICING_WORKBOOK = "MASTER_LOAN_SERVICING_WORKBOOK",
    DEAL_SHEET = "DEAL_SHEET",
    LOAN_PAYOFF_LETTER = "LOAN_PAYOFF_LETTER",
    COLLATERAL_RELEASE = "COLLATERAL_RELEASE",
    LOAN_PROPOSAL = "LOAN_PROPOSAL",
    LETTER_OF_INTENT = "LETTER_OF_INTENT",
    LOAN_EXECUTIVE_SUMMARY = "LOAN_EXECUTIVE_SUMMARY",
    CONTRACT_AMENDMENTS = "CONTRACT_AMENDMENTS",
    WELCOME_LETTER = "WELCOME_LETTER",
    REGISTERED_PRINCIPALS = "REGISTERED_PRINCIPALS",
    VIRTUAL_FOLDER = "VIRTUAL_FOLDER",
    SOURCES_USES = "SOURCES_USES",
}

export enum LinkActionType {
    INVITE_PERSON_TO_LOAN = "INVITE_PERSON_TO_LOAN",
    TEST_ACTION_CONTROLLER = "TEST_ACTION_CONTROLLER",
    CONFIRM_SIGN_UP = "CONFIRM_SIGN_UP",
    VIEW = "VIEW",
    RESEND_LOAN_INVITE = "RESEND_LOAN_INVITE",
    RESEND_CONFIRMATION_CODE = "RESEND_CONFIRMATION_CODE",
    RESET_PASSWORD = "RESET_PASSWORD",
}

export enum LoanEntityLabel {
    MEMBER = "MEMBER",
    APPLICANT = "APPLICANT",
    PRIMARY_COLLATERAL = "PRIMARY_COLLATERAL",
    PRIMARY_OPERATION_COMPANY = "PRIMARY_OPERATION_COMPANY",
    OPERATING_COMPANY = "OPERATING_COMPANY",
    EPC = "EPC",
    AFFILIATE = "AFFILIATE",
    RELATED_NON_AFFILIATE = "RELATED_NON_AFFILIATE",
    NOT_A_GUARANTOR = "NOT_A_GUARANTOR",
    UNSECURED_FULL = "UNSECURED_FULL",
    SECURED_FULL = "SECURED_FULL",
    SECURED_LIMITED = "SECURED_LIMITED",
    UNSECURED_LIMITED = "UNSECURED_LIMITED",
}

export enum LoanPhaseCategoryType {
    LEAD = "LEAD",
    ORIGINATION = "ORIGINATION",
    ARCHIVE = "ARCHIVE",
    PORTFOLIO = "PORTFOLIO",
}

export enum LoanReviewStatus {
    IN_PROGRESS = "IN_PROGRESS",
    READY_FOR_UNDERWRITING = "READY_FOR_UNDERWRITING",
    READY_FOR_REVIEW_PENDING = "READY_FOR_REVIEW_PENDING",
    READY_FOR_REVIEW_REVIEW = "READY_FOR_REVIEW_REVIEW",
    REVIEW_COMPLETE_ACCEPTED = "REVIEW_COMPLETE_ACCEPTED",
    REVIEW_COMPLETE_CONDITIONAL = "REVIEW_COMPLETE_CONDITIONAL",
    REVIEW_COMPLETE_REJECTED = "REVIEW_COMPLETE_REJECTED",
    CLOSING = "CLOSING",
    HOLD = "HOLD",
    IN_SERVICE = "IN_SERVICE",
    WITHDRAWN = "WITHDRAWN",
    LEAD = "LEAD",
    IN_DEFAULT = "IN_DEFAULT",
    ARCHIVE = "ARCHIVE",
}

export enum LoanStatus {
    APPLYING = "APPLYING",
    ANALYZING = "ANALYZING",
    CONDITIONING = "CONDITIONING",
    CLOSING = "CLOSING",
    ARCHIVED = "ARCHIVED",
    SERVICING = "SERVICING",
}

/**
 * @deprecated for removal
 * 
 * Values:
 * - `ACQUISITION`
 * - `AGRICULTURAL`
 * - `CRE_BRIDGE`
 * - `CRE_PURCHASE`
 * - `CRE_A_AND_D`
 * - `CRE_CONSTRUCTION`
 * - `EQUIPMENT`
 * - `FIX_AND_FLIP`
 * - `INVENTORY`
 * - `LINE_OF_CREDIT`
 * - `SBA504`
 * - `SBA504_CRE_PURCHASE`
 * - `SBA504_CRE_CONSTRUCTION`
 * - `SBA504_EQUIPMENT`
 * - `SBA7A`
 * - `ACCOUNTS_RECEIVABLE_CONTRACTS`
 * - `CASH_SECURED`
 * - `START_UP`
 * - `SIERRA_WEST`
 * - `UNSECURED`
 * - `RESIDENTIAL_CONSTRUCTION`
 * - `RESIDENTIAL_BRIDGE`
 * - `CRE_REHAB`
 */
export enum LoanType {
    ACQUISITION = "ACQUISITION",
    AGRICULTURAL = "AGRICULTURAL",
    CRE_BRIDGE = "CRE_BRIDGE",
    CRE_PURCHASE = "CRE_PURCHASE",
    CRE_A_AND_D = "CRE_A_AND_D",
    CRE_CONSTRUCTION = "CRE_CONSTRUCTION",
    EQUIPMENT = "EQUIPMENT",
    FIX_AND_FLIP = "FIX_AND_FLIP",
    INVENTORY = "INVENTORY",
    LINE_OF_CREDIT = "LINE_OF_CREDIT",
    SBA504 = "SBA504",
    SBA504_CRE_PURCHASE = "SBA504_CRE_PURCHASE",
    SBA504_CRE_CONSTRUCTION = "SBA504_CRE_CONSTRUCTION",
    SBA504_EQUIPMENT = "SBA504_EQUIPMENT",
    SBA7A = "SBA7A",
    ACCOUNTS_RECEIVABLE_CONTRACTS = "ACCOUNTS_RECEIVABLE_CONTRACTS",
    CASH_SECURED = "CASH_SECURED",
    START_UP = "START_UP",
    SIERRA_WEST = "SIERRA_WEST",
    UNSECURED = "UNSECURED",
    RESIDENTIAL_CONSTRUCTION = "RESIDENTIAL_CONSTRUCTION",
    RESIDENTIAL_BRIDGE = "RESIDENTIAL_BRIDGE",
    CRE_REHAB = "CRE_REHAB",
}

export enum LoanViewType {
    NO_VIEW = "NO_VIEW",
    CONVENTIONAL = "CONVENTIONAL",
    SBA7A = "SBA7A",
    SBA504 = "SBA504",
    CONSOLIDATED_LENDER = "CONSOLIDATED_LENDER",
}

export enum LocationType {
    NO_LOCATION = "NO_LOCATION",
    ROOT = "ROOT",
    MISC = "MISC",
    IGNORE = "IGNORE",
    ASSET = "ASSET",
    COMPANY = "COMPANY",
    INDIVIDUAL = "INDIVIDUAL",
    TRUST = "TRUST",
    TRANSACTION = "TRANSACTION",
    UNDERWRITING = "UNDERWRITING",
    SERVICING = "SERVICING",
    SERVICING_LOANS = "SERVICING_LOANS",
    CONSTRUCTION_FOLDER = "CONSTRUCTION_FOLDER",
    SERVICING_INVESTORS = "SERVICING_INVESTORS",
    SERVICING_INSURANCE = "SERVICING_INSURANCE",
    SERVICING_PAYOFFS = "SERVICING_PAYOFFS",
    PROPERTY_PHOTOS = "PROPERTY_PHOTOS",
    SBA504_ROOT = "SBA504_ROOT",
    SBA504_TAB01 = "SBA504_TAB01",
    SBA504_TAB02 = "SBA504_TAB02",
    SBA504_TAB03 = "SBA504_TAB03",
    SBA504_TAB04 = "SBA504_TAB04",
    SBA504_TAB05 = "SBA504_TAB05",
    SBA504_TAB06 = "SBA504_TAB06",
    SBA504_TAB07 = "SBA504_TAB07",
    SBA504_TAB08 = "SBA504_TAB08",
    SBA504_TAB09 = "SBA504_TAB09",
    SBA504_TAB10 = "SBA504_TAB10",
    SBA504_TAB11 = "SBA504_TAB11",
    SBA504_TAB12 = "SBA504_TAB12",
    SBA504_TAB13 = "SBA504_TAB13",
    SBA504_TAB14 = "SBA504_TAB14",
    SBA504_TAB15 = "SBA504_TAB15",
    SBA504_TAB16 = "SBA504_TAB16",
    SBA504_TAB17 = "SBA504_TAB17",
    SBA504_TAB18 = "SBA504_TAB18",
    SBA504_TAB19 = "SBA504_TAB19",
    SBA504_TAB20 = "SBA504_TAB20",
    SBA504_TAB21 = "SBA504_TAB21",
    SBA504_TAB22 = "SBA504_TAB22",
    SBA504_TAB23 = "SBA504_TAB23",
    SBA504_TAB24 = "SBA504_TAB24",
    SBA504_TAB25 = "SBA504_TAB25",
    SBA504_TAB26 = "SBA504_TAB26",
    SBA504_TAB27 = "SBA504_TAB27",
    SBA504_TAB28 = "SBA504_TAB28",
    SBA504_CLOSING_DOCS = "SBA504_CLOSING_DOCS",
    SBA504_ENTITY_DOCS = "SBA504_ENTITY_DOCS",
    SBA504_MISC = "SBA504_MISC",
    SBA504_APPROVALS = "SBA504_APPROVALS",
    SBA504_RECEIPTS = "SBA504_RECEIPTS",
    SBA504_SUBMIT = "SBA504_SUBMIT",
    SBA504_SUBMIT_DRAFTS = "SBA504_SUBMIT_DRAFTS",
    SBA504_SIGGI_DOCS = "SBA504_SIGGI_DOCS",
    SBA504_CORRESPONDENCE = "SBA504_CORRESPONDENCE",
    SBA504_DISBURSEMENTS = "SBA504_DISBURSEMENTS",
    SBA504_SECONDARY_MARKET = "SBA504_SECONDARY_MARKET",
    GOVERNMENT_IDS = "GOVERNMENT_IDS",
    CREDIT_CONSENTS = "CREDIT_CONSENTS",
    CREDIT_REPORTS = "CREDIT_REPORTS",
    RISK_REPORTS = "RISK_REPORTS",
    ENTITY_DOCS = "ENTITY_DOCS",
    SBA504_CUSTOM_01 = "SBA504_CUSTOM_01",
    SBA504_CUSTOM_02 = "SBA504_CUSTOM_02",
    SBA504_CUSTOM_03 = "SBA504_CUSTOM_03",
    SBA504_CUSTOM_04 = "SBA504_CUSTOM_04",
    SBA504_CUSTOM_05 = "SBA504_CUSTOM_05",
    SBA504_CUSTOM_06 = "SBA504_CUSTOM_06",
    SBA504_CUSTOM_07 = "SBA504_CUSTOM_07",
    SBA504_CUSTOM_08 = "SBA504_CUSTOM_08",
    SBA504_CUSTOM_09 = "SBA504_CUSTOM_09",
    SBA504_CUSTOM_10 = "SBA504_CUSTOM_10",
    SBA504_CUSTOM_11 = "SBA504_CUSTOM_11",
    SBA504_CUSTOM_12 = "SBA504_CUSTOM_12",
    SBA504_CUSTOM_13 = "SBA504_CUSTOM_13",
    SBA504_CUSTOM_14 = "SBA504_CUSTOM_14",
    SBA504_CUSTOM_15 = "SBA504_CUSTOM_15",
    SBA504_CUSTOM_16 = "SBA504_CUSTOM_16",
    SBA504_CUSTOM_17 = "SBA504_CUSTOM_17",
    SBA504_CUSTOM_18 = "SBA504_CUSTOM_18",
    SBA504_CUSTOM_19 = "SBA504_CUSTOM_19",
    SBA504_CUSTOM_20 = "SBA504_CUSTOM_20",
    SBA504_CUSTOM_21 = "SBA504_CUSTOM_21",
    SBA504_CUSTOM_22 = "SBA504_CUSTOM_22",
    SBA504_CUSTOM_23 = "SBA504_CUSTOM_23",
    SBA504_CUSTOM_24 = "SBA504_CUSTOM_24",
    SBA504_CUSTOM_25 = "SBA504_CUSTOM_25",
    SBA504_CUSTOM_26 = "SBA504_CUSTOM_26",
    SBA504_CUSTOM_27 = "SBA504_CUSTOM_27",
    SBA504_CUSTOM_28 = "SBA504_CUSTOM_28",
    SBA504_CUSTOM_29 = "SBA504_CUSTOM_29",
    SBA504_CUSTOM_30 = "SBA504_CUSTOM_30",
    SBA504_CUSTOM_31 = "SBA504_CUSTOM_31",
    SBA504_CUSTOM_32 = "SBA504_CUSTOM_32",
    SBA504_CUSTOM_33 = "SBA504_CUSTOM_33",
    SBA504_CUSTOM_34 = "SBA504_CUSTOM_34",
    SBA504_CUSTOM_35 = "SBA504_CUSTOM_35",
    SBA504_CUSTOM_36 = "SBA504_CUSTOM_36",
    SBA504_CUSTOM_37 = "SBA504_CUSTOM_37",
    SBA504_CUSTOM_38 = "SBA504_CUSTOM_38",
    SBA504_CUSTOM_39 = "SBA504_CUSTOM_39",
    SBA504_CUSTOM_40 = "SBA504_CUSTOM_40",
    SBA504_CUSTOM_41 = "SBA504_CUSTOM_41",
    SBA504_CUSTOM_42 = "SBA504_CUSTOM_42",
    SBA504_CUSTOM_43 = "SBA504_CUSTOM_43",
    SBA504_CUSTOM_44 = "SBA504_CUSTOM_44",
    SBA504_CUSTOM_45 = "SBA504_CUSTOM_45",
    SBA504_CUSTOM_46 = "SBA504_CUSTOM_46",
    SBA504_CUSTOM_47 = "SBA504_CUSTOM_47",
    SBA504_CUSTOM_48 = "SBA504_CUSTOM_48",
    SBA504_CUSTOM_49 = "SBA504_CUSTOM_49",
    SBA504_CUSTOM_50 = "SBA504_CUSTOM_50",
    SBA504_CUSTOM_51 = "SBA504_CUSTOM_51",
    SBA504_CUSTOM_52 = "SBA504_CUSTOM_52",
    SBA504_CUSTOM_53 = "SBA504_CUSTOM_53",
    SBA504_CUSTOM_54 = "SBA504_CUSTOM_54",
    SBA504_CUSTOM_55 = "SBA504_CUSTOM_55",
    SBA504_CUSTOM_56 = "SBA504_CUSTOM_56",
    SBA504_CUSTOM_57 = "SBA504_CUSTOM_57",
    SBA504_CUSTOM_58 = "SBA504_CUSTOM_58",
    SBA504_CUSTOM_59 = "SBA504_CUSTOM_59",
    SBA504_CUSTOM_60 = "SBA504_CUSTOM_60",
    SBA504_CUSTOM_61 = "SBA504_CUSTOM_61",
    SBA504_CUSTOM_62 = "SBA504_CUSTOM_62",
    SBA504_CUSTOM_63 = "SBA504_CUSTOM_63",
    SBA504_CUSTOM_64 = "SBA504_CUSTOM_64",
    SBA504_CUSTOM_65 = "SBA504_CUSTOM_65",
    SBA504_CUSTOM_66 = "SBA504_CUSTOM_66",
    SBA504_CUSTOM_67 = "SBA504_CUSTOM_67",
    SBA504_CUSTOM_68 = "SBA504_CUSTOM_68",
    SBA504_CUSTOM_69 = "SBA504_CUSTOM_69",
    SBA504_CUSTOM_70 = "SBA504_CUSTOM_70",
    SBA504_CUSTOM_71 = "SBA504_CUSTOM_71",
    SBA504_CUSTOM_72 = "SBA504_CUSTOM_72",
    SBA504_CUSTOM_73 = "SBA504_CUSTOM_73",
    SBA504_CUSTOM_74 = "SBA504_CUSTOM_74",
    SBA504_CUSTOM_75 = "SBA504_CUSTOM_75",
    SBA504_CUSTOM_76 = "SBA504_CUSTOM_76",
    SBA504_CUSTOM_77 = "SBA504_CUSTOM_77",
    SBA504_CUSTOM_78 = "SBA504_CUSTOM_78",
    SBA504_CUSTOM_79 = "SBA504_CUSTOM_79",
    SBA504_CUSTOM_80 = "SBA504_CUSTOM_80",
    SBA504_CUSTOM_81 = "SBA504_CUSTOM_81",
    SBA504_CUSTOM_82 = "SBA504_CUSTOM_82",
    SBA504_CUSTOM_83 = "SBA504_CUSTOM_83",
    SBA504_CUSTOM_84 = "SBA504_CUSTOM_84",
    SBA504_CUSTOM_85 = "SBA504_CUSTOM_85",
    SBA504_CUSTOM_86 = "SBA504_CUSTOM_86",
    SBA504_CUSTOM_87 = "SBA504_CUSTOM_87",
    SBA504_CUSTOM_88 = "SBA504_CUSTOM_88",
    SBA504_CUSTOM_89 = "SBA504_CUSTOM_89",
    SBA504_CUSTOM_90 = "SBA504_CUSTOM_90",
    SBA504_CUSTOM_91 = "SBA504_CUSTOM_91",
    SBA504_CUSTOM_92 = "SBA504_CUSTOM_92",
    SBA504_CUSTOM_93 = "SBA504_CUSTOM_93",
    SBA504_CUSTOM_94 = "SBA504_CUSTOM_94",
    SBA504_CUSTOM_95 = "SBA504_CUSTOM_95",
    SBA504_CUSTOM_96 = "SBA504_CUSTOM_96",
    SBA504_CUSTOM_97 = "SBA504_CUSTOM_97",
    SBA504_CUSTOM_98 = "SBA504_CUSTOM_98",
    SBA504_CUSTOM_99 = "SBA504_CUSTOM_99",
    SBA7A_CUSTOM_01 = "SBA7A_CUSTOM_01",
    SBA7A_CUSTOM_02 = "SBA7A_CUSTOM_02",
    SBA7A_CUSTOM_03 = "SBA7A_CUSTOM_03",
    SBA7A_CUSTOM_04 = "SBA7A_CUSTOM_04",
    SBA7A_CUSTOM_05 = "SBA7A_CUSTOM_05",
    SBA7A_CUSTOM_06 = "SBA7A_CUSTOM_06",
    SBA7A_CUSTOM_07 = "SBA7A_CUSTOM_07",
    SBA7A_CUSTOM_08 = "SBA7A_CUSTOM_08",
    SBA7A_CUSTOM_09 = "SBA7A_CUSTOM_09",
    SBA7A_CUSTOM_10 = "SBA7A_CUSTOM_10",
    SBA7A_CUSTOM_11 = "SBA7A_CUSTOM_11",
    SBA7A_CUSTOM_12 = "SBA7A_CUSTOM_12",
    SBA7A_CUSTOM_13 = "SBA7A_CUSTOM_13",
    SBA7A_CUSTOM_14 = "SBA7A_CUSTOM_14",
    SBA7A_CUSTOM_15 = "SBA7A_CUSTOM_15",
    SBA7A_CUSTOM_16 = "SBA7A_CUSTOM_16",
    SBA7A_CUSTOM_17 = "SBA7A_CUSTOM_17",
    SBA7A_CUSTOM_18 = "SBA7A_CUSTOM_18",
    SBA7A_CUSTOM_19 = "SBA7A_CUSTOM_19",
    SBA7A_CUSTOM_20 = "SBA7A_CUSTOM_20",
    SBA7A_CUSTOM_21 = "SBA7A_CUSTOM_21",
    SBA7A_CUSTOM_22 = "SBA7A_CUSTOM_22",
    SBA7A_CUSTOM_23 = "SBA7A_CUSTOM_23",
    SBA7A_CUSTOM_24 = "SBA7A_CUSTOM_24",
    SBA7A_CUSTOM_25 = "SBA7A_CUSTOM_25",
    SBA7A_CUSTOM_26 = "SBA7A_CUSTOM_26",
    SBA7A_CUSTOM_27 = "SBA7A_CUSTOM_27",
    SBA7A_CUSTOM_28 = "SBA7A_CUSTOM_28",
    SBA7A_CUSTOM_29 = "SBA7A_CUSTOM_29",
    SBA7A_CUSTOM_30 = "SBA7A_CUSTOM_30",
    SBA7A_CUSTOM_31 = "SBA7A_CUSTOM_31",
    SBA7A_CUSTOM_32 = "SBA7A_CUSTOM_32",
    SBA7A_CUSTOM_33 = "SBA7A_CUSTOM_33",
    SBA7A_CUSTOM_34 = "SBA7A_CUSTOM_34",
    SBA7A_CUSTOM_35 = "SBA7A_CUSTOM_35",
    SBA7A_CUSTOM_36 = "SBA7A_CUSTOM_36",
    SBA7A_CUSTOM_37 = "SBA7A_CUSTOM_37",
    SBA7A_CUSTOM_38 = "SBA7A_CUSTOM_38",
    SBA7A_CUSTOM_39 = "SBA7A_CUSTOM_39",
    SBA7A_CUSTOM_40 = "SBA7A_CUSTOM_40",
    SBA7A_CUSTOM_41 = "SBA7A_CUSTOM_41",
    SBA7A_CUSTOM_42 = "SBA7A_CUSTOM_42",
    SBA7A_CUSTOM_43 = "SBA7A_CUSTOM_43",
    SBA7A_CUSTOM_44 = "SBA7A_CUSTOM_44",
    SBA7A_CUSTOM_45 = "SBA7A_CUSTOM_45",
    SBA7A_CUSTOM_46 = "SBA7A_CUSTOM_46",
    SBA7A_CUSTOM_47 = "SBA7A_CUSTOM_47",
    SBA7A_CUSTOM_48 = "SBA7A_CUSTOM_48",
    SBA7A_CUSTOM_49 = "SBA7A_CUSTOM_49",
    SBA7A_CUSTOM_50 = "SBA7A_CUSTOM_50",
    SBA7A_CUSTOM_51 = "SBA7A_CUSTOM_51",
    SBA7A_CUSTOM_52 = "SBA7A_CUSTOM_52",
    SBA7A_CUSTOM_53 = "SBA7A_CUSTOM_53",
    SBA7A_CUSTOM_54 = "SBA7A_CUSTOM_54",
    SBA7A_CUSTOM_55 = "SBA7A_CUSTOM_55",
    SBA7A_CUSTOM_56 = "SBA7A_CUSTOM_56",
    SBA7A_CUSTOM_57 = "SBA7A_CUSTOM_57",
    SBA7A_CUSTOM_58 = "SBA7A_CUSTOM_58",
    SBA7A_CUSTOM_59 = "SBA7A_CUSTOM_59",
    SBA7A_CUSTOM_60 = "SBA7A_CUSTOM_60",
    SBA7A_CUSTOM_61 = "SBA7A_CUSTOM_61",
    SBA7A_CUSTOM_62 = "SBA7A_CUSTOM_62",
    SBA7A_CUSTOM_63 = "SBA7A_CUSTOM_63",
    SBA7A_CUSTOM_64 = "SBA7A_CUSTOM_64",
    SBA7A_CUSTOM_65 = "SBA7A_CUSTOM_65",
    SBA7A_CUSTOM_66 = "SBA7A_CUSTOM_66",
    SBA7A_CUSTOM_67 = "SBA7A_CUSTOM_67",
    SBA7A_CUSTOM_68 = "SBA7A_CUSTOM_68",
    SBA7A_CUSTOM_69 = "SBA7A_CUSTOM_69",
    SBA7A_CUSTOM_70 = "SBA7A_CUSTOM_70",
    SBA7A_CUSTOM_71 = "SBA7A_CUSTOM_71",
    SBA7A_CUSTOM_72 = "SBA7A_CUSTOM_72",
    SBA7A_CUSTOM_73 = "SBA7A_CUSTOM_73",
    SBA7A_CUSTOM_74 = "SBA7A_CUSTOM_74",
    SBA7A_CUSTOM_75 = "SBA7A_CUSTOM_75",
    SBA7A_CUSTOM_76 = "SBA7A_CUSTOM_76",
    SBA7A_CUSTOM_77 = "SBA7A_CUSTOM_77",
    SBA7A_CUSTOM_78 = "SBA7A_CUSTOM_78",
    SBA7A_CUSTOM_79 = "SBA7A_CUSTOM_79",
    SBA7A_CUSTOM_80 = "SBA7A_CUSTOM_80",
    SBA7A_CUSTOM_81 = "SBA7A_CUSTOM_81",
    SBA7A_CUSTOM_82 = "SBA7A_CUSTOM_82",
    SBA7A_CUSTOM_83 = "SBA7A_CUSTOM_83",
    SBA7A_CUSTOM_84 = "SBA7A_CUSTOM_84",
    SBA7A_CUSTOM_85 = "SBA7A_CUSTOM_85",
    SBA7A_CUSTOM_86 = "SBA7A_CUSTOM_86",
    SBA7A_CUSTOM_87 = "SBA7A_CUSTOM_87",
    SBA7A_CUSTOM_88 = "SBA7A_CUSTOM_88",
    SBA7A_CUSTOM_89 = "SBA7A_CUSTOM_89",
    SBA7A_CUSTOM_90 = "SBA7A_CUSTOM_90",
    SBA7A_CUSTOM_91 = "SBA7A_CUSTOM_91",
    SBA7A_CUSTOM_92 = "SBA7A_CUSTOM_92",
    SBA7A_CUSTOM_93 = "SBA7A_CUSTOM_93",
    SBA7A_CUSTOM_94 = "SBA7A_CUSTOM_94",
    SBA7A_CUSTOM_95 = "SBA7A_CUSTOM_95",
    SBA7A_CUSTOM_96 = "SBA7A_CUSTOM_96",
    SBA7A_CUSTOM_97 = "SBA7A_CUSTOM_97",
    SBA7A_CUSTOM_98 = "SBA7A_CUSTOM_98",
    SBA7A_CUSTOM_99 = "SBA7A_CUSTOM_99",
    SOURCES_USES = "SOURCES_USES",
}

export enum MessageType {
    DIGEST = "DIGEST",
}

export enum NamingStrategyType {
    NO_STRATEGY = "NO_STRATEGY",
    ENTITY_ADDRESS = "ENTITY_ADDRESS",
    ENTITY_NAME = "ENTITY_NAME",
    PREFIX_ENTITY_NAME = "PREFIX_ENTITY_NAME",
    PREFIX_ENTITY_ADDRESS = "PREFIX_ENTITY_ADDRESS",
    SUFFIX_ENTITY_NAME = "SUFFIX_ENTITY_NAME",
    SUFFIX_ENTITY_NAME_AND_DATE = "SUFFIX_ENTITY_NAME_AND_DATE",
}

export enum NullHandling {
    NATIVE = "NATIVE",
    NULLS_FIRST = "NULLS_FIRST",
    NULLS_LAST = "NULLS_LAST",
}

export enum PackageType {
    NONE = "NONE",
    LOAN = "LOAN",
    PERSON = "PERSON",
}

/**
 * Values:
 * - `Unset`
 * - `HIDDEN` - @deprecated
 * - `PRIVATE` - @deprecated
 * - `IGNORE`
 * - `BLOCKER`
 * - `LOW`
 * - `MEDIUM`
 * - `HIGH`
 * - `CRITICAL`
 * - `URGENT` - @deprecated
 */
export enum PriorityType {
    Unset = "Unset",
    /**
     * @deprecated
     */
    HIDDEN = "HIDDEN",
    /**
     * @deprecated
     */
    PRIVATE = "PRIVATE",
    IGNORE = "IGNORE",
    BLOCKER = "BLOCKER",
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
    CRITICAL = "CRITICAL",
    /**
     * @deprecated
     */
    URGENT = "URGENT",
}

export enum ProviderType {
    S3 = "S3",
    ONE_DRIVE = "ONE_DRIVE",
}

export enum Role {
    BORROWER = "BORROWER",
    LEAD_BORROWER = "LEAD_BORROWER",
    LENDER = "LENDER",
    LEAD_LENDER = "LEAD_LENDER",
    MANAGER_LENDER = "MANAGER_LENDER",
    UNDERWRITER_LENDER = "UNDERWRITER_LENDER",
    ASSOCIATE_LENDER = "ASSOCIATE_LENDER",
    CONTACT_BORROWER = "CONTACT_BORROWER",
    CONTACT_LENDER = "CONTACT_LENDER",
    KEY_CONTACT = "KEY_CONTACT",
}

export enum SearchTypeEnum {
    FORM_ELEMENT = "FORM_ELEMENT",
    LOAN = "LOAN",
    US_DRIVERS_LICENSE = "US_DRIVERS_LICENSE",
    PERSON = "PERSON",
}

/**
 * Values:
 * - `ASSET`
 * - `RE_ASSET` - @deprecated
 * - `GENERIC_RE_ASSET`
 * - `COMPANY`
 * - `TRUST`
 * - `INDIVIDUAL`
 * - `NEW_TITLED_EQUIPMENT_ASSET`
 * - `NEW_UNTITLED_EQUIPMENT_ASSET`
 * - `USED_TITLED_EQUIPMENT_ASSET`
 * - `USED_UNTITLED_EQUIPMENT_ASSET`
 * - `PURCHASE_COMMERCIAL_RE_ASSET`
 * - `REHAB_COMMERCIAL_RE_ASSET`
 * - `CONSTRUCTION_COMMERCIAL_RE_ASSET`
 * - `PURCHASE_RESIDENTIAL_RE_ASSET`
 * - `REHAB_RESIDENTIAL_RE_ASSET`
 * - `CONSTRUCTION_RESIDENTIAL_RE_ASSET`
 * - `STOCK_ACCOUNT_ASSET`
 * - `BOND_ACCOUNT_ASSET`
 * - `SAVINGS_ACCOUNT_ASSET`
 * - `INVENTORY_OTHER_ASSET`
 * - `AG_OTHER_ASSET`
 * - `ACCOUNT_RECEIVABLE_OTHER_ASSET`
 */
export enum SherpaEntityType {
    ASSET = "ASSET",
    /**
     * @deprecated
     */
    RE_ASSET = "RE_ASSET",
    GENERIC_RE_ASSET = "GENERIC_RE_ASSET",
    COMPANY = "COMPANY",
    TRUST = "TRUST",
    INDIVIDUAL = "INDIVIDUAL",
    NEW_TITLED_EQUIPMENT_ASSET = "NEW_TITLED_EQUIPMENT_ASSET",
    NEW_UNTITLED_EQUIPMENT_ASSET = "NEW_UNTITLED_EQUIPMENT_ASSET",
    USED_TITLED_EQUIPMENT_ASSET = "USED_TITLED_EQUIPMENT_ASSET",
    USED_UNTITLED_EQUIPMENT_ASSET = "USED_UNTITLED_EQUIPMENT_ASSET",
    PURCHASE_COMMERCIAL_RE_ASSET = "PURCHASE_COMMERCIAL_RE_ASSET",
    REHAB_COMMERCIAL_RE_ASSET = "REHAB_COMMERCIAL_RE_ASSET",
    CONSTRUCTION_COMMERCIAL_RE_ASSET = "CONSTRUCTION_COMMERCIAL_RE_ASSET",
    PURCHASE_RESIDENTIAL_RE_ASSET = "PURCHASE_RESIDENTIAL_RE_ASSET",
    REHAB_RESIDENTIAL_RE_ASSET = "REHAB_RESIDENTIAL_RE_ASSET",
    CONSTRUCTION_RESIDENTIAL_RE_ASSET = "CONSTRUCTION_RESIDENTIAL_RE_ASSET",
    STOCK_ACCOUNT_ASSET = "STOCK_ACCOUNT_ASSET",
    BOND_ACCOUNT_ASSET = "BOND_ACCOUNT_ASSET",
    SAVINGS_ACCOUNT_ASSET = "SAVINGS_ACCOUNT_ASSET",
    INVENTORY_OTHER_ASSET = "INVENTORY_OTHER_ASSET",
    AG_OTHER_ASSET = "AG_OTHER_ASSET",
    ACCOUNT_RECEIVABLE_OTHER_ASSET = "ACCOUNT_RECEIVABLE_OTHER_ASSET",
}

export enum StorageType {
    FILE = "FILE",
    FOLDER = "FOLDER",
}

export enum SubRole {
    DEFAULT = "DEFAULT",
    VENDOR = "VENDOR",
    FRANCHISOR = "FRANCHISOR",
    LANDLORD = "LANDLORD",
    REAL_ESTATE_AGENT = "REAL_ESTATE_AGENT",
    TITLE_AGENT = "TITLE_AGENT",
    INSURANCE_AGENT = "INSURANCE_AGENT",
    ENVIRONMENTAL_AGENT = "ENVIRONMENTAL_AGENT",
    APPRAISER = "APPRAISER",
    LAWYER = "LAWYER",
    PROPERTY_INSPECTOR = "PROPERTY_INSPECTOR",
    PROPERTY_MANAGER = "PROPERTY_MANAGER",
    CONTRACTOR = "CONTRACTOR",
    TENAT = "TENAT",
    SPECIAL_USER = "SPECIAL_USER",
}

export enum TaskType {
    assign_signee = "assign_signee",
    fill = "fill",
    sign = "sign",
}

export enum UnitedStates {
    AL = "AL",
    AK = "AK",
    AZ = "AZ",
    AR = "AR",
    CA = "CA",
    CO = "CO",
    CT = "CT",
    DE = "DE",
    DC = "DC",
    FL = "FL",
    GA = "GA",
    HI = "HI",
    ID = "ID",
    IL = "IL",
    IN = "IN",
    IA = "IA",
    KS = "KS",
    KY = "KY",
    LA = "LA",
    ME = "ME",
    MD = "MD",
    MA = "MA",
    MI = "MI",
    MN = "MN",
    MS = "MS",
    MO = "MO",
    MT = "MT",
    NE = "NE",
    NV = "NV",
    NH = "NH",
    NJ = "NJ",
    NM = "NM",
    NY = "NY",
    NC = "NC",
    ND = "ND",
    OH = "OH",
    OK = "OK",
    OR = "OR",
    PA = "PA",
    RI = "RI",
    SC = "SC",
    SD = "SD",
    TN = "TN",
    TX = "TX",
    UT = "UT",
    VT = "VT",
    VA = "VA",
    WA = "WA",
    WV = "WV",
    WI = "WI",
    WY = "WY",
    PR = "PR",
}

export enum VentureExportStatus {
    NOT_EXPORTED = "NOT_EXPORTED",
    EXPORTING = "EXPORTING",
    EXPORT_INCOMPLETE = "EXPORT_INCOMPLETE",
    EXPORT_COMPLETE = "EXPORT_COMPLETE",
    EXPORT_ERROR = "EXPORT_ERROR",
}

export enum VenturesDocType {
    A1098_STATEMENTS = "A1098_STATEMENTS",
    A12_MONTH_PROJECTION_OF_INCOME_AND_EXPENSES = "A12_MONTH_PROJECTION_OF_INCOME_AND_EXPENSES",
    A327_PLUS_BACKUP_DOCUMENTATION = "A327_PLUS_BACKUP_DOCUMENTATION",
    A504_DEBENTURE_CLOSING_CHECKLIST = "A504_DEBENTURE_CLOSING_CHECKLIST",
    A504_LOAN_CLOSING_ACKNOWLEDGEMENT_AND_CONSENT = "A504_LOAN_CLOSING_ACKNOWLEDGEMENT_AND_CONSENT",
    ACH_AUTH_AND_VOIDED_CHECK = "ACH_AUTH_AND_VOIDED_CHECK",
    AGREEMENT_REGARDING_ATTORNEY_FEES = "AGREEMENT_REGARDING_ATTORNEY_FEES",
    ALL_CREDIT_REPORTS_EXPLANATIONS = "ALL_CREDIT_REPORTS_EXPLANATIONS",
    AMORTIZATION_AND_PREPAY_SCHEDULES = "AMORTIZATION_AND_PREPAY_SCHEDULES",
    ANNUAL_FS_REVIEW = "ANNUAL_FS_REVIEW",
    AP_AR_AGINGS = "AP_AR_AGINGS",
    APPRAISAL_FOR_PROPERTY_CHECKLIST_SBA_APP = "APPRAISAL_FOR_PROPERTY_CHECKLIST_SBA_APP",
    ASSIGNMENT_OF_DEED_OF_TRUST = "ASSIGNMENT_OF_DEED_OF_TRUST",
    ATTORNEYS_REVIEW_CHECKLIST = "ATTORNEYS_REVIEW_CHECKLIST",
    BANKRUPTCY = "BANKRUPTCY",
    BORROWER_AND_OP_CO_CERT = "BORROWER_AND_OP_CO_CERT",
    BORROWERS_TENANT_CHECKS = "BORROWERS_TENANT_CHECKS",
    BUSINESS_DEBT_SCHEDULE = "BUSINESS_DEBT_SCHEDULE",
    BUSINESS_FEDERAL_TAX_RETURN_2012_2011 = "BUSINESS_FEDERAL_TAX_RETURN_2012_2011",
    BUSINESS_FEDERAL_TAX_RETURN_2013 = "BUSINESS_FEDERAL_TAX_RETURN_2013",
    BUSINESS_FEDERAL_TAX_RETURN_2014 = "BUSINESS_FEDERAL_TAX_RETURN_2014",
    BUSINESS_FEDERAL_TAX_RETURN_ARCHIVE = "BUSINESS_FEDERAL_TAX_RETURN_ARCHIVE",
    BUSINESS_INTERIM_FINANCIAL_STATEMENT = "BUSINESS_INTERIM_FINANCIAL_STATEMENT",
    BUSINESS_PROFESSIONAL_LICENSES = "BUSINESS_PROFESSIONAL_LICENSES",
    CDC_APPLICATION = "CDC_APPLICATION",
    CDC_BOARD_RESOLUTION_SBA_FORM_1528 = "CDC_BOARD_RESOLUTION_SBA_FORM_1528",
    CDC_CERTIFICATION_SBA_FORM_2101 = "CDC_CERTIFICATION_SBA_FORM_2101",
    CDC_DEED_OF_TRUST = "CDC_DEED_OF_TRUST",
    CERTIFICATE_OF_FUTURE_OCCUPANCY = "CERTIFICATE_OF_FUTURE_OCCUPANCY",
    COLPURS_REO = "COLPURS_REO",
    COMPENSATION_AGREEMENT_SBA_FORM_159 = "COMPENSATION_AGREEMENT_SBA_FORM_159",
    CONSTRUCTION_BIDS = "CONSTRUCTION_BIDS",
    CONSTRUCTION_DISBURS_ITEMIZATION_NOC_COFO = "CONSTRUCTION_DISBURS_ITEMIZATION_NOC_COFO",
    CORPS_ARTCLS_BYLAWS_STOCKS_CERTOFSECY_RES = "CORPS_ARTCLS_BYLAWS_STOCKS_CERTOFSECY_RES",
    CORRESPONDENCE = "CORRESPONDENCE",
    CORRESPONDENCE_EMAILS = "CORRESPONDENCE_EMAILS",
    CURRENT_PROPOSED_LEASE = "CURRENT_PROPOSED_LEASE",
    DEVELOPMENT_COMPANY_DEB_SBA_FORM_1504 = "DEVELOPMENT_COMPANY_DEB_SBA_FORM_1504",
    ELIGIBILITY_QUESTIONNAIRE = "ELIGIBILITY_QUESTIONNAIRE",
    ENVIRO_TSA_PH1_QSTNRE_CHCKLST_SBA_APP = "ENVIRO_TSA_PH1_QSTNRE_CHCKLST_SBA_APP",
    FEE_DISCLOSURE_STMT_BORROWERS_SGNTR_CERT = "FEE_DISCLOSURE_STMT_BORROWERS_SGNTR_CERT",
    FLOOD_INSURANCE_FEMA_FORM = "FLOOD_INSURANCE_FEMA_FORM",
    FRANCHISE_AGRMNT_FTC_DISCLOSURE = "FRANCHISE_AGRMNT_FTC_DISCLOSURE",
    HAZARD_INSURANCE = "HAZARD_INSURANCE",
    HISTORY_OF_BUSINESS = "HISTORY_OF_BUSINESS",
    INJECTION_CERT_FINAL_CLSNG_STMNT_AND_CERT = "INJECTION_CERT_FINAL_CLSNG_STMNT_AND_CERT",
    INTERIM_LENDER_CERTIFICATION_SBA_FORM_2288 = "INTERIM_LENDER_CERTIFICATION_SBA_FORM_2288",
    INTERIM_LENDER_NOTE_AND_DEED_OF_TRUST = "INTERIM_LENDER_NOTE_AND_DEED_OF_TRUST",
    IRS_W_9_FORM = "IRS_W_9_FORM",
    LEASES_EPC_OC_TENANT_CERTS_ASSIGNMENTS = "LEASES_EPC_OC_TENANT_CERTS_ASSIGNMENTS",
    LEGAL_MISC = "LEGAL_MISC",
    LEGAL_MISC_FILE_CHECKLIST = "LEGAL_MISC_FILE_CHECKLIST",
    LENDER_COMMITMENT_LETTER_LENDER_CREDIT_MEMO = "LENDER_COMMITMENT_LETTER_LENDER_CREDIT_MEMO",
    LIQUIDATION = "LIQUIDATION",
    LIQUIDATIONS = "LIQUIDATIONS",
    LIST_OF_EQUIP_TO_BE_ACQUIRED_WITH_PROCEEDS = "LIST_OF_EQUIP_TO_BE_ACQUIRED_WITH_PROCEEDS",
    LLC__BRRWNG_GTY_RES_ARTCLS_OF_ORG_OP_AGRMENT = "LLC__BRRWNG_GTY_RES_ARTCLS_OF_ORG_OP_AGRMENT",
    LLP_ARTICLES_AGREEMENT_AND_RESOLUTION = "LLP_ARTICLES_AGREEMENT_AND_RESOLUTION",
    LOAN_AUTHORIZATION_327_ACTION_LETTERS = "LOAN_AUTHORIZATION_327_ACTION_LETTERS",
    LOAN_MODIFICATION = "LOAN_MODIFICATION",
    NAC_FOR_FUNDING_INCL_SPREADS = "NAC_FOR_FUNDING_INCL_SPREADS",
    OPINION_OF_CDC_COUNSEL = "OPINION_OF_CDC_COUNSEL",
    OTHER_MISC_CLOSING = "OTHER_MISC_CLOSING",
    PARTICIPATION_FEE = "PARTICIPATION_FEE",
    PARTNERSHIPS_AGREEMENT_AND_RESOLUTIONS = "PARTNERSHIPS_AGREEMENT_AND_RESOLUTIONS",
    PERSONAL_FEDERAL_INCOME_TAX_RETURN = "PERSONAL_FEDERAL_INCOME_TAX_RETURN",
    PERSONAL_FINANCIAL_STATEMENT = "PERSONAL_FINANCIAL_STATEMENT",
    PERSONAL_FINANCIAL_STATEMENT_SBA_FORM_413 = "PERSONAL_FINANCIAL_STATEMENT_SBA_FORM_413",
    POST_FUNDING_INVOICES = "POST_FUNDING_INVOICES",
    PRE_CLOSING_REVIEW_AUDIT = "PRE_CLOSING_REVIEW_AUDIT",
    PREPAYS_AND_PAYOFFS = "PREPAYS_AND_PAYOFFS",
    PREVIOUS_GOVT_FINANCING_DEBARMENT = "PREVIOUS_GOVT_FINANCING_DEBARMENT",
    PROMISSORY_NOTE = "PROMISSORY_NOTE",
    PROPERTY_TAXES = "PROPERTY_TAXES",
    PURCHASE_AGRMNT_ESCROW_INST_LTR_TO_ESCROW = "PURCHASE_AGRMNT_ESCROW_INST_LTR_TO_ESCROW",
    RECONVEYANCES = "RECONVEYANCES",
    REFI = "REFI",
    REQUEST_FOR_NOTICE = "REQUEST_FOR_NOTICE",
    SBA_912_NAME_AFFDVT_RESUME_CIP = "SBA_912_NAME_AFFDVT_RESUME_CIP",
    SBA_ACTIONS = "SBA_ACTIONS",
    SBA_FORM_912 = "SBA_FORM_912",
    SCREENOUTS_LOAN_NUMBER = "SCREENOUTS_LOAN_NUMBER",
    SECONDARY_COLLATERAL_SEE_SBA_CHKLST = "SECONDARY_COLLATERAL_SEE_SBA_CHKLST",
    SERVICING_AGENT_AGREEMENT_SBA_FORM_1506 = "SERVICING_AGENT_AGREEMENT_SBA_FORM_1506",
    SITE_INSPECTION = "SITE_INSPECTION",
    SLPC_CREDIT_MEMO = "SLPC_CREDIT_MEMO",
    SOLEPROP_FBNS = "SOLEPROP_FBNS",
    STANDBY_AGREEMENTS_SBA_FORM_155 = "STANDBY_AGREEMENTS_SBA_FORM_155",
    SUPPLEMENTAL = "SUPPLEMENTAL",
    TAX_TRANSCRIPTS_BUSINESS = "TAX_TRANSCRIPTS_BUSINESS",
    TAX_TRANSCRIPTS_PERSONAL = "TAX_TRANSCRIPTS_PERSONAL",
    THIRD_PARTY_LENDER_AGREEMENT_SBA_FORM_2287 = "THIRD_PARTY_LENDER_AGREEMENT_SBA_FORM_2287",
    THIRD_PARTY_LENDER_NOTE_AND_DEED_OF_TRUST = "THIRD_PARTY_LENDER_NOTE_AND_DEED_OF_TRUST",
    TITLE_AND_ALTA_AND_PRELIMS = "TITLE_AND_ALTA_AND_PRELIMS",
    TRUST_AGREEMENT_AND_CERT = "TRUST_AGREEMENT_AND_CERT",
    UCC = "UCC",
    UNCONDITIONAL_GUARANTEE_SBA_FORM_148_148L = "UNCONDITIONAL_GUARANTEE_SBA_FORM_148_148L",
    USCIS_FORMS_G_845_ALIEN_REG_CARD = "USCIS_FORMS_G_845_ALIEN_REG_CARD",
    WIRING_INSTRUCTIONS = "WIRING_INSTRUCTIONS",
    YEAR_END_FINANCIAL_STATEMENT = "YEAR_END_FINANCIAL_STATEMENT",
}

export enum ViewType {
    PRINCIPAL = "PRINCIPAL",
    LENDER = "LENDER",
    ANONYMOUS = "ANONYMOUS",
    CONTACT = "CONTACT",
}

export enum WorflowAgentRole {
    FILLER = "FILLER",
    SIGNER = "SIGNER",
    PAYER = "PAYER",
}

export enum WorkflowPhaseAgentStatus {
    TODO = "TODO",
    DONE = "DONE",
}

export enum WorkflowPhaseStatus {
    WAITING_PREVIOUS_PHASE = "WAITING_PREVIOUS_PHASE",
    TODO = "TODO",
    DONE = "DONE",
}

export enum WorkflowPhaseType {
    FILL = "FILL",
    SIGN = "SIGN",
    PAY = "PAY",
}

export enum WorkflowType {
    PAYMENT = "PAYMENT",
}

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}
