import { Box } from '@mui/material';
import { toast as reactToast } from 'react-toastify';

export type TToastType = 'info' | 'success' | 'warning' | 'error' | 'default';

type TToast = {
    position?: 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left',
    content: string,
    duration?: number,
    toastId?: string,
    type?: TToastType,
    onClick?: (event: React.MouseEvent) => void,
};

export const toast = ({
    position = 'bottom-right',
    content,
    onClick,
    toastId,
    duration = 5000,
    type = 'default' }: TToast) => reactToast(<Box sx={{
        '& a': {
            color: 'primary.main',
            textDecoration: 'underline',
            '&:hover': {
                color: 'primary.dark',
                textDecoration: 'none',
            },
        }
    }} dangerouslySetInnerHTML={{ __html: content }} />,
        {
            position,
            toastId,
            autoClose: duration,
            onClick: onClick,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            type
        });