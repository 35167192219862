// function gets image url as parameter and returns base64 string
export const imageUrlToBase64 = (imageUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width * 4;
            canvas.height = image.height * 4;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, image.width * 4, image.height * 4);
            try {
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            } catch (error) {
                resolve(imageUrl);
            }
        }
        image.onerror = () => {
            reject(imageUrl);
        }
    }
    )
}