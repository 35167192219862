import { LoanRole, LoanTableColumn } from "src/types/loan";

import { LoanPhaseCategoryType, Role } from "../backend";

export const CardStatus = {
    PROCESSING: 'PROCESSING',
    PROCESSED: 'PROCESSED',
    ACCEPTED: 'ACCEPTED',
    NOT_ACCEPTED: "NOT_ACCEPTED",
    PROCESSED_INCOMPLETE: 'PROCESSED_INCOMPLETE',
    PROCESSED_ERROR: 'PROCESSED_ERROR',
} as const;

export const LoanStatus = {
    APPLYING: "APPLYING",
    ANALYZING: "ANALYZING",
    CONDITIONING: "CONDITIONING",
    CLOSING: "CLOSING",
    ARCHIVED: "ARCHIVED",
    SERVICING: "SERVICING",
} as const;

export const CardType = {
    LOAN_SUMMARY: 'LOAN_SUMMARY',
    LOAN_SUMMARIES: 'LOAN_SUMMARIES',
    ONBOARDING: 'ONBOARDING',
    CONGRATULATIONS: 'CONGRATULATIONS',
    PROCESSED: 'PROCESSED',
    MESSAGES: 'MESSAGES',
    DRIVERS_LICENSE: 'DRIVERS_LICENSE',
    DEMOGRAPHICS: 'DEMOGRAPHICS',
    SOFT_CREDIT_PULL: 'SOFT_CREDIT_PULL',
    IRS_4506C: 'IRS_4506C',
} as const;

export const LoanTemplateType = {
    Full: 'FULL',
    Fragment: 'BLOCK',
} as const;

export const LoanFragmentType = {
    Asset: 'ASSET',
    Entity: 'ENTITY',
} as const;

export const RE_LOAN_TYPES = [
    "CRE_BRIDGE",
    "CRE_PURCHASE",
    "CRE_A_AND_D",
    "CRE_CONSTRUCTION",
    "FIX_AND_FLIP",
    "SBA504_CRE_PURCHASE",
    "SBA504_CRE_CONSTRUCTION",
    "SIERRA_WEST",
    "SBA504_CRE_CONSTRUCTION",
    "RESIDENTIAL_CONSTRUCTION",
    "RESIDENTIAL_BRIDGE",
    "CRE_REHAB"
] as const;

export const EntityType = {
    Asset: 'ASSET',
    RealEstateAsset: 'RE_ASSET',
    Company: 'COMPANY',
    Trust: 'TRUST',
    Individual: 'INDIVIDUAL',
} as const;

export const UNSAVED_LOAN_ID = "unsavedLoan";

export const QUERY_PARAM_COPY_FROM_LOAN_ID = 'copyFromLoanId';

export const QUERY_PARAM_PHASE_CATEGORY = 'phaseCategory';

export const BLANK_LOAN_TEMPLATE_ID = 'blankLoanTemplateId';
export const RoleType: { [key: string]: Role } = {
    Borrower: Role.BORROWER,
    LeadBorrower: Role.LEAD_BORROWER,
    Lender: Role.LENDER,
    LeadLender: Role.LEAD_LENDER,
    BORROWER: Role.BORROWER,
    LEAD_BORROWER: Role.LEAD_BORROWER,
    LENDER: Role.LENDER,
    LEAD_LENDER: Role.LEAD_LENDER,
    AssociateLender: Role.ASSOCIATE_LENDER,
    Manager: Role.MANAGER_LENDER,
    Underwriter: Role.UNDERWRITER_LENDER,
    ContactBorrower: Role.CONTACT_BORROWER,
    ContactLender: Role.CONTACT_LENDER,
    KeyContact: Role.KEY_CONTACT,
} as const;

export const SubRoleType = {
    "Default": "DEFAULT",
    "Vendor": "VENDOR",
    "Franchisor": "FRANCHISOR",
    "LandLord": "LANDLORD",
    "RalEstateAgent": "REAL_ESTATE_AGENT",
    "TitleAgent": "TITLE_AGENT",
    "InsuranceAgent": "INSURANCE_AGENT",
    "EnvironmentalAgent": "ENVIRONMENTAL_AGENT",
    "Appraiser": "APPRAISER",
    "Lawyer": "LAWYER",
    "PropertyInspector": "PROPERTY_INSPECTOR",
    "PropertyManager": "PROPERTY_MANAGER",
    "Contractor": "CONTRACTOR",
    "Tenant": "TENAT",
    "SpecialUser": "SPECIAL_USER"

}

export const RoleTypeLabel = (roleType: Role, loanRoles?: LoanRole[]): string => {
    const multiRole = !!loanRoles && isLeaderRole(roleType) && loanRoles.filter(loanRole => loanRole.role === roleType).length > 1;

    if (multiRole) return 'Co-Lead';

    // if roleType is not defined, return empty string
    if (!roleType) return '';

    const label = ({
        [RoleType.Borrower]: 'Borrower',
        [RoleType.LeadBorrower]: 'Lead',
        [RoleType.Lender]: 'Lender',
        [RoleType.AssociateLender]: 'Associate',
        [RoleType.LeadLender]: 'Lead',
        [RoleType.Manager]: 'Manager',
        [RoleType.Underwriter]: 'Underwriter',
        [RoleType.ContactBorrower]: 'Contact',
        [RoleType.ContactLender]: 'Contact',
        [RoleType.KeyContact]: 'Key Contact',
    }[roleType])

    // if we don't have a label, return the roleType
    if (!label) return roleType;

    return label;
};

const isLeaderRole = (roleType: Role) => {
    return roleType === RoleType.LeadBorrower || roleType === RoleType.LeadLender;
}
export const LoanStageCategory = LoanPhaseCategoryType;

export const LoansKanbanFilter = {
    LENDER_LOANS: 'LENDER_LOANS',
    COMPANY_LOANS: 'COMPANY_LOANS',
    PINNED: 'PINNED',
    UNPINNED: 'UNPINNED',
    TEAMMATES: 'TEAMMATES'
} as const;

export enum DownPaymentSource {
    Unset = 'Select',
    CASH = 'Cash',
    HELOC = 'HELOC',
    LAND_EQUITY = 'Land Equity',
    SECOND_MORTGAGE = '2nd Mortgage',
    INVESTOR = 'Investor',
    OTHER = 'Other',
};

export const LoanListViewColumns: LoanTableColumn[] = [
    { id: 'loanID', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Loan ID' },
    { id: 'projectName', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Project Name' },
    { id: 'purchasePrice', value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Purchase Price' },
    { id: 'projectCosts', value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Project Costs' },
    { id: 'totalCosts', value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Total Costs' },
    // { id: 'applicants', value: '', sortable: false, numeric: false, formattedValue: '', label: 'Applicants' },
    // { id: 'downPayment', value: '', sortable: true, numeric: true, formattedValue: '', label: 'Down Payment' },
    // { id: 'loanAmount', value: '', sortable: true, numeric: true, formattedValue: '', label: 'Requested Loan Amount' },
    { id: 'pmla', value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Loan amount' },
    { id: 'ltv', value: '', sortable: true, numeric: false, align: 'right', formattedValue: '', label: 'LTV' },
    { id: 'ltc', value: '', sortable: true, numeric: false, align: 'right', formattedValue: '', label: 'LTC' },
    { id: 'apr', value: '', sortable: true, numeric: false, align: 'right', formattedValue: '', label: 'APR' },
    // { id: 'loanTermInYears', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Amortization (Y)' },
    { id: 'closeDate', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Close Date' },
    { id: 'dateCreated', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Date Created' },
    { id: 'loanType', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Loan Type' },
    { id: 'actualLoanTerm', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Term' },
    { id: 'loanStatus', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Loan Status' },
    { id: 'applicants', value: '', sortable: false, numeric: false, formattedValue: '', label: 'Applicants' },
    { id: 'lenders', value: '', sortable: false, numeric: false, formattedValue: '', label: 'Lenders' },
    { id: 'downPayment', value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Down Payment' },
    { id: 'actualLoanAmount', value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Actual Loan Amount' },
    { id: 'amortization', value: '', sortable: true, numeric: false, formattedValue: '', label: 'Amortization' },
    { id: 'loanProgress', value: '', sortable: false, numeric: false, formattedValue: '', label: 'Loan Progress' },
]

export const LoanListViewFixedColumns = [
    {
        id: 'projectName',
    },
    {
        id: 'loanID',
    },
    {
        id: 'closeDate',
    },
];

export const LoanListViewColumnsDefaultOrder = LoanListViewColumns.map(column => column.id);

export const LOAN_VIEW_TYPE_QUERY_PARAM = 'loanViewType';

export const MAX_LOAN_TITLE_LENGTH = 32;


export const DEFAULT_LOANS_PER_PAGE = 5;
