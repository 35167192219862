import axios from 'axios';
import { parseISO } from 'date-fns';
import { FileRejection } from "react-dropzone";
import { AppUserDTO2, FormElementV2ResponseDto, KnowledgeBase as IKnowledgeBase, ViewType } from 'src/backend';
import { KnowledgeBase, Type } from "src/constants/form-element";
import { LENDER_VIEW, PRINCIPAL_VIEW } from "src/constants/person";
import { DEFAULT_TOAST_DURATION, MAX_TOAST_DURATION, POLLING_INTERVAL } from "src/constants/ui";
import { MysherpasCalendarEvent } from "src/types/calendar";
import { TKnowledgeBase } from 'src/types/formelement';
import type { Borrower, FormElement } from "src/types/view";

import { countWords } from "./count-words";
import { getFileExtension } from './get-file-extension';
import { isAFile } from './is-a-file';
import { toast } from "./toast";

export const isDevelopmentMode = process.env.NODE_ENV === 'development';

export const isServer = typeof window === 'undefined'

// is current domain localhost
export const isLocalhost = typeof window !== 'undefined' && window.location.hostname === 'localhost';

// recursively find element by knowledgeBase
export const findElementByKnowledgeBase = (knowledgeBase: TKnowledgeBase, elements: FormElement[]): FormElement | undefined => {
    for (const element of elements) {
        if (element.knowledgeBase === knowledgeBase) {
            return element;
        }
        if (element.storageType === Type.SECTION) {
            const found = findElementByKnowledgeBase(knowledgeBase, element.children);
            if (found) {
                return found;
            }
        }
    }
    return undefined;
};

// recursively find all from element with knowledgebase of type SHOE_BOX
export const findAllShoeBoxes = (rootElement: FormElement, includeRoot: boolean = false) => {
    const showBoxes: FormElement[] = [];
    if (typeof rootElement === 'undefined' || typeof rootElement?.children === 'undefined') {
        return showBoxes;
    }
    // TODO improve this
    if (includeRoot && rootElement?.knowledgeBase === KnowledgeBase.SHOE_BOX) {
        // @ts-ignore
        showBoxes.push({ ...rootElement, parent: { ...rootElement.parent, title: rootElement.title } });
    }
    rootElement?.children.forEach(formElement => {
        if (formElement.knowledgeBase === KnowledgeBase.SHOE_BOX) {
            // @ts-ignore
            showBoxes.push({ ...formElement, parent: { ...formElement.parent, title: rootElement.title } });
        }
        showBoxes.push(...findAllShoeBoxes(formElement));
    });
    return showBoxes;
};

// recursively find nested form element section by id
export const findSectionById = (id: string, rootSection: FormElement, allowGetFile: boolean = false, disallowShoeBox: boolean = false): FormElement | undefined => {
    if (!id || !rootSection || !rootSection.children) return rootSection;

    for (const section of rootSection.children) {
        if (section.id === id && (section.storageType === Type.SECTION || allowGetFile)) {
            if (section.knowledgeBase === KnowledgeBase.SHOE_BOX && disallowShoeBox) {
                return rootSection;
            }
            return section
        }
        if (section.storageType === Type.SECTION) {
            const nestedSection = findSectionById(id, section, allowGetFile);
            if (nestedSection && nestedSection.id === id || nestedSection.children.findIndex(file => file.id === id && file.storageType === Type.FILE) !== -1) {
                if (nestedSection.knowledgeBase === KnowledgeBase.SHOE_BOX && disallowShoeBox) {
                    return section;
                }
                return nestedSection
            }
        }
    }
    return rootSection
}

// recursively find nested form element by knowledgeBase and id and assignedToUser filters
export const findPrincipalFormElement = (rootElement: FormElement, assignedToUserId?: string, knowledgeBase?: string): FormElement | undefined => {
    if (!rootElement || !rootElement.children) return rootElement;

    if (!!rootElement.assignedToUser && ((rootElement.assignedToUser.id === assignedToUserId) || !assignedToUserId) && (!knowledgeBase || rootElement.knowledgeBase === knowledgeBase)) {
        return rootElement;
    }

    for (const element of rootElement.children) {
        if (!!element.assignedToUser && ((element.assignedToUser.id === assignedToUserId) || !assignedToUserId) && (!knowledgeBase || element.knowledgeBase === knowledgeBase)) {
            return element
        }
        if (element.storageType === Type.SECTION) {
            const nestedElement = findPrincipalFormElement(element, assignedToUserId, knowledgeBase)
            if (nestedElement && !!nestedElement.assignedToUser && ((nestedElement.assignedToUser.id === assignedToUserId) || !assignedToUserId) && (!knowledgeBase || nestedElement.knowledgeBase === knowledgeBase)) {
                return nestedElement
            }
        }
    }
    return null;
}

// recursively find nested form element by field value and id and assignedToUser filters
export const findFormElementByFieldValue = (rootElement: FormElement, fieldName: string, fieldValue: string): FormElement | undefined => {
    if (!rootElement || !rootElement.children) return rootElement;

    if (!!rootElement.assignedToUser && (!fieldValue || rootElement[fieldName] === fieldValue)) {
        return rootElement
    }

    for (const element of rootElement.children) {
        if (!!element.assignedToUser && (!fieldValue || element[fieldName]?.trim().toLowerCase() === fieldValue.trim().toLowerCase())) {
            return element
        }
        if (element.storageType === Type.SECTION) {
            const nestedElement = findFormElementByFieldValue(element, fieldName, fieldValue)
            if (nestedElement && !!nestedElement.assignedToUser && (!fieldValue || nestedElement[fieldName]?.trim().toLocaleString() === fieldValue.trim().toLocaleString())) {
                return nestedElement
            }
        }
    }
    return null;
}

// recursively find all nested form elements by knowledgeBase and assignedToUser filters as a flat array
export const findAllPrincipalFormElements = (rootElement: FormElement, assignedToUser?: Borrower, knowledgeBase?: string): FormElement[] => {
    if (!rootElement || !rootElement.children) return [];
    const elements: FormElement[] = [];
    for (const element of rootElement.children) {
        if (element.storageType === Type.SECTION && !!element.assignedToUser && (element.assignedToUser.id === assignedToUser.id) && (!knowledgeBase || element.knowledgeBase === knowledgeBase)) {
            elements.push(element)
        }
        if (element.storageType === Type.SECTION) {
            const nestedElements = findAllPrincipalFormElements(element, assignedToUser, knowledgeBase)
            if (nestedElements.length > 0) {
                elements.push(...nestedElements)
            }
        }
    }
    return elements;
}


// find the first FormElement which has any children of type FILE or more than one child of type SECTION recursively
export const findFirstFileOrSection = (formElement: FormElement, viewType: ViewType): FormElement | undefined => {
    if (typeof formElement?.children === 'undefined') return formElement;
    if (formElement.storageType === Type.FILE ||
        (formElement.storageType === Type.SECTION
            && ((formElement.children.filter(child => (viewType === LENDER_VIEW) || (viewType === PRINCIPAL_VIEW && (child.storageType === Type.FILE))).length >= 1)
                || (viewType === PRINCIPAL_VIEW && formElement.storageType === Type.SECTION && formElement.children.filter(child => child.storageType === Type.SECTION).length > 1)
            )
        )) {
        return { ...formElement, parent: null }
    } else {
        for (const child of formElement.children) {
            const found = findFirstFileOrSection(child, viewType);
            if (found) {
                return { ...found, parent: null }
            }
        }
    }

    return formElement;
}

// return array of unique assignedToUser from a formElement recursively
export const getAssignedToUsers = (formElement: FormElement): AppUserDTO2[] => {
    const assignedToUsers: AppUserDTO2[] = [];

    if (typeof formElement?.children === 'undefined') return assignedToUsers;
    if (formElement.storageType === Type.SECTION) {
        for (const child of formElement.children) {
            if (child.storageType === Type.SECTION) {
                assignedToUsers.push(...getAssignedToUsers(child))
            } else if (child.storageType === Type.FILE && assignedToUsers.findIndex(user => user.id === child.assignedToUser.id) === -1) {
                assignedToUsers.push(child.assignedToUser)
            }
        }
    } else if (formElement.storageType === Type.FILE && assignedToUsers.findIndex(user => user.id === formElement.assignedToUser.id) === -1) {
        assignedToUsers.push(formElement.assignedToUser);

    }
    return assignedToUsers.reduce((unique, item) => unique.some(user => user.id === item.id) ? unique : [...unique, item], [])
}

// get total of unapproved files in a formElement recursively
export const getTotalUnApprovedFiles = (formElement: FormElement): number => {
    let total = 0;

    if (typeof formElement?.children === 'undefined') return total;
    if (formElement.storageType === Type.SECTION) {
        for (const child of formElement.children) {
            total += getTotalUnApprovedFiles(child);
        }
    }
    if (formElement.storageType === Type.FILE && !formElement.approvedByUser?.id) {
        total++;
    }
    return total;
}

// get total of unapproved files in a formElement recursively
export const getTotalUnAnsweredFiles = (formElement: FormElement): number => {
    let total = 0;

    if (typeof formElement?.children === 'undefined') return total;
    if (formElement.storageType === Type.SECTION) {
        for (const child of formElement.children) {
            total += getTotalUnAnsweredFiles(child);
        }
    }
    if (formElement.storageType === Type.FILE && !formElement.answer?.id) {
        total++;
    }
    return total;
}

// get FormElements assigned tp user AssignedToUser
export const getAssignedToUserFormElements = (formElement: FormElement, assignedToUser: AppUserDTO2): FormElement[] => {
    const assignedToUserFormElements: FormElement[] = [];

    if (typeof formElement?.children === 'undefined') return assignedToUserFormElements;
    if (formElement.storageType === Type.SECTION) {
        for (const child of formElement.children) {
            if (child.storageType === Type.SECTION) {
                assignedToUserFormElements.push(...getAssignedToUserFormElements(child, assignedToUser))
            } else if (child.storageType === Type.FILE && child.assignedToUser.id === assignedToUser.id) {
                assignedToUserFormElements.push(child)
            }
        }
    } else if (formElement.storageType === Type.FILE && formElement.assignedToUser.id === assignedToUser.id) {
        assignedToUserFormElements.push(formElement)
    }
    return assignedToUserFormElements;
}


// function to flatten form element sections and children into a single array recursively
export const flattenSections = (sections: FormElement[], flatSections: FormElement[] = []): FormElement[] => {
    for (const section of sections) {
        flatSections.push(section)
        // @ts-ignore
        flattenSections(section.children.map(childSection => ({ ...childSection, loanId: section.loanId })), flatSections)
    }
    return flatSections
}

// find a specific form element by id and return it with all it's parents as a flatt array
export const findSectionByIdAndItsAncestors = (id: string, formElements: FormElementV2ResponseDto[]): FormElementV2ResponseDto[] => {
    const formElement = formElements.find(formElement => formElement.id === id);
    if (!formElement) return [];
    if (!formElement.parentId) return [];
    // if form element is file only return parents
    if (formElement.storageType === Type.FILE) {
        return findSectionByIdAndItsAncestors(formElement.parentId, formElements)
    }
    return [formElement, ...findSectionByIdAndItsAncestors(formElement.parentId, formElements)]
}

export const findAllChildrenRecursively = (formElement: FormElementV2ResponseDto, formElements: FormElementV2ResponseDto[]): FormElementV2ResponseDto[] => {
    const children = formElements.filter(child => child.parentId === formElement.id);
    if (!children.length) return [];
    return [...children, ...children.flatMap(child => findAllChildrenRecursively(child, formElements))]
}

// recursively traverse a form elements list
// if we find files add them to the return array
// if we find a folder that's not a virtual folder knowledge base add it to the return array , and do not traverse it's children
// if we find a folder that's a virtual folder knowledge base traverse it's children recurse
export const findFirstNonVirtualFolderFormElementsRecursively = (formElements: FormElementV2ResponseDto[], allFormElements: FormElementV2ResponseDto[]): FormElementV2ResponseDto[] => {
    const result: FormElementV2ResponseDto[] = [];
    for (const formElement of formElements) {
        if (formElement.storageType === Type.FILE) {
            result.push(formElement)
        } else if (formElement.storageType === Type.SECTION && formElement.knowledgeBase !== IKnowledgeBase.VIRTUAL_FOLDER) {
            result.push(formElement)
        } else if (formElement.storageType === Type.SECTION && formElement.knowledgeBase === IKnowledgeBase.VIRTUAL_FOLDER) {
            const children = allFormElements.filter(child => formElement.childrenIds.includes(child.id))
            result.push(...findFirstNonVirtualFolderFormElementsRecursively(children, allFormElements))
        }
    }
    return result;
}




const INVALID_FILE_MESSAGE = 'Invalid file type.  Please upload a valid file type.'

// Check dropped files are not folders
export const filterInvalidFiles = async (files: File[], rejectedFiles: FileRejection[]): Promise<{ acceptedFiles: File[], rejectedFiles: FileRejection[] }> => {
    const finalAcceptedFiles: File[] = [];
    const finalRejectedFiles: FileRejection[] = rejectedFiles
        .filter(rejectedFile => !['heic'].includes(getFileExtension(rejectedFile.file)))
        .map(rejectedFile => ({
            ...rejectedFile,
            errors: [
                {
                    message: INVALID_FILE_MESSAGE,
                    code: 'file-invalid-type'
                }
            ]
        }));
    for (const rejectedFile of rejectedFiles) {
        if (['heic'].includes(getFileExtension(rejectedFile.file))) {
            const { file } = rejectedFile;
            finalAcceptedFiles.push(file);
        }
    }
    for (const file of files) {
        const isValidFile = await isAFile(file);
        if (isValidFile && file.size > 0) {
            finalAcceptedFiles.push(file);
        } else if (!file || file.size === 0) {
            finalRejectedFiles.push({
                file,
                errors: [{
                    message: 'File is empty or folder has not be unzipped.  Please check file or unzip folder prior to uploading file',
                    code: 'file-too-small'
                }]
            });
        } else {
            finalRejectedFiles.push({
                file,
                errors: [
                    {
                        message: INVALID_FILE_MESSAGE,
                        code: 'file-invalid-type'
                    }
                ]
            });
        }
    }

    return ({
        acceptedFiles: finalAcceptedFiles,
        rejectedFiles: finalRejectedFiles
    });
}

// show toast for rejected dropped files
export const showRejectedDroppedFilesToast = (rejectedFiles: FileRejection[]) => {
    if (rejectedFiles?.length > 0) {
        const message = rejectedFiles.map(file => file.errors.map(error => error.message).join(', ')).join(', ');
        toast({
            content: message,
            type: 'error',
            duration: getToastDuration(message)
        });
    }
}

export const getErrors = (errors: any) => {
    if (!errors) {
        return {};
    }
    let errorObj = {};
    errors.forEach(error => {
        errorObj[error.field] = error.defaultMessage;
    });
    return errorObj;
}
// @ts-ignore
export const mapFormElementToCalendarEvent = ({ id, fullTitle, title, loanId, storageType, parent, answer, approved }: FormElement): MysherpasCalendarEvent => ({
    id,
    title,
    // storageType: storageType,
    toolTipTitle: fullTitle,
    parentId: parent?.id,
    description: '',
    loanId,
    approved: !!answer ? approved : null,
    start: parseISO(``).getTime(),
    end: parseISO(``).getTime(),
    allDay: true,
    editable: storageType !== Type.SECTION,
    backgroundColor: '#6A77C9',
    borderColor: '#3E509E',
    textColor: '#002D74',
    classNames: 'fc-daygrid-event'
});

export const getTextReadDurationPerMillisecond = (text: string) => {
    return Math.ceil(countWords(text) / 3.9) * 1000;
}

export const getToastDuration = (text: string): number => {
    const calculatedDuration = getTextReadDurationPerMillisecond(text) * 1.5;
    let duration = DEFAULT_TOAST_DURATION;

    if (calculatedDuration > MAX_TOAST_DURATION) {
        duration = MAX_TOAST_DURATION;
    } else if (calculatedDuration > DEFAULT_TOAST_DURATION) {
        duration = calculatedDuration;
    }

    return duration;
}

export const chunkArray = <T>(input: T[], size: number): T[][] => {

    let x: number;
    let p: string = '';
    let i: number = 0
    let c: number = -1
    const l: number = input.length || 0
    const n: T[][] = []
    if (size < 1) {
        return null
    }
    if (Object.prototype.toString.call(input) === '[object Array]') {

        while (i < l) {
            (x = i % size)
                ? n[c][x] = input[i]
                : n[++c] = [input[i]]
            i++
        }

    } else {

        for (p in input) {
            if (input.hasOwnProperty(p)) {
                (x = i % size)
                    ? n[c][x] = input[p]
                    : n[++c] = [input[p]]
                i++
            }
        }
    }

    return n
}
export class IntervalManager {
    private intervals: { [name: string]: ReturnType<typeof setInterval> } = {};

    public set(name: string, func: () => void, delayExecution: boolean = false, customPollInterval = 0): void {
        this.clear(name);
        if (!delayExecution) {
            func();
        }
        this.intervals[name] = setInterval(func, customPollInterval > 0 ? customPollInterval : POLLING_INTERVAL);
    }

    public clear(name: string): void {
        if (this.intervals[name]) {
            clearInterval(this.intervals[name]);
            delete this.intervals[name];
        }
    }

    public clearAll(): void {
        Object.keys(this.intervals).forEach(name => this.clear(name));
    }
}

// return file name without extension
export const getFileNameWithoutExtension = (fileName: string): string => {
    return String(fileName).split('.').slice(0, -1).join('.');
}


// create a singleton instance of IntervalManager
export const intervalManager = new IntervalManager();

// sort form elements for lender
export const sortFilesForLender = (a: FormElement, b: FormElement) => {
    const aApproved = a.approved && !!a.answer;
    const aNotApproved = !a.approved && !!a.answer;
    const bApproved = b.approved && !!b.answer;
    const bNotApproved = b.approved && !!b.answer;
    // sort by not approved first then approved second
    if (aApproved && !bApproved) {
        return 1
    } else if (!aApproved && bApproved) {
        return -1
    } else if (aNotApproved && !bNotApproved) {
        return -1
    } else {
        return 0
    }

}

// sort form elements for principals
export const sortFilesForPrincipal = (a: FormElement, b: FormElement) => {
    const aHasAnswer = !!a.answer
    const bHasAnswer = !!b.answer
    const aApproved = a.approved && aHasAnswer;
    const aNotApproved = !a.approved && aHasAnswer;
    const bApproved = b.approved && bHasAnswer;
    const bNotApproved = b.approved && bHasAnswer;

    if (aHasAnswer && !bHasAnswer) {
        return 1
    } else if (!aHasAnswer && bHasAnswer) {
        return -1
    } else if (aNotApproved && !bNotApproved) {
        return 1
    } else if (!aNotApproved && bNotApproved) {
        return -1
    } else if (aApproved && !bApproved) {
        return 1
    } else if (!aApproved && bApproved) {
        return -1
    } else {
        return 0
    }
}
// sort form elements by due dates
export const sortByDueDate = (a: FormElement, b: FormElement) => {
    return 0;
    // if (a.dueDate && b.dueDate) {
    //     return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
    // } else if (a.dueDate) {
    //     return -1
    // } else if (b.dueDate) {
    //     return 1
    // } else {
    //     return 0
    // }
}
// get blob and filename from url 
export const getBlobAndFileNameFromUrl = async (url: string): Promise<{ blob: Blob, filename: string }> => {
    if (!url) return Promise.reject('No url provided');

    // get filename without query params
    const filename = url.split('?')[0].split('/').pop();
    // get file from url
    const response = await axios.get(url, {
        responseType: 'blob'
    });
    // get blob
    const blob = await response.data;

    return { blob, filename };
}

// create url slug from string
export const createUrlSlug = (str: string): string => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return str.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}